export default {
  itemsPerPage: [
    {
      value: 5
    },
    {
      value: 6
    },
    {
      value: 10
    }
  ]
}
