<template>
  <div 
    class="vuestic-switch"
    :class="wrapperClass"
  >
    <div class="vuestic-switch-option true-option" :class="{'active': value}" @click.prevent="setValue(true)">
      <slot name="trueTitle"></slot>
    </div>
    <div class="vuestic-switch-option false-option" :class="{'active': !value}" @click.prevent="setValue(false)">
      <slot name="falseTitle"></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'vuestic-switch',
    props: {
      value: [Number, Boolean],
      disabled: {
        type: Boolean,
        default: false
      },
      offcolor: {
        type: Boolean,
        default: false
      },
    },
    mounted () {
      this.$emit('input', this.value)
    },
    computed: {
      wrapperClass () {
        return {
          'disabled': this.disabled,
          'inactive': !this.value,
          'offcolor': this.offcolor
        }
      },
    },
    methods: {
      setValue (newVal) {
        if(this.disabled) return;
        this.$emit('input', newVal)
        this.$emit('change', newVal)
      }
    }
  }
</script>

<style lang="scss">
  .vuestic-switch {
    cursor: pointer;
    display: flex;
    flex-direction: row;

    .vuestic-switch-option {
      padding: $vuestic-switch-padding;
      border-top: $vuestic-switch-border-size solid transparent;
      border-bottom: $vuestic-switch-border-size solid $vuestic-switch-bg;
      background-color: transparent;
      transition: all 0.15s ease;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem!important;
      &.true-option {
        border-top-right-radius: 0!important;
        border-bottom-right-radius: 0!important;
      }

      &.false-option {
        border-top-left-radius: 0!important;
        border-bottom-left-radius: 0!important;
      }

      &.active {
        color: $white;
        background-color: $vuestic-switch-bg;
      }

      &:not(.active):hover {
        color: $brand-primary;
      }
    }
    &.inactive.offcolor {
      .vuestic-switch-option {
        border-bottom-color: $theme-red;
        &.active {
          background-color: $theme-red;
        }
      }
    }
    &.disabled {
      .vuestic-switch-option {
        border-bottom: $vuestic-switch-border-size solid $vuestic-switch-disabled-bg !important;

        &.active {
          background-color: $vuestic-switch-disabled-bg !important;
          color: $vuestic-switch-disabled-color !important;
        }

        &:not(.active):hover {
          color: $vuestic-switch-disabled-color !important;
        }
      }
    }
  }
</style>
