<template>
  <div class="profile-dropdown dropdown" v-dropdown>
    <a class="sidebar-link dropdown-toggle" href="#">
      <div class="profile-section-avatar-container">
        <slot></slot>
      </div>
    </a>
    <div class="dropdown-menu last">
      <div class="dropdown-menu-content">
        <div v-for="(option, id) in options" :key="id" class="dropdown-item plain-link-item">
          <router-link :to="{name: option.redirectTo}" class="plain-link" href="#">
            {{ `user.${option.name}` | translate}}
          </router-link>
        </div>
        <div class="dropdown-item plain-link-item" @click="navigator(getRoleGroup.toLowerCase() + '.profile')">
          {{ `user.profile` | translate}}
        </div>
        <div class="dropdown-item plain-link-item" @click="logout">
          <span>Logout</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile-section",

  props: {
    options: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    navigator(name) {
      this.$router.push({name: name})
    },

    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push({name: 'login'})
        })
        .catch(() => {})
    }
  },

  computed: {
    getRole () {
      return this.$store.getters['auth/getRole']
    },

    getRoleGroup () {
      return this.$store.getters['auth/getRoleGroup']
    }
  }
};
</script>

<style lang="scss">
.profile-dropdown {
  .dropdown-menu {
    background: #fff;
    top: -63px;
    left: 58px;
    .dropdown-menu-content {
      box-shadow: none;
    }
    .dropdown-item {
      cursor: pointer;
      &:hover {
        color: $white;
        background-color: $rb-blue;
      }
    }
  }
  .sidebar-link {
    margin-left: auto;
  }
  .dropdown-toggle {
    &::after{
      display: none;
    }
  }
  .profile-section-avatar-container {
    display: inline-block;
    width: 50px;
    height: 50px;
    color: white;
    // border-radius: 50%;
    // border: 2px solid $lighter-gray;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
