import Vue from 'vue'
import axios from 'axios'

const initState = {
  didFetch: false,
  fetching: false,
  processing: false,
  googleIntegrations: [], 
  googleLocations: [], 
  emailAccounts: [],
  emailProviders: [
    { id: 'other', label: 'SMTP'}
  ],
  userIntegrations: [],
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    processingItem(state) {
      state.processing = true;
    },

    done(state) {
      state.processing = false;
    },

    getList(state, payload) {
      const { type } = payload;
      state.fetching = true;
      switch (type) {
        case 'email':
          state.emailAccounts = [];
          break;
        case 'location':
          state.googleLocations = [];
          break;
        case 'google':
          state.googleIntegrations = [];
          break;
        case 'all':
        default:
          state.userIntegrations = [];
          break;
      }
    },

    getListSuccess(state, payload) {
      const { type, data } = payload;
      state.didFetch = true;
      state.fetching = false;
      switch (type) {
        case 'email':
          state.emailAccounts = data;
          break;
        case 'location':
          state.googleLocations = data;
          break;
        case 'google':
          state.googleIntegrations = data;
          break;
        case 'all':
        default:
          state.userIntegrations = data;
          break;
      }
    },

    createSuccess(state, payload)  {
      const { type, data } = payload;
      state.processing = false;
      switch (type) {
        case 'location':
          break;
        case 'email':
          let index = state.emailAccounts.findIndex(item => item.id === data.id)
          if (index === -1)
          {
            state.emailAccounts.push(data);
          }
          break;
        case 'google':
          index = state.googleIntegrations.findIndex(item => item.id === data.id)
          if (index === -1)
          {
            state.googleIntegrations.push(data);
          }
          break;
        case 'all':
        default:
          break;
      }
    },

    deleteSuccess(state, payload)  {
      const { type, id } = payload;
      state.processing = false;
      switch (type) {
        case 'email':
          state.emailAccounts = state.emailAccounts.filter(item => item.id !== id)
          break;
        case 'google':
          state.googleIntegrations = state.googleIntegrations.filter(item => item.id !== id)
          break;
        case 'location':
          const { installId }  = payload
          let itemIndex = state.googleLocations.findIndex(item => item.id == id)
          let item = state.googleLocations[itemIndex]
          if (item.installs.length > 1)
          {
            item.installs = item.installs.filter(i => i.id !== installId)
            state.googleLocations.splice(itemIndex, 1, item)
          } else {
            state.googleLocations = state.googleLocations.filter(item => item.id !== id)
          }
          break;
        case 'all':
        default:
          state.userIntegrations = state.userIntegrations.filter(item => item.id !== id)
          break;
      }
    },

    clearList(state) {
      state.didFetch = false;
      state.fetching = false;
      state.processing = false;
      state.data = [];
    }
  },

  actions: {
    googleAll({ commit }) {
      commit('getList', {
        type: 'google'
      });
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/google-integrations`).then(res => {
          commit('getListSuccess', {
            type: 'google',
            data: res.data.data,
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    googleCreate({ commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/businesses/google-integrations`, arg).then(res => {
          commit('createSuccess', {
            type: 'google',
            data: res.data.data
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    googleDelete({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/businesses/google-integrations/${id}`).then(res => {
          commit('deleteSuccess', {
            type: 'google',
            id: id
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    getLocationsByGoogleAccount({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/locations-by-google/${id}`).then(res => {
          commit('done')
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    googleLocationAll({ commit }) {
      commit('getList', {
        type: 'location'
      });
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/google-locations`).then(res => {
          commit('getListSuccess', {
            type: 'location',
            data: res.data.data,
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    googleLocationGet({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/google-locations/${id}`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    googleLocationCreate({ commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/businesses/google-locations`, arg).then(res => {
          commit('createSuccess', {
            type: 'location',
            data: res.data.data
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    googleLocationUpdate({ commit }, arg) {
      return new Promise((resolve, reject) => {
        const { id, data } = arg
        axios.put(`/v1/businesses/google-locations/${id}`, data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    googleLocationDelete({ commit }, arg) {
      return new Promise((resolve, reject) => {
        const { id, installId } = arg
        axios.delete(`/v1/businesses/google-locations/${id}?install_id=${installId}`).then(res => {
          commit('deleteSuccess', {
            type: 'location',
            id,
            installId
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    emailAccountAll({ commit }) {
      commit('getList', {
        type: 'email'
      });
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/email-accounts`).then(res => {
          commit('getListSuccess', {
            type: 'email',
            data: res.data.data,
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    emailAccountGet({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/email-accounts/${id}`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    emailAccountCreate({ commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/businesses/email-accounts`, arg).then(res => {
          commit('createSuccess', {
            type: 'email',
            data: res.data.data
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    emailAccountUpdate({ commit }, arg) {
      return new Promise((resolve, reject) => {
        const { id, data } = arg
        axios.put(`/v1/businesses/email-accounts/${id}`, data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    emailAccountDelete({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/businesses/email-accounts/${id}`).then(res => {
          commit('deleteSuccess', {
            type: 'email',
            id,
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    types({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/businesses/integrations/types`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    type({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/businesses/integrations/type/${id}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    list({ commit }) {
      commit('getList', {
        type: 'all'
      });
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/integrations`).then(res => {
          commit('getListSuccess', {
            type: 'all',
            data: res.data.data,
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    get({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/integrations/${id}`).then(res => {
          commit('done');
          resolve(res.data.data.integration)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    create({ commit }, params) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios
          .post(`v1/businesses/integrations`, params)
          .then((res) => {
            commit('createSuccess', {
              type: 'all',
              data: res.data.data
            })
            resolve(res.data.data.integration)
          })
          .catch((error) => {
            commit('error')
            reject(error)
          })
      })
    },

    update({ commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        const { id, data } = arg
        axios
          .put(`v1/businesses/integrations/${id}`, data)
          .then((res) => {
            commit('done')
            resolve(res.data.data.integration)
          })
          .catch((error) => {
            commit('error')
            reject(error)
          })
      })
    },

    delete({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/businesses/integrations/${id}`).then(res => {
          commit('deleteSuccess', {
            type: 'all',
            id: id
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    clearList ({ commit }) {
      commit('clearList')
    },
  },

  getters: {
    sendgridIntegrations(state) {
      return state.userIntegrations.filter(item => item.key === 'sendgrid')
    },

    emailAccounts(state) {
      return state.emailAccounts
    },
  }
}