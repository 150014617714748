<template>
  <div class="b-order-page">
    <div class="header-section">
      <div class="container--full-width container-xl px-3 d-flex justify-content-between flex-md-row flex-column">
        <router-link :to="{ path: '/' }" class="float-left">
          <img v-if="headerLogo" class="logo" :src="headerLogo" width="200" alt="logo" />
        </router-link>
        <div class="h5 float-right mt-4 mb-md-2 mt-md-2 text-center">
          Need help? Contact Support:
          <a class="color-primary" :href="`mailto:${supportEmail}?subject=Free Trial Signup Question`">{{ supportEmail
          }}</a>
        </div>
      </div>
    </div>
    <div class="container--full-width container-xl main-section">
      <router-view></router-view>
    </div>
    <div class="footer-section">
      <div class="container--full-width container-xl px-3">
        <div class="d-block">
          <router-link :to="{ path: '/' }">
            <img v-if="whiteLogo" class="logo" :src="whiteLogo" width="200" alt="logo" />
          </router-link>
        </div>
        <div class="text-center mt-3">
          <div class="text-center">
            <span>Copyright {{ copyRightYear }} All Rights Reserved</span>
            <a href="javascript:;">Terms of Service</a>
            <a href="javascript:;">Privacy Policy</a>
          </div>
        </div>
        <div class="mt-2 text-center">
          <span>{{ address }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import postscribe from 'postscribe'

export default {
  created() {
    let currentHost = window.location.host

    this.$store.dispatch('getSiteData', currentHost)
      .then(({ data }) => {
        if (!data.data.validDomain || !data.data.isMainAgency) {
          this.$router.push({ name: 'landing' });
        } else {
          this.install_custom_script();
        }
      })
  },

  data() {
    return {
    }
  },

  computed: {
    sitedata() {
      return this.$store.state.app.sitedata
    },
    headerLogo() {
      return this.sitedata && this.sitedata.headerLogo
    },
    whiteLogo() {
      return this.sitedata && this.sitedata.loginLogo
    },
    supportEmail() {
      return this.sitedata && this.sitedata.supportEmail
    },
    address() {
      return this.sitedata && this.sitedata.address
    },
    copyRightYear() {
      return this.$store.getters["copyRightYear"]
    },
    scripts() {
      return this.sitedata && this.sitedata.custom_script
    }
  },

  mounted() {
  },

  beforeDestroy() {
    this.destroy_custom_script();
  },
  
  methods: {
    install_custom_script() {
      if (!$('#custom-scripts').length) {
        $('body').append(`<div id="custom-scripts"></div>`)
      }

      if ($('#LEWIDGET')) {
        $('#LEWIDGET').remove()
      }
      if (window.LEPLUGINWIDGET) {
        window.LEPLUGINWIDGET = null
      }
      
      this.$nextTick(() => {
        postscribe('#custom-scripts', this.scripts, {
          done: () => {
          },
        })
      })
    },
    
    destroy_custom_script() {
      if ($('#custom-scripts')) {
        $('#custom-scripts').remove()
      }
      if ($('#LEWIDGET')) {
        $('#LEWIDGET').remove()
      }
    },
  }
}
</script>
  
<style lang="scss">
.b-order-page {
  background: #fff;
  color: #585858;
  min-height: 100vh;

  .header-section {
    background-color: #F3F3F3;
    padding: 20px 0 20px;
  }

  .main-section {
    min-height: calc(100vh - 250px);
    @include media-breakpoint-down(sm) {
      margin: 0;
      padding: 0;
    }
  }

  a.color-primary {

    &:focus,
    &:hover {
      color: $rb-blue;
    }
  }

  @media (max-width: 767px) {
    .logo {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .footer-section {
    background-color: #101828;
    color: #fff;
    padding: 30px 0 20px;
    text-align: center;

    @include media-breakpoint-down(sm) {
      padding-bottom: 70px;
    }

    span,
    a {
      color: #fff;
      font-size: 16px;
      display: inline-block;
      margin-left: 20px;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}
</style>
  