import lazyLoading from './lazyLoading'
import AuthLayout from '../components/layouts/AuthLayout'
import Landing from '../components/common/Landing'

export default [{
  path: '/',
  name: 'landing',
  component: Landing,
}, {
  path: '/',
  component: AuthLayout,
  children: [
    {
      name: 'login',
      path: 'login',
      component: lazyLoading('auth/Login')
    },
    {
      name: 'auth',
      path: 'auth/login',
      component: lazyLoading('auth/OAuth')
    },
    {
      name: 'auth.reset-password',
      path: '/password/reset',
      component: lazyLoading('auth/ResetPassword')
    },
    {
      name: 'auth.reset-password-email',
      path: '/password/email',
      component: lazyLoading('auth/ResetPasswordEmail')
    },
    {
      name: 'auth.verify-email',
      path: '/email-verify',
      component: lazyLoading('auth/VerifyEmail')
    },
    {
      name: 'not-found',
      path: '/not-found',
      component: lazyLoading('auth/NotFound')
    },
    {
      path: '',
      redirect: {name: 'login'}
    }
  ]
}]
