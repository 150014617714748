import Vue from 'vue'
import axios from 'axios'

const initState = {
  didFetch: false,
  fetching: false,
  processing: false,
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  filter: {
    type: 'all',
    keyword: ''
  }
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    processingItem(state) {
      state.processing = true;
    },

    done(state) {
      state.fetching = false;
      state.processing = false;
    },

    getList(state, query) {
      state.fetching = true;
      state.total = 0;
      state.page = query.page;
      state.perPage = query.per_page;
    },

    getListSuccess(state, payload) {
      const { data, meta } = payload;
      state.didFetch = true;
      state.fetching = false;
      state.data = data;
      state.total = meta.total;
    },

    tagSuccess(state, broadcast) {
      const { data } = state;
      state.processing = false;
      state.data = data.map(item => {
        return item.id === broadcast.id ? broadcast : item
      })
    },

    updateSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = state.data.map(item => {
        return item.id === id ? payload : item
      })
      state.processing = false;
    },

    createSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = [payload, ...state.data]
      state.processing = false;
    },

    deleteSuccess(state ,id) {
      state.data = state.data.filter(item => item.id != id)
      state.processing = false;
    },

    clearList(state) {
      state.didFetch = false;
      state.fetching = false;
      state.processing = false;
      state.data = [];
      state.page = 1;
      state.perPage = 10;
      state.total = 0;
      state.filter = {
        type: 'all',
        keyword: ''
      }
    }
  },

  actions: {
    all({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/tags/all`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    fetch({ state, commit }, query) {
      commit('getList', query);
      return new Promise((resolve, reject) => {
        axios.get(`/v1/broadcasts`, { params: query }).then(res => {
          commit('getListSuccess', {
            data: res.data.data,
            meta: res.data.meta
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    get({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/broadcasts/${id}`, {}).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    messageCredit({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/broadcasts/message-credit`, { params: queries})
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    tags ({ commit }, payload) {
      commit('processingItem')
      const { id, data } = payload
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasts/${id}/tag`, data)
          .then((res) => {
            commit('tagSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    update({ state, commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.put(`/v1/broadcasts/${arg.id}`, arg.data).then(res => {
          commit('updateSuccess', res.data.data)
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    create({ state, commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasts`, arg).then(res => {
          commit('createSuccess', res.data.data)
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    delete({ state, commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/broadcasts/${id}`).then(res => {
          commit('deleteSuccess', id)
          resolve(id)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    stop({ state, commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasts/${id}/stop`).then(res => {
          resolve(true)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    test({ state, commit }, arg) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasts/test`, arg).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    rerun({ state, commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasts/${id}/rerun`).then(res => {
          commit('done')
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    report({ state, commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/broadcasts/${queries.id}/report`, { params: queries }).then(res => {
          commit('done')
          resolve(res.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    downloadReport ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/broadcasts/${queries.id}/report/download`, { params: queries, responseType: 'blob' })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    refresh({ state, commit }, broadcastId) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasts/${broadcastId}/refresh`).then(res => {
          commit('done')
          commit('updateSuccess', res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    bulkOptout ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasts/${payload.id}/bulk-optout`, payload)
          .then((res) => {
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    fetchBroadcasters({ state, commit }, query) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/broadcasters`, { params: query }).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    createBroadcaster({ state, commit }, arg) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/broadcasters`, arg).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    updateBroadcaster({ state, commit }, arg) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/broadcasters/${arg.id}`, arg.data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    deleteBroadcaster({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/broadcasters/${id}`).then(res => {
          resolve(id)
        }).catch(e => {
          reject(e)
        })
      });
    },

    clearList ({ commit }) {
      commit('clearList')
    },
  },

  getters: {
    all(state) {
      const { data } = state
      return data
    }
  }
}