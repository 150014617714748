<template>
  <div ref="modal" class="modal fade hide"  role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <a class="close-modal" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </a>
        <div v-if="page" style="padding:20px;">
          <h2>{{ page.title }}</h2>
          <div v-html="page.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      page: null,
    }
  },
  mounted () {
  },
  methods: {
    openModal (key) {
      this.$store.dispatch('page/getPagePublic', key)
        .then((res) => {
          this.page = res.data.data

          if (this.page.content) {
            $(this.$refs.modal).modal('show')
          }
        })
        .catch(() => {
        })
    },
  },
}
</script>

<style scoped lang="scss">
  .elInputMid {
    &::placeholder {
      font-size: inherit;
      font-family: inherit;
    }
    &.error {
      border-color: rgb(185, 21, 23);
      border-width: 3px;
    }
  }

  .close-modal {
    position: absolute;
    right: 10px;
    top: 3px;
    font-size: 40px;
    line-height: 1;
    z-index: 10000;
  }

  .modal-content {
    max-height: 90vh;
    overflow-y: auto;
  }

  .modal-dialog {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 5vh;
    transform: none !important;
  }

  @media (max-width: 769px) {
    .dialog {
      overflow: scroll !important;
      height: 100%;
    }
    .modal-dialog {
      position: static !important;
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
      margin: 30px auto;
      transform: translateY(0) !important;
    }
  }
</style>

<style>
  .toasted.vuestic-toast.register-page-toast {
    font-size: 17px;
  }
</style>
