import axios from 'axios'

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  mutations: {
    loading(state) {
      state.loading = true
    },

    done(state) {
      state.loading = false
    },
  },

  actions: {
    getPage ({ commit }, key) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/pages/${key}`)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    getPagePublic ({ commit }, key) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/pages/${key}/public?domain=` + window.location.hostname)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    updatePage ({ commit }, page) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/pages/${page.key}`, page)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },
  },

  getters: {
  },
}
