import axios from 'axios'

export default {
  namespaced: true,
  state: {
    list: {
      rows: [],
      summary: [],
      total: 0
    },
    loading: false,
    processing: false,
  },
  mutations: {
    loading(state) {
      state.loading = true
    },

    processing(state) {
      state.processing = true
    },

    listSuccess(state, data) {
      state.list.rows = data.rows
      state.list.total = data.total
    },

    done(state) {
      state.loading = false
      state.processing = false
    },

    updateReportSuccess(state, data) {
      this.state.auth.user.business.report_emails = data.data.business.report_emails
      this.state.auth.user.business.report_schedules = data.data.business.report_schedules
    },

    updateSettingSuccess(state, data) {
      this.state.auth.user.business = {
        ...data.data.business
      }
    },

    addMessageCount(state, { length, hasImages, isIncoming }) {
      if ( hasImages )
      {
        if (isIncoming)
          this.state.auth.user.business.messages += 2
        else
          this.state.auth.user.business.messages += 3
      } else {
        this.state.auth.user.business.messages += Math.ceil(length / 160)
      }
    }
  },
  actions: {
    list ({ commit },query) {
      return new Promise((resolve,reject) => {
        axios.get('/v1/business',{params: query}).then(response => {
          commit('listSuccess', response.data);
          resolve(response.data)
        }).catch(e => {
          reject(e)
        })
      });
    },
    
    fetch({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/business/${id}`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    resetPassword({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/business/${id}/reset-password`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },
    
    resetUsage({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/businesses/${id}/reset-usage`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    toggleActive({commit}, businessId) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.put(`/v1/businesses/${businessId}/active`)
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    updateReport({commit}, data) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.put(`/v1/businesses/report`, data)
          .then((res) => {
            commit('done')
            commit('updateReportSuccess', res.data)

            resolve(true)
          })
          .catch((error) => {
            commit('done')
            reject(error.response)
          })
      })
    },

    updateInfo({commit}, data) {
      return new Promise((resolve, reject) => {
        commit('processing')
        axios.put(`/v1/businesses/info`, data)
          .then((res) => {
            commit('done')
            commit('updateSettingSuccess', res.data)

            resolve(true)
          })
          .catch((error) => {
            commit('done')
            reject(error.response)
          })
      })
    },

    updateSetting({commit}, data) {
      return new Promise((resolve, reject) => {
        commit('processing')
        axios.put(`/v1/businesses/setting`, data)
          .then((res) => {
            commit('done')
            commit('updateSettingSuccess', res.data)

            resolve(true)
          })
          .catch((error) => {
            commit('done')
            reject(error.response)
          })
      })
    },

    getInstallStats ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/install-stats`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addMessageCount({ commit }, data) {
      commit('addMessageCount', data)
    },

    fetchAnalytic ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/business/analytics`, data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    downloadReportAnalytic ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/business/analytics/export`, { params: queries, responseType: 'blob' })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sendEmailVerification({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/business/${id}/send-verification`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    verifyEmail({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/business/${id}/verify-email`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    getPackages({ state, commit })
    {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/businesses/packages`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    
    addPackage({ state, commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/business/${params.id}/add-package`, params).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    purchasePackage({ state, dispatch }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/businesses/purchase-package`, params).then(res => {
          this.dispatch('auth/getProfile', {}, { root: true });
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    uploadImage ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/businesses/image`, params.data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteImage ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/businesses/image/${params.type}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    generateAIContent({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/businesses/generate-ai-content`, params)
          .then(res => {
            resolve(res.data)
          })
          .catch(errors => reject(errors))
      })
    },
  },

  getters: {
  }
}