<template>
  <div class="order-page">
    <router-view></router-view>
  </div>
</template>
  
<script>

export default {
  created() {
    let tmp = window.location.href.split('/')
    let slug = tmp[tmp.length - 1]
    if (slug)
      this.$store.dispatch('getProductData', slug)
  },

  data() {
    return {
    }
  },

  computed: {
  },
}
</script>
  
<style lang="scss">
.order-page {
  background: #000;
  color: #333;
  min-height: 100vh;
}
</style>
  