import Vue from 'vue'
import axios from 'axios'

const initState = {
  defaultAutomation: {
    blocks: [],
    name: null,
    start_trigger: null,
    active: true,
    options: {},
    installs: [],
    tags: [],
  },
  triggerGroups: [
    {
      id: 'behavior',
      label: 'Behavior & Actions',
    },
    {
      id: 'crm',
      label: 'Sales & CRM',
    },
  ],
  triggers: [
    { component: 'DirectCall', label: 'Text Back On All Calls', icon: 'fa fa-phone', id: 'direct-call', group: 'behavior', },
    { component: 'Birthday', label: 'Birthday', icon: 'fa fa-calendar', id: 'birthday', group: 'behavior', },
    { component: 'Anniversary', label: 'Anniversary', icon: 'fa fa-calendar-o', id: 'anniversary', group: 'behavior', },
    { component: 'Date', label: 'Date', icon: 'fa fa-calendar-check-o', id: 'date', group: 'behavior', },
    { component: 'MissedCall', label: 'Missed Call', icon: 'fa fa-bell-slash-o', id: 'missed-call', group: 'behavior', },
    { component: 'CompletedCall', label: 'Completed Call', icon: 'fa fa-volume-control-phone', id: 'completed-call', group: 'behavior', },
    { component: 'SmsKeyword', label: 'Keyword Text Message', icon: 'fa fa-comment', id: 'sms-keyword', group: 'behavior', },
    { component: 'NewTag', label: 'New Tag Added', icon: 'fa fa-tag', id: 'new-tag', group: 'behavior', },
    { component: 'HomeAdvisor', label: 'Home Advisor Trigger', icon: 'fa fa-cog', id: 'home-advisor', group: 'crm', },
    { component: 'Webhook', label: 'Webhook Trigger', icon: 'fa fa-star', id: 'webhook', group: 'crm', },
  ],
  actions: {
    sendingOptions: {
      label: 'Sending options',
      isOpen: true,
      actions: [
        {
          name: 'Send SMS', action: 'SendMessage', description: 'Send an SMS to the contact', icon: 'fa fa-envelope',
          options: {
            content: '',
          }
        },
        {
          name: 'Send Call', action: 'SendCall', description: 'Send a Call to the contact', icon: 'fa fa-phone',
          options: {
            whisper: '',
          }
        },
        {
          name: 'Send VoiceMail', action: 'SendVoiceMail', description: 'Send a VoiceMail to the contact', icon: 'fa fa-microphone',
          options: {
            timezone: {
              "sort": -800,
              "label": "America/Los_Angeles (UTC-08:00)",
              "value": "America/Los_Angeles"
            },
            process_now: true,
            send_at: '09:00',
            file: null,
          }
        },
        {
          name: 'Send Email', action: 'SendEmail', description: 'Send an Email to the contact', icon: 'fa fa-envelope-o',
          options: {
            title: '',
            content: '',
            googleIntegration: null,
            emailAccount: null,
            attachments: [],
          }
        },
        {
          name: 'Add Tag', action: 'AddTag', description: 'Add tags to the contact', icon: 'fa fa-tag',
          options: {
            tags: [],
          }
        },
        {
          name: 'Webhook', action: 'Webhook', description: 'Post a contact data to the URL', icon: 'fa fa-bolt',
          options: {
            url: null,
          }
        },
        { 
          name: 'Alert', action: 'Alert', description: 'Send an alert', icon: 'fa fa-exclamation-circle',
          options: {
            allLeads: false,
            users: [],
            content: "[phone_number] opted into the business"
          }
        },
      ]
    },
    conditionsAndWorkflow: {
      label: 'Conditions and workflow',
      isOpen: false,
      actions: [
        { name: 'Wait', action: 'Wait', description: 'Wait for a certain period of time, or until conditions are matched.', icon: 'fa fa-clock-o', options: { type: 'wait_for', duration: 1, period: 'seconds' } },
        { 
          name: 'Process Between', action: 'ProcessBetween', description: 'Process automation when time conditions are matched', icon: 'fa fa-clock-o', 
          options: { IsActiveAdvance: false, ResumeOn: [], IsActiveFilter: false, Condition: 'CurrentDay', Operator: 'Is' } },
        { 
          name: 'Condition', action: 'Condition', description: 'Condition', icon: 'fa fa-question',
          branches: [
            {
              name: 'None',
              none: true,
            }
          ]
        },
        { 
          name: 'Add To Automation', action: 'AddToAutomation', description: 'Add To Automation', icon: 'fa fa-magic',
          options: {
            AutomationId: null,
            IsIncludeData: false,
          }
        },
      ]
    },
    contacts: {
      label: 'Contacts',
      isOpen: false,
      actions: [
        {
          name: 'Collect Contact Data', action: 'CollectContactData', description: 'Send sms to collect contact data', icon: 'fa fa-user', options: {
            collectFields: [
              { name: 'first_name', label: 'Contact First Name', enable: true, message: 'Please reply with your Contact first name' },
              { name: 'last_name', label: 'Contact Last Name', enable: true, message: 'Please reply with your Contact last name' },
              { name: 'email', label: 'Contact Email', enable: true, message: 'Please reply with your Contact email' },
              { name: 'birthday', label: 'Birthday', enable: true, message: 'When is your birthday? Please reply with mm/dd', format: 'mm/dd' },
              { name: 'anniversary', label: 'Anniversary', enable: false, message: 'When is your anniversary? Please reply with mm/dd', format: 'mm/dd' },
              { name: 'city', label: 'City', enable: true, message: 'Please reply with your City' }
            ]
          },
        },
      ]
    },
  },
  didFetch: false,
  fetching: false,
  processing: false,
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  filter: {
    type: 'all',
    keyword: ''
  }
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    processingItem(state) {
      state.processing = true;
    },

    getList(state, query) {
      state.fetching = true;
      state.total = 0;
      state.page = query.page;
      state.perPage = query.per_page;
    },

    getListSuccess(state, payload) {
      const { data, meta } = payload;
      state.didFetch = true;
      state.fetching = false;
      state.data = data;
      state.total = meta.total;
    },

    updateSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = state.data.map(item => {
        return item.id === id ? payload : item
      })
      state.processing = false;
    },

    createSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = [payload, ...state.data]
      state.processing = false;
    },

    deleteSuccess(state, id) {
      state.data = state.data.filter(item => item.id != id)
      state.processing = false;
    },

    cloneSuccess(state, id) {
      state.processing = false;
    },

    clearList(state) {
      state.didFetch = false;
      state.fetching = false;
      state.processing = false;
      state.data = [];
      state.page = 1;
      state.perPage = 10;
      state.total = 0;
      state.filter = {
        type: 'all',
        keyword: ''
      }
    }
  },

  actions: {
    all({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/automations/all`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    fetch({ state, commit }, query) {
      commit('getList', query);
      return new Promise((resolve, reject) => {
        axios.get(`/v1/automations`, { params: query }).then(res => {
          commit('getListSuccess', {
            data: res.data.data,
            meta: res.data.meta
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    getTemplates({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/automations/templates`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    get({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/automations/${id}`, {}).then(res => {
          resolve(res.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    getTemplate({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/automations/template/${id}`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    findByUniqueId({ commit }, uniqueId) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/automations/${uniqueId}/unique-id`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    toggleStatus({ state, commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.put(`/v1/automations/${arg.id}/toggle`, arg).then(res => {
          commit('updateSuccess', res.data.data)
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    update({ state, commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.put(`/v1/automations/${arg.id}`, arg.data).then(res => {
          commit('updateSuccess', res.data.data)
          resolve(res.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    create({ state, commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/automations`, arg).then(res => {
          commit('createSuccess', res.data.data)
          resolve(res.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    delete({ state, commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/automations/${id}`).then(res => {
          commit('deleteSuccess', id)
          resolve(id)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    clone({ state, commit }, id) {
      commit('processingItem')

      return new Promise((resolve, reject) => {
        axios.post(`/v1/automations/${id}/clone`).then(res => {
          commit('cloneSuccess')
          resolve(id)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    duplicate({ state, commit }, id) {
      commit('processingItem')

      return new Promise((resolve, reject) => {
        axios.post(`/v1/automations/${id}/duplicate`).then(res => {
          commit('cloneSuccess')
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    generateWebhookUrl({ state, commit }, query) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/automations/new-webhook`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    testSms({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/automations/test-sms`, data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    testEmail({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/automations/test-email`, data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    clearList({ commit }) {
      commit('clearList')
    },
  },

  getters: {
    defaultAutomation(state) {
      return state.defaultAutomation
    },
    triggerGroups(state) {
      return state.triggerGroups
    },
    triggers(state) {
      return state.triggers
    },
    actions(state) {
      return state.actions
    },
    actionList(state, getters) {
      let actions = getters['actions']
      let res = []
      res = res.concat(actions['conditionsAndWorkflow']['actions'])
        .concat(actions['contacts']['actions'])
        .concat(actions['sendingOptions']['actions'])

      return res;
    },
    all(state) {
      const { data } = state
      return data
    }
  }
}