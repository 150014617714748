import lazyLoading from './lazyLoading'
import AppLayout from '../components/layouts/AppLayout'
import AdminGuard from './guards/AdminGuard'

const EmptyParentComponent = {
  template: '<router-view></router-view>'
}

let routes = [
  {
    path: '/admin/',
    component: AppLayout,
    redirect: { name: 'agencyaccounts' },
    meta: {
      displayName: 'menu.home'
    },
    children: [
      {
        path: 'agency',
        name: 'agency',
        redirect: { name: 'agencyaccounts' },
        component: EmptyParentComponent,
        meta: {
          displayName: 'menu.agency',
        },
        children: [
          {
            name: 'agencyaccounts',
            path: 'accounts',
            component: lazyLoading('agency/Accounts'),
            beforeEnter: AdminGuard,
            meta: {
              displayName: 'menu.accounts',
              sidebarName: 'menu.agencyaccounts',
              iconClass: 'fa fa-users'
            }
          },
          {
            name: 'admin.agencies.archived',
            path: 'archived',
            component: lazyLoading('agency/Archived'),
            beforeEnter: AdminGuard,
          },
          {
            name: 'addagencyaccount',
            path: 'add',
            component: lazyLoading('agency/Account'),
            beforeEnter: AdminGuard,
            meta: {
              displayName: 'menu.add',
              iconClass: 'fa fa-users'
            }
          },
          {
            path: 'edit/:id',
            name: 'editAgency',
            component: lazyLoading('agency/Account'),
            beforeEnter: AdminGuard,
            meta: {
              displayName: 'menu.edit',
            }
          }
        ]
      },
      {
        name: "business",
        path: 'business',
        redirect: { name: 'businessaccounts' },
        meta: {
          displayName: 'menu.business',
        },
        component: EmptyParentComponent,
        children: [
          {
            name: "addbusiness",
            path: "add",
            component: lazyLoading('business/Account'),
            beforeEnter: AdminGuard,
            meta: {
              displayName: 'menu.add',
            }
          },
          {
            name: 'editBusiness',
            path: 'edit/:id',
            component: lazyLoading('business/Account'),
            beforeEnter: AdminGuard,
            meta: {
              displayName: 'menu.edit',
            }
          },
          {
            name: 'businessaccounts',
            path: '',
            component: lazyLoading('business/Accounts'),
            beforeEnter: AdminGuard,
            meta: {
              displayName: 'menu.accounts',
              sidebarName: 'menu.businessaccounts',
              iconClass: 'fa fa-building'
            }
          }
        ]
      },
      {
        path: 'settings',
        name: 'settings',
        meta: {
          displayName: 'menu.settings',
          sidebarName: 'menu.settings',
          iconClass: 'fa fa-cogs'
        },
        component: EmptyParentComponent,
        children: [],
      },
      {
        path: 'whitelabel',
        name: 'admin.whitelabel',
        component: lazyLoading('admin/Whitelabel'),
        beforeEnter: AdminGuard,
      },
      {
        path: 'training',
        name: 'admin.training',
        component: lazyLoading('admin/Training'),
        beforeEnter: AdminGuard,
      },
      {
        path: 'releaseNote',
        name: 'admin.releaseNote',
        component: EmptyParentComponent,
        redirect: {name: 'admin.releaseNote.index'},
        beforeEnter: AdminGuard,
        children: [
          {
            path: '',
            name: 'admin.releaseNote.index',
            component: lazyLoading('admin/ReleaseNote/Index'),
            beforeEnter: AdminGuard,
          },
          {
            path: 'create',
            name: 'admin.releaseNote.create',
            component: lazyLoading('admin/ReleaseNote/Create'),
            beforeEnter: AdminGuard,
          },
          {
            path: ':id',
            name: 'admin.releaseNote.edit',
            component: lazyLoading('admin/ReleaseNote/Edit'),
            beforeEnter: AdminGuard,
          }
        ]
      },
      {
        name: 'admin.profile',
        path: 'profile',
        component: lazyLoading('auth/Profile'),
      },
      {
        path: 'blacklist',
        name: 'admin.blacklist',
        component: lazyLoading('admin/Blacklist/Index'),
        beforeEnter: AdminGuard,
        meta: {
          sidebarName: 'menu.blacklist',
          displayName: 'menu.blacklist',
          iconClass: 'fa fa-ban'
        }
      },
      {
        path: 'whitelist',
        name: 'admin.whitelist',
        component: lazyLoading('admin/Whitelist/Index'),
        beforeEnter: AdminGuard,
        meta: {
          sidebarName: 'menu.whitelist',
          displayName: 'menu.whitelist',
          iconClass: 'fa fa-check-square-o'
        }
      },
      {
        path: 'users',
        name: 'admin.users',
        component: EmptyParentComponent,
        redirect: {name: 'admin.users.index'},
        beforeEnter: AdminGuard,
        children: [
          {
            path: '',
            name: 'admin.users.index',
            component: lazyLoading('admin/Users/Index'),
            beforeEnter: AdminGuard,
          },
          {
            path: 'create',
            name: 'admin.users.create',
            component: lazyLoading('admin/Users/Create'),
            beforeEnter: AdminGuard,
          },
          {
            path: ':id',
            name: 'admin.users.edit',
            component: lazyLoading('admin/Users/Edit'),
            beforeEnter: AdminGuard,
          }
        ]
      },
    ],
  },
]

export default routes
