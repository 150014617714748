<template>
    <div class="landing-page-container landing-page-wrapper" v-show="isReady">
        <div class="containerWrapper">
            <div class="container noTopMargin padding40-top padding40-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 activeSection_topBorder activeSection_bottomBorder activeSection_topBorder0 activeSection_bottomBorder0 emptySection fullContainer bgCover100 stickyTop" id="header-wrapper" data-title="sales copy &amp; optin - Clone" data-block-color="0074C7" data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px P0-top P0-bottom P0H noTopMargin cornersBottom radius0 shadow0"
                            id="row--16568-147" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="3 column row"
                            style="padding: 15px 10px; margin: 0px; outline: none; background-color: rgb(255, 255, 255);"
                            col-array="3,6,3">
                        <div id="col-left-100-154" class="innerContent col_left ui-resizable col-md-3 header-logo" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elMargin0 elAlign_left ui-droppable de-editable"
                                        id="img-66023-144" data-de-type="img" data-de-editing="false" data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="outline: none; cursor: pointer;" aria-disabled="false">
                                    <router-link :to="{path: '/'}">
                                        <img v-if="sitedata.headerLogo" :src="sitedata.headerLogo" class="elIMG ximg logo-img" alt=""/>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div id="col-center-129-143" class="innerContent col_right ui-resizable col-md-6"
                                data-col="center"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elNavWrapper de-nav elMargin0 ui-droppable elAlign_right de-editable"
                                        id="nav-89106-135" data-de-type="nav" data-de-editing="false"
                                        data-title="navigation"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <nav class="nodoNav elLinkSize1 slide elLinkBold elLinks4">
                                        <ul>
                                            <li class="nav1 nodoNavItem">
                                                <a href="#scroll-HowItWorks"
                                                    class="nav1link nodoNavLink"
                                                    style="color: rgb(47, 47, 47);"
                                                >How It Works</a>
                                            </li>
                                            <li class="nav2 nodoNavItem">
                                                <a href="#scroll-Features"
                                                    class="nav2link nodoNavLink"
                                                    style="color: rgb(47, 47, 47);"
                                                >Features</a>
                                            </li>
                                            <li class="nav4 nodoNavItem" v-if="!user">
                                              <router-link
                                                :to="{ name: 'login' }"
                                                class="nav4link nodoNavLink"
                                                style="color: rgb(47, 47, 47);"
                                              >Login</router-link>
                                            </li>
                                            <li class="nav5 nodoNavItem">
                                                <a href="#"
                                                    class="nav5link nodoNavLink"
                                                    style="color: rgb(49, 121, 199);"
                                                >Consectetur</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                        <div id="col-right-154-151" class="innerContent col_right ui-resizable col-md-3"
                                data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="3rd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elBTN elMargin0 elAlign_left ui-droppable de-editable"
                                        id="tmp_button-36454-178"
                                        data-de-type="button" data-de-editing="false" data-title="button"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false"
                                        data-elbuttontype="1">
                                    <router-link :to="{ path: $store.getters['auth/dashboardLink'] }"
                                            class="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elButtonCorner3 elButtonFluid elBtnHP_25 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1"
                                            style="color: rgb(255, 255, 255); font-weight: 600; background-color: rgb(13, 157, 88); font-size: 16px;"
                                            rel="noopener noreferrer"
                                            v-if="user">
                                        <span class="elButtonMain">Dashboard</span>
                                    </router-link>
                                    <a v-if="!user"
                                        class="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elButtonCorner3 elButtonFluid elBtnHP_25 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1"
                                        style="color: rgb(255, 255, 255); font-weight: 600; background-color: rgb(13, 157, 88); font-size: 16px;"
                                        rel="noopener noreferrer"
                                        @click.prevent="openModal">
                                        <span class="elButtonMain">Request A Free Demo</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section--28325" data-title="OptIn" data-block-color="0074C7"
                    style="padding-top: 50px; padding-bottom: 50px; outline: none;" data-trigger="none"
                    data-animate="fade"
                    data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--68043" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row"
                            style="padding: 50px 10px; margin: 0px; outline: none;" col-array="6,6">
                        <div id="col-right-161" class="innerContent col_right ui-resizable col-md-6" data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elMargin0 ui-droppable elAlign_right de-editable"
                                        id="tmp_image-18624" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <img src="../../assets/images/landing/Header-2-2.webp" class="elIMG ximg" alt="" width="">
                                </div>
                            </div>
                        </div>
                        <div id="col-left-131" class="innerContent col_left ui-resizable col-md-6" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_headline1-11985"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 32px; color: rgb(47, 47, 47);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>Instantly
                                        Convert Website Visitors Into Leads And Then Customers ...</b></div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_paragraph-93557"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0"
                                            data-bold="inherit"
                                            style="text-align: left; font-size: 19px; color: rgb(71, 71, 71);"
                                            data-gramm="false" contenteditable="false">Easily communicate with your visitors using our multi-channel engagement software.
                                    </div>
                                </div>
                                <div class="de elBTN elMargin0 elAlign_left ui-droppable de-editable"
                                        id="tmp_button-89788"
                                        data-de-type="button" data-de-editing="false" data-title="button"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 25px; outline: none; cursor: pointer;" aria-disabled="false"
                                        data-elbuttontype="1">
                                    <a @click.prevent="openModal"
                                            class="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1 elButtonCorner5 elBtnHP_40 elButtonFull"
                                            style="color: rgb(255, 255, 255); font-weight: 600; background-color: rgb(49, 121, 199); font-size: 20px;"
                                            rel="noopener noreferrer">
                                        <span class="elButtonMain">Request A Free Demo Today!</span>
                                        <span class="elButtonSub">Get More Leads &amp; Customers Right now!</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="work-with-best-section fullContainer">
                <div class="containerInner">
                    <span class="section-title">We work with the best ...</span>
                    <div class="row image-wrapper">
                        <div class="col-md-3 col-sm-6 col-xs-12"><img src="../../assets/images/landing/wordpress-logo.webp" class="elIMG ximg" alt="" width=""></div>
                        <div class="col-md-3 col-sm-6 col-xs-12"><img src="../../assets/images/landing/shopify-logo.webp" class="elIMG ximg" alt="" width=""></div>
                        <div class="col-md-3 col-sm-6 col-xs-12"><img src="../../assets/images/landing/squarespace-logo.webp" class="elIMG ximg" alt="" width=""></div>
                        <div class="col-md-3 col-sm-6 col-xs-12"><img src="../../assets/images/landing/clickfunnel-logo.webp" class="elIMG ximg" alt="" width=""></div>
                    </div>
                </div>
            </div>
            <div class="drastically-section fullContainer">
                <div class="containerInner">
                    <div class="text-wrapper">
                        <span>Drastically Increase Sales From Your Website By Increasing Conversion Rates & <u>Improving Your Customers Experience!</u></span>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section--71722" data-title="Section" data-block-color="0074C7"
                    style="padding-top: 50px; padding-bottom: 50px; background-color: rgb(255, 255, 255); outline: none;"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--80610" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row"
                            style="padding: 0px; margin: 0px; outline: none;" col-array="5,7">
                        <div id="col-left-108" class="innerContent col_left ui-resizable col-md-5" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-63449" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <img src="../../assets/images/landing/Analytics_Computer.webp" class="elIMG ximg" alt="">
                                </div>
                            </div>
                        </div>
                        <div id="col-right-168" class="innerContent col_right ui-resizable col-md-7" data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-10147"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 32px; color: rgb(47, 47, 47);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>Don’t Make
                                        Your
                                        Customers Wait</b></div>
                                </div>
                                <div class="de elBullet elMargin0 ui-droppable de-editable" id="tmp_list-93057"
                                        data-de-type="list" data-de-editing="false" data-title="icon bullet list"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <ul class="ne elBulletList elBulletListNew elBulletList2 listBorder0"
                                            data-bold="inherit"
                                            data-gramm="false" contenteditable="false" style="color: rgb(71, 71, 71);">
                                        <li>
                                            <i class="fa fa-fw fa-check" style="color: rgb(13, 157, 88);"></i>92% of all
                                            customer interactions happen over the phone. (Salesforce)
                                        </li>
                                        <li><span style="color: inherit;"><i class="fa fa-fw fa-check"
                                                style="color: rgb(13, 157, 88);"></i> It takes an average of 8 cold call attempts to reach a prospect. (SiriusDecisions)</span>
                                        </li>
                                        <li><span style="color: inherit;"><i class="fa fa-fw fa-check"
                                                style="color: rgb(13, 157, 88);"></i> 30-50% of sales go to the vendor that responds first. (InsideSales)</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_paragraph-52396"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 5px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0"
                                            data-bold="inherit" style="text-align: left; color: rgb(71, 71, 71); line-height: 25.5px"
                                            data-gramm="false" contenteditable="false">Give your future customers a
                                        quick and
                                        easy way to get on the phone with you. We simply place an eye-catching call
                                        button on
                                        your website to connect immediately. Visitors can click the Call Button and
                                        enter their
                                        phone number to receive a call within seconds or schedule a call for later.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section--79631" data-title="HowItWorks" data-block-color="0074C7"
                    style="padding-top: 70px; padding-bottom: 50px; background-color: rgb(247, 247, 247); outline: none; margin-top: 0px;"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--22226" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row"
                            style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-113" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable hiddenElementTools de-editable"
                                        id="headline-68831" data-de-type="headline" data-de-editing="false"
                                        data-title="headline" data-ce="true" data-trigger="none" data-animate="fade"
                                        data-delay="500" style="margin-top: 0px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 46px; color: rgb(47, 47, 47);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>How It Works ...</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--27631-107" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row - Clone" style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-140-151" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elSeperator elMargin0 ui-droppable de-editable" id="divider-50656-162"
                                        data-de-type="divider" data-de-editing="false" data-title="Divider"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 30px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="elDivider elDividerStyle1 padding10-top padding10-bottom">
                                        <div class="elDividerInner" data-align="center" data-width-border="75"
                                                style="border-color: rgb(128, 128, 128);"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--16065" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row"
                            style="padding: 40px 0px 10px; margin: 0px; outline: none;" col-array="6,6">
                        <div id="col-left-164" class="innerContent col_left ui-resizable col-md-6" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-60164" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <img src="../../assets/images/landing/hiw_01-2019.webp"
                                            class="elIMG ximg" alt="">
                                </div>
                            </div>
                        </div>
                        <div id="col-right-118" class="innerContent col_right ui-resizable col-md-6" data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-80218"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 23px; color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>Artificial Intelligence</b>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_headline1-61106"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 15px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 18px; color: rgb(71, 71, 71);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">Your visitor clicks the call widget icon in the lower right/left hand corner of the website or our artificial intelligence prompts the widget to open at the ideal time.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--27631" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row"
                            style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-140" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elSeperator elMargin0 ui-droppable de-editable" id="divider-50656"
                                        data-de-type="divider" data-de-editing="false" data-title="Divider"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 30px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="elDivider elDividerStyle1 padding10-top padding10-bottom">
                                        <div class="elDividerInner" data-align="center" data-width-border="75"
                                                style="border-color: rgb(128, 128, 128);"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--16065-186" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row - Clone"
                            style="padding: 40px 0px 10px; margin: 0px; outline: none;"
                            col-array="6,6">
                        <div id="col-left-164-154" class="innerContent col_left ui-resizable col-md-6" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_subheadline-70961"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 23px; color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">
                                        <b>The PopUp</b>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-99162"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 15px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 18px; color: rgb(71, 71, 71);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">A mobile friendly pop-up prompts them to enter their phone number to request an instant callback or text message from your business. The website visitor can also schedule a call later when it is more convenient for them.</div>
                                </div>
                            </div>
                        </div>
                        <div id="col-right-118-148" class="innerContent col_right ui-resizable col-md-6"
                                data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-60164-136" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <img src="../../assets/images/landing/step2.webp"
                                            class="elIMG ximg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--27631-151" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row - Clone" style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-140-177" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elSeperator elMargin0 ui-droppable de-editable" id="divider-50656-147"
                                        data-de-type="divider" data-de-editing="false" data-title="Divider"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 30px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="elDivider elDividerStyle1 padding10-top padding10-bottom">
                                        <div class="elDividerInner" data-align="center" data-width-border="75"
                                                style="border-color: rgb(128, 128, 128);"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--16065-106" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row - Clone"
                            style="padding: 40px 0px 10px; margin: 0px; outline: none;"
                            col-array="6,6">
                        <div id="col-left-164-184" class="innerContent col_left ui-resizable col-md-6" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-60164-125" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <img src="../../assets/images/landing/hiw_05-2019.webp"
                                            class="elIMG ximg" alt="">
                                </div>
                            </div>
                        </div>
                        <div id="col-right-118-112" class="innerContent col_right ui-resizable col-md-6"
                                data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-36023"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 23px; color: rgb(13, 157, 88); border-color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">
                                        <b>14 Second Instant Call Back</b>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-65563"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 15px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 18px; color: rgb(71, 71, 71);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">If your visitor chooses to be called our software platform calls your business and when your sales team or front desk staff answer the phone they are prompted to claim the call by simply pressing #1 on their phone.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--27631-151" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row - Clone" style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-140-177" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elSeperator elMargin0 ui-droppable de-editable" id="divider-50656-147"
                                        data-de-type="divider" data-de-editing="false" data-title="Divider"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 30px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="elDivider elDividerStyle1 padding10-top padding10-bottom">
                                        <div class="elDividerInner" data-align="center" data-width-border="75"
                                                style="border-color: rgb(128, 128, 128);"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--16065-186-163" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row - Clone - Clone"
                            style="padding: 40px 0px 10px; margin: 0px; outline: none;" col-array="6,6">
                        <div id="col-left-164-154-120" class="innerContent col_left ui-resizable col-md-6"
                                data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable"
                                        id="tmp_subheadline-70961-157"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 23px; color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">
                                        <b>Instantly Connected</b>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-99162-112"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 15px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 18px; color: rgb(71, 71, 71);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">After your staff or sales people accept the call our software automatically initiates a call to your website visitor connecting them within 14 seconds. Increasing conversion and improving customers experience.</div>
                                </div>
                            </div>
                        </div>
                        <div id="col-right-118-148-187" class="innerContent col_right ui-resizable col-md-6"
                                data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-60164-136-178" data-de-type="img" data-de-editing="false"
                                        data-title="image" data-ce="false" data-trigger="none" data-animate="fade"
                                        data-delay="500" style="margin-top: 0px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <img src="../../assets/images/landing/how-it-works-heads.webp"
                                            class="elIMG ximg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--27631-151" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row - Clone" style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-140-177" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elSeperator elMargin0 ui-droppable de-editable" id="divider-50656-147"
                                        data-de-type="divider" data-de-editing="false" data-title="Divider"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 30px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="elDivider elDividerStyle1 padding10-top padding10-bottom">
                                        <div class="elDividerInner" data-align="center" data-width-border="75"
                                                style="border-color: rgb(128, 128, 128);"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--16065-107" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row - Clone"
                            style="padding: 40px 0px 10px; margin: 0px; outline: none;"
                            col-array="6,6">
                        <div id="col-left-164-184" class="innerContent col_left ui-resizable col-md-6" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-60164-125" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <img src="../../assets/images/landing/chat-box.webp"
                                            class="elIMG ximg" alt="">
                                </div>
                            </div>
                        </div>
                        <div id="col-right-118-112" class="innerContent col_right ui-resizable col-md-6"
                                data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-36023"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 23px; color: rgb(13, 157, 88); border-color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">
                                        <b>Two-Way Text Messaging</b>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-65563"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 15px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: left; font-size: 18px; color: rgb(71, 71, 71);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">If your visitor choses to be contacted via text message your are immediately notified of new incoming messages. You have access to a simple dashboard to send, receive, and manage text message conversations online or on the go. Engage with your customers faster with two-way text messaging and close the deal.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-your-customer-section fullContainer">
                <div class="containerInner">
                    <span class="section-title">Text Your Customers Online From Your Computer!</span>
                    <div class="inner-wrapper">
                        <img src="../../assets/images/landing/chat-box-big.webp" class="elIMG ximg" alt="" width="">
                    </div>
                </div>
            </div>
            <div class="container noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection fullContainer"
                    id="section--71722-187" data-title="Features" data-block-color="0074C7"
                    style="padding-top: 70px; padding-bottom: 50px; background-color: rgb(247, 247, 247); outline: none;"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--15805" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row"
                            style="padding-top: 20px; padding-bottom: 20px; margin: 0px; outline: none;">
                        <div id="col-full-148" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-73320"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style='margin-top: 0px; outline: none; cursor: pointer;'
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0 yellow-headline"
                                            style="text-align: center; font-size: 46px;"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>We Have All The Features You Will Ever Need</b>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-68726"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0 lh2"
                                            data-bold="inherit"
                                            style="text-align: center; font-size: 17px; line-height: 25.5px; color: rgb(71, 71, 71);"
                                            data-gramm="false" contenteditable="false">
                                        <div>Connect with prospects when they are most likely to make a purchase. Get prospects on the phone or text with your sales team while they are still on your website. Generate more leads and increase conversion rates while streamlining the sales process for your customers and your team.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin all-feature-section"
                            id="row--82920-126" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="3 column row" style="padding: 0px; margin: 0px; outline: none;"
                            col-array="5,2,5">
                        <div id="col-left-150-152" class="innerContent col_left ui-resizable col-md-5" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="tmp_featureimage-73569" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/instant-callback.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Instant
                                                    Callback</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); font-size: 15px; line-height: 23.5px;">Engage your website visitors
                                                    with an
                                                    instant call back within 14 seconds.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-65248" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/calendar1.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>24/7
                                                    Call Scheduling</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">Manage calls based on your business hours and enable call scheduling during off hours.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-65249" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/register1.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>24/7
                                                    Automatic Call Retry</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">When a prospect does not answer, the system will automatically retry the call.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-652459" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/id.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>24/7
                                                    Business Caller ID</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">Set the caller ID to the business and the calls to the visitor will come from the businesses main phone line.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-38686" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/settings-1.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Customizable Widget</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">Customize call buttons, pop-ups, and calls-to-action to match your brand and messaging.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="col-center-115-146" class="innerContent col_right ui-resizable col-md-2"
                                data-col="center"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-97104" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 100px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <img class="feature-image elIMG ximg" src="../../assets/images/landing/features.webp" alt="" height="">
                                </div>
                            </div>
                        </div>
                        <div id="col-right-151-137" class="innerContent col_right ui-resizable col-md-5"
                                data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="3rd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 clearfix elFeatureImage_80_20 ui-droppable elScreenshot_left de-editable"
                                        id="featureimage-43402" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;"><b>Two-Way Texting</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;font-size: 15px; line-height: 23.5px;">A simple dashboard to send, receive, and manage text message conversations online or on the go.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/sms.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 clearfix elFeatureImage_80_20 ui-droppable elScreenshot_left de-editable"
                                        id="featureimage-43402" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;"><b>Text Auto-Reply</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;font-size: 15px; line-height: 23.5px;">When a text message is requested our system will automatically send a welcome message.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/autotxt.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 clearfix elFeatureImage_80_20 ui-droppable elScreenshot_left de-editable"
                                        id="featureimage-82601" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;"><b>International Numbers</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;font-size: 15px; line-height: 23.5px;">System works internationally so that you can market to customers in any country around the world.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/group.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 clearfix elFeatureImage_80_20 ui-droppable elScreenshot_left de-editable"
                                        id="featureimage-64110" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;"><b>Real-Time Reporting</b>
                                                </h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;font-size: 15px; line-height: 23.5px;">Customer reporting gives you deep insight into your prospects and employees.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/laptop.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                    </div>
                                </div>
                                <div class="de elMargin0 clearfix elFeatureImage_80_20 ui-droppable elScreenshot_left de-editable"
                                        id="featureimage-64110" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;"><b>Automated Notification</b>
                                                </h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71); text-align: right;font-size: 15px; line-height: 23.5px;">When call are missed or scheduled the business owner receives text & email notifications.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/teamwork.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--65549" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row"
                            style="padding-top: 20px; padding-bottom: 20px; margin: 0px; outline: none;">
                        <div id="col-full-157" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elSeperator elMargin0 ui-droppable de-editable" id="divider-93565"
                                        data-de-type="divider" data-de-editing="false" data-title="Divider"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 10px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="elDivider elDividerStyle1 padding10-top padding10-bottom">
                                        <div class="elDividerInner" data-align="center" data-width-border="75"
                                                style="border-color: rgb(128, 128, 128);"></div>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_headline1-17069"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 20px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0 yellow-headline"
                                            style="text-align: center; font-size: 32px;"
                                            data-bold="inherit" data-gramm="false" contenteditable="false">
                                        <b>Additional Features</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin additional-feature-section"
                            id="row--15461" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="3 column row"
                            style="padding: 20px 0px; margin: 0px; outline: none; width: auto;">
                        <div id="col-left-148" class="col-md-4 innerContent col_left ui-resizable" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-38686" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/settings.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Setup Wizard</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">A simple wizard that guides you through setting up your call widget in less than 5 minutes.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-38686" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/smsnotificatoin.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Text Notifications</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">SMS alerts to the prospect and business, when call is scheduled or missed.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="col-center-166" class="col-md-4 innerContent col_right ui-resizable" data-col="center"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-38686" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/code.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Exit Monitoring</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">Advanced Exit Technology triggers the callback widget when your visitor intends to leave your site.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-38686" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/group.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Intelligent Routing</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">It is simple to direct calls to unlimited agents in the office or on their mobile phones.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="col-right-103" class="col-md-4 innerContent col_right ui-resizable" data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="3rd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-38686" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/phone-icon.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Intelligent Pop Ups</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">The callback widget automatically pops up at the right moment prompting the visitor to initiate an instant callback.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elMargin0 elScreenshot_right clearfix elFeatureImage_80_20 ui-droppable de-editable"
                                        id="featureimage-38686" data-de-type="featureimage" data-de-editing="false"
                                        data-title="Image Feature" data-ce="true" data-trigger="none"
                                        data-animate="fade"
                                        data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;"
                                        aria-disabled="false" data-element-theme="customized">
                                    <div class="elFeatureImage">
                                        <div class="elScreenshot_image elAlign_center">
                                            <img src="../../assets/images/landing/calendar1.webp"
                                                    class="elScreenshot_image_src1 ximg effect2 effectbox" height="auto"
                                                    width="auto" style="">
                                        </div>
                                        <div class="elScreenshot_text">
                                            <div class="elScreenshot_text_padding" style="padding: 0 10px">
                                                <h3 class="ne elScreenshot_text_headline ssHeadlineSize2"
                                                        contenteditable="false" style="color: rgb(71, 71, 71);"><b>Responsive Design</b></h3>
                                                <div class="ne elScreenshot_text_body ssBodySize1"
                                                        contenteditable="false"
                                                        style="color: rgb(71, 71, 71);font-size: 15px; line-height: 23.5px;">The fully responsive widget automatically adjust to the screen size for flawless user experience.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section--71678-173" data-title="Section - Clone" data-block-color="0074C7"
                    style="padding-top: 50px; padding-bottom: 50px; background-color: rgb(255, 255, 255); outline: none;"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--31089-157" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row" style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-177-161" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_headline1-19822-150"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" 
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 32px; color: rgb(47, 47, 47);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>What
                                        Others Are
                                        Saying About Us!</b></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-wrapper">
                        <vueper-slides
                            class="no-shadow"
                            :visible-slides="isMobile ? 1 : 3"
                            :slide-ratio="1 / 4"
                            :dragging-distance="70">
                            <vueper-slide key="testimonial_1">
                                <template v-slot:content>
                                    <span class="testimonial">"A simple widget that truly enhances a website visitors experience. A great tool every website owner should have. Highly recommend."</span>
                                    <span class="testimonial_writer">David S.</span>
                                    <div>
                                        <img src="../../assets/images/landing/5starss.webp" class="elIMG ximg" alt="" height="25" tabindex="0">
                                    </div>
                                </template>
                            </vueper-slide>
                            <vueper-slide key="testimonial_2">
                                <template v-slot:content>
                                    <span class="testimonial">"This thing has made my phone ring off the hook. If you are a service based industry you definitely need to get this thing."</span>
                                    <span class="testimonial_writer">Duan B.</span>
                                    <div>
                                        <img src="../../assets/images/landing/5starss.webp" class="elIMG ximg" alt="" height="25" tabindex="0">
                                    </div>
                                </template>
                            </vueper-slide>
                            <vueper-slide key="testimonial_3">
                                <template v-slot:content>
                                    <span class="testimonial">"Wonderful and refreshing business experience. Software really rocks. Ethics and quality built in America."</span>
                                    <span class="testimonial_writer">Don W.</span>
                                    <div> 
                                        <img src="../../assets/images/landing/5starss.webp" class="elIMG ximg" alt="" height="25" tabindex="0">
                                    </div>
                                </template>
                            </vueper-slide>
                        </vueper-slides>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section--71722-187-114-126" data-title="Section - Clone - Clone - Clone"
                    data-block-color="0074C7"
                    style="padding-top: 50px; padding-bottom: 50px; background-color: rgb(247, 247, 247); outline: none;"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--44332" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row"
                            style="padding-top: 20px; padding-bottom: 20px; margin: 0px; outline: none;">
                        <div id="col-full-151" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-98051"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style='margin-top: 0px; outline: none; cursor: pointer;'
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 46px; color: rgb(47, 47, 47);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>Start Generating More Business Today</b></div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-76738"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0 lh2"
                                            data-bold="inherit"
                                            style="text-align: center; font-size: 17px; font-weight: 600; color: rgb(71, 71, 71);"
                                            data-gramm="false" contenteditable="false">It Takes Less Than 10 Minutes To Setup A Call Widget On Your Website</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--82920-126-128-169" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="3 column row" style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-left-150-152-106-167" class="col-md-4 innerContent col_left ui-resizable"
                                data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-77198-128-162-156" data-de-type="img" data-de-editing="false"
                                        data-title="image" data-ce="false" data-trigger="none" data-animate="fade"
                                        data-delay="500" style="margin-top: 0px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <img src="../../assets/images/landing/register1.webp"
                                            class="elIMG ximg" alt="" height="200">
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_subheadline-57860"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 5px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 23px; color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>
                                        Step 1</b></div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable"
                                        id="tmp_paragraph-93236-150-146-140"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 10px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0 lh2"
                                            data-bold="inherit" style="text-align: center; font-size: 15px; line-height: 23.5px;"
                                            data-gramm="false"
                                            contenteditable="false">Registering is simple. Just enter your email address and schedule a demo.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="col-center-115-146-157-116" class="col-md-4 innerContent col_right ui-resizable"
                                data-col="center" data-trigger="none" data-animate="fade" data-delay="500"
                                data-title="2nd column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-27794-153-122-146" data-de-type="img" data-de-editing="false"
                                        data-title="image" data-ce="false" data-trigger="none" data-animate="fade"
                                        data-delay="500" style="margin-top: 0px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <img src="../../assets/images/landing/monitor.webp"
                                            class="elIMG ximg" alt="" height="200">
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-12284"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 5px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 23px; color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>
                                        Step 2</b></div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable"
                                        id="headline-62609-135-150-169"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 10px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0 lh2"
                                            data-bold="inherit" style="text-align: center; font-size: 15px; line-height: 23.5px;"
                                            data-gramm="false"
                                            contenteditable="false">We walk you through the setup process of customizing your widget.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="col-right-151-137-136-168" class="col-md-4 innerContent col_right ui-resizable"
                                data-col="right" data-trigger="none" data-animate="fade" data-delay="500"
                                data-title="3rd column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-80485-114-174-134" data-de-type="img" data-de-editing="false"
                                        data-title="image" data-ce="false" data-trigger="none" data-animate="fade"
                                        data-delay="500" style="margin-top: 0px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <img src="../../assets/images/landing/code.webp"
                                            class="elIMG ximg" alt="" height="200">
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-71030"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 5px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 23px; color: rgb(13, 157, 88);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>
                                        Step 3</b></div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable"
                                        id="headline-29241-118-140-135"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 10px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0 lh2"
                                            data-bold="inherit" style="text-align: center; font-size: 15px; line-height: 23.5px;"
                                            data-gramm="false"
                                            contenteditable="false">Once we are done setting up the widget we install it on your site.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section-money-back" data-title="Section - Clone" data-block-color="0074C7"
                    style="padding-top: 70px; padding-bottom: 50px; background-color: rgb(255, 255, 255); outline: none;"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--31089-157" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row" style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-177-161" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_headline1-19822-150"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" 
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 42px; color: rgb(47, 47, 47);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>30 Day Money Back Guarantee</b></div>
                                    <div class="testimonial-div ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0 padding0"
                                            style="text-align: center;font-size: 23px; padding:20px 0; font-weight: 700" data-bold="inherit"
                                            data-gramm="false"
                                            contenteditable="false">No Questions Asked
                                    </div>
                                    <div style="text-align: center"><img src="../../assets/images/landing/30-day.webp" width="400" class="elIMG ximg" alt=""></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--14054" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row"
                            style="padding-top: 20px; padding-bottom: 20px; margin: 0px auto; width: 80%; max-width: 100%; outline: none;">
                        <div id="col-full-109" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_subheadline-27388"
                                        data-de-type="headline" data-de-editing="false" data-title="sub-headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="testimonial-div ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0 padding0"
                                            style="text-align: center;font-size: 20px" data-bold="inherit"
                                            data-gramm="false"
                                            contenteditable="false">If you are not 100% satisfied, we are not satisfied. That's why we'll happily give you your money back. Zero questions asked. 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section--92732" data-title="Section" data-block-color="0074C7"
                    style="padding-top: 50px; padding-bottom: 50px; background-color: rgb(229, 247, 254); outline: none;"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--39547" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="1 column row"
                            style="padding: 0px; margin: 0px; outline: none;">
                        <div id="col-full-105" class="col-md-12 innerContent col_left" data-col="full"
                                data-trigger="none"
                                data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="headline-60320"
                                        data-de-type="headline" data-de-editing="false" data-title="headline"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;"
                                        aria-disabled="false">
                                    <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0"
                                            style="text-align: center; font-size: 32px; color: rgb(47, 47, 47);"
                                            data-bold="inherit" data-gramm="false" contenteditable="false"><b>With our multi-channel communication widget you can stop chasing leads and start closing deals instantly turning website visitors into customers</b>
                                    </div>
                                </div>
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_paragraph-64778"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0 lh2"
                                            data-bold="inherit"
                                            style="text-align: center; font-size: 17px; font-weight: 600; color: rgb(71, 71, 71);"
                                            data-gramm="false" contenteditable="false">
                                        Convert website traffic to sales instantly with our multi-channel lead engagement software. This new technology lets you build on high-value engagements – creating a unique experience for prospects and customers.
                                    </div>
                                </div>
                                <div class="de elBTN elMargin0 ui-droppable elAlign_center de-editable"
                                        id="button-31118"
                                        data-de-type="button" data-de-editing="false" data-title="button"
                                        data-ce="false"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 30px; outline: none; cursor: pointer;" aria-disabled="false"
                                        data-elbuttontype="1">
                                    <a
                                            class="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1 elButtonCorner5 elButtonFluid elBtnHP_40"
                                            style="color: rgb(255, 255, 255); font-weight: 600; background-color: rgb(13, 157, 88); font-size: 20px;"
                                            rel="noopener noreferrer" id="undefined-691-353"
                                            @click.prevent="openModal">
                                        <span class="elButtonMain">Request A Free Demo Today!</span>
                                        <span class="elButtonSub">Get More Leads &amp; Customers Right now!</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection"
                    id="section--45040" data-title="Section" data-block-color="0074C7"
                    style="padding-top: 30px; padding-bottom: 30px; background-color: rgb(247, 247, 247); outline: none; color: rgb(96, 96, 96);"
                    data-trigger="none" data-animate="fade" data-delay="500">
                <div class="containerInner ui-sortable">
                    <div class="row bgCover noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                            id="row--77765" data-trigger="none" data-animate="fade" data-delay="500"
                            data-title="2 column row"
                            style="padding-top: 0px; padding-bottom: 0px; margin: 0px; outline: none;" col-array="3,9">
                        <div id="col-left-118" class="innerContent col_left ui-resizable col-md-3" data-col="left"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elImageWrapper de-image-block elAlign_center elMargin0 ui-droppable de-editable"
                                        id="tmp_image-20839" data-de-type="img" data-de-editing="false"
                                        data-title="image"
                                        data-ce="false" data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 0px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <router-link :to="{path: '/'}">
                                        <img v-if="sitedata.headerLogo" :src="sitedata.headerLogo" class="elIMG ximg logo-img" alt=""/>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div id="col-right-140" class="innerContent col_right ui-resizable col-md-9" data-col="right"
                                data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column"
                                style="outline: none;">
                            <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin"
                                    style="padding: 0 10px">
                                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_paragraph-71575"
                                        data-de-type="headline" data-de-editing="false" data-title="Paragraph"
                                        data-ce="true"
                                        data-trigger="none" data-animate="fade" data-delay="500"
                                        style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                                    <div class="ne elHeadline hsSize1 lh5 elMargin0 elBGStyle0 hsTextShadow0"
                                            data-bold="inherit" data-gramm="false"
                                            contenteditable="false">
                                        <span>@ Copyright {{copyRightYear}} {{sitedata.title}}. All rights reserved.</span> | <a href="javascript:;" @click.prevent="openPage('terms-of-services')">Terms of Service</a> | <a href="javascript:;" @click.prevent="openPage('privacy-policy')">Privacy Policy</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="hidden" name="cf-state-county-dropdown-feature-enabled" value="false">
        </div>

        <register-form ref="form"></register-form>
        <page-modal ref="pageModal"></page-modal>
        </div>
    </div>
</template>

<script>
import RegisterForm from '../landing/RegisterForm'
import PageModal from '../landing/PageModal'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import postscribe from 'postscribe'

export default {
    name: 'app-landing',
    components: {
        RegisterForm, PageModal, VueperSlides, VueperSlide
    },
    data () {
        return {
            modalOpen: false,
            isReady: false,
        }
    },
    mounted () {
        this.addLoadEvent();
    },
    created () {
        this.getMetaInfoLogin();
        this.modalOpen = false;
    },
    beforeDestroy () {
        this.destroy_custom_script();
    },
    methods: {
        install_custom_script() {
            if (!$('#custom-scripts').length)
            {
                $('body').append(`<div id="custom-scripts"></div>`)
            }

            if ($('#LEWIDGET')) {
                $('#LEWIDGET').remove()
            }
            if (window.LEPLUGINWIDGET)
            {
                window.LEPLUGINWIDGET = null;
            }
            if (this.$store.state.app.sitedata.custom_script)
            {
                postscribe('#custom-scripts', this.$store.state.app.sitedata.custom_script, {
                    done: () => {
                        setTimeout(() => {
                            if (this.$route.name !== 'landing')
                                this.destroy_custom_script()
                        }, 3000);
                    },
                })
            }
        },
        destroy_custom_script() {
            if ($('#custom-scripts')) {
                $('#custom-scripts').remove()
            }
            if ($('#LEWIDGET')) {
                $('#LEWIDGET').remove()   
            }
        },
        getMetaInfoLogin () {
            this.$store.dispatch('getSiteData', window.location.host).then(result => {
                const landingPage = this.$store.state.app.sitedata.landingPage
                if (!landingPage)
                {
                    this.$router.push({name: 'login'})
                } else {
                    if (this.$store.state.app.sitedata.isMainAgency) {
                        window.location.href = 'https://www.torowave.com'
                        return
                    } else {
                        this.isReady = true;
                        if (this.$route.name === 'landing')
                            this.install_custom_script();
                    }
                }
            })
        },
        openModal () {
            this.$refs.form.openModal()
        },
        goToByScroll (ele) {
            $('html,body').animate({
                scrollTop: $(ele).offset().top,
            }, 'slow')
        },
        addLoadEvent (link) {
            $('.nodoNavLink').off('touchstart click').on('touchstart click', (e) => {
                const section = $(e.target).attr('href').replace('#scroll-', '')
                if (section !== '/login') {
                    e.preventDefault()
                    this.goToByScroll(`[data-title="${section}"]`)
                }
            })
        },

        openPage (key) {
            this.$refs.pageModal.openModal(key)
        },
    },
    computed: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },

        sitedata () {
        return this.$store.state.app.sitedata
        },
        user () {
            return this.$store.state.auth.user
        },
        copyRightYear () {
            return this.$store.getters["copyRightYear"]
        }
    },
}
</script>

<style lang="scss">
    .modal-backdrop.fade {
        opacity: 0.5 !important;
        filter: alpha(opacity=0.5) !important;
    }
    div.row {
        &:before {
            content: none;
        }

        &:after {
            content: '';
        }
    }
</style>
