<template>
  <vuestic-sidebar class="sidebar" :hidden="isOpen" :class="{
      'agency': user && user.role_group == 'Agency'
    }">
    <template slot="menu" v-if="user && menuItems[menuKey]">
      <li v-for="(item, index) in menuItems[menuKey]" :key="index" :class="{
          disabled: ['agency.subscriptions', 'agency.training'].indexOf(item.to) === -1 && !isCompletedSetup
        }" v-if="tabShow(item)">
        <div class="parent-li-wrapper">
          <span v-if="item['name'] == 'menu.messaging' && numberOfMessages" class="number-of-notifications badge badge-danger">{{
          numberOfMessages }}</span>
          <span v-if="item['name'] == 'menu.tasks' && numberOfTasks" class="number-of-notifications badge badge-danger">{{
            numberOfTasks }}</span>
          <a v-if="item.childs" class="sidebar-link" href="javascript: void(0)" :class="{
              'router-link-active': isParentItem(item)
            }" @click.prevent="toggleMenu(item)">
            <div v-b-tooltip.hover v-b-tooltip.right :title="item.name | translate" delay="{
                'show': 100, 
                'hide': 1
              }">
              <i v-if="!item.type" class="sidebar-menu-item-icon" :class="item.icon"></i>
            </div>
          </a>
          <template v-else>
            <router-link v-if="item.to" :to="{ name: item.to }" class="sidebar-link" :class="{
                'router-link-active': isParentItem(item)
              }" @click.native="toggleMenu(item)">
              <div v-b-tooltip.hover v-b-tooltip.right :title="item.name | translate" delay="{
                  'show': 100, 
                  'hide': 1
                }">
                <i v-if="!item.type" class="sidebar-menu-item-icon" :class="item.icon"></i>
                <div v-if="item.type == 'img'" class="sidebar-menu-item-icon">
                  <img :src="require(`@/assets/images/${item.filename}`)" width="20px" height="20px">
                </div>
              </div>
            </router-link>
            <a v-if="item.toOut" :href="item.toOut" class="sidebar-link" target="_blank">
              <div v-b-tooltip.hover v-b-tooltip.right :title="item.name | translate" delay="{
                  'show': 100, 
                  'hide': 1
                }">
                <i v-if="!item.type" class="sidebar-menu-item-icon" :class="item.icon"></i>
                <div v-if="item.type == 'img'" class="sidebar-menu-item-icon">
                  <img :src="require(`@/assets/images/${item.filename}`)" width="20px" height="20px">
                </div>
              </div>
            </a>
          </template>
        </div>

        <expanding v-if="showSubMenu(item.childs)" :class="{ in: submenuOpened(item) }">
          <ul class="sidebar-submenu collapse">
            <li v-if="child.visible && tabShow(child)" v-for="(child, index) in item.childs" :key="index">
              <router-link class="sidebar-link sidebar-submenu-link"
                :class="{ 'router-link-active': isParentItem(child) }" :to="{ name: child.to }">
                <div v-b-tooltip.hover v-b-tooltip.right :title="child.name | translate" delay="{
                    'show': 100, 
                    'hide': 1
                  }">
                  <i v-if="!child.type" class="sidebar-menu-item-icon" :class="child.icon"></i>
                  <div v-if="child.type == 'img'" class="sidebar-menu-item-icon">
                    <img :src="require(`@/assets/images/${child.filename}`)" width="20px" height="20px">
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </expanding>
      </li>
      <div class="profile-link">
        <li v-if="showHelpIcon" class="side-menu-help-link">
          <a href="javascript:;" class="sidebar-link">
            <div v-b-tooltip.hover v-b-tooltip.right title="Got a Question?" delay="{
                'show': 100, 
                'hide': 1
              }">
              <i class="sidebar-menu-item-icon fa fa-question-circle"></i>
            </div>
          </a>
        </li>
        <li v-if="showNotification" class="side-menu-notification">
          <a href="javascript:;" class="sidebar-link">
            <div v-b-tooltip.hover v-b-tooltip.right title="Notifications" delay="{
                'show': 100, 
                'hide': 1
              }">
              <i class="sidebar-menu-item-icon fa fa-bell"></i>
            </div>
          </a>
        </li>
        <li v-if="showReleaseNote" class="side-menu-releasenotes">
          <a href="/release-notes" target="_blank" class="sidebar-link">
            <div v-b-tooltip.hover v-b-tooltip.right title="Release Notes" delay="{
                'show': 100, 
                'hide': 1
              }">
              <i class="sidebar-menu-item-icon fa fa-bookmark"></i>
            </div>
          </a>
        </li>
        <li>
          <profile-dropdown>
            <span v-if="avatar" class="avatar-span">
              <img :src="avatar" />
            </span>
            <span v-else class="avatar-span text">
              <span v-if="user.first_name">{{ user | avatar }}</span>
              <span v-else aria-hidden="true" class="fa fa-user" style="font-size: 26px;"></span>
            </span>
          </profile-dropdown>
        </li>
      </div>
    </template>
  </vuestic-sidebar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProfileDropdown from "./components/ProfileDropdown";
import VuesticSidebar from '../../../vuestic-theme/vuestic-components/vuestic-sidebar/VuesticSidebar'
import Expanding from '../Expanding'

export default {
  name: 'sidebar',

  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },

  components: {
    ProfileDropdown,
    VuesticSidebar,
    Expanding
  },

  mounted() {
    this.initMenuOpen()
    if (this.showNotification) {
      this.install_notification_script();
    }
  },

  watch: {
    $route: function (to, from) {
      if (to && from && to.name !== from.name) {
        this.initMenuOpen()
      }
    }
  },

  beforeDestroy() {
    this.destroy_notification_script();
  },

  data() {
    return {
      show: true,
    }
  },

  methods: {
    initMenuOpen() {
      const userData = {user_type: this.user.user_type, custom_type_pages: this.user.settings.custom_type_pages};
      this.$store.dispatch('addCustomTypeMenuItem', {userData});
      this.$forceUpdate();
      this.menuItems[this.menuKey] && this.menuItems[this.menuKey].forEach((item, index) => {
        if (item.childs) {
          item.childs.forEach((item1, index1) => {
            if (this.$route.name === item1.to && item1.visible ||
              item1.childs && item1.childs.find((child1) => child1.to === this.$route.name)
            ) {
              this.$store.dispatch('updateSubmenuOpened', true)
            }
          });
        }
      });
    },
    install_notification_script() {
      const widgetWrapper = document.getElementById('beamer-script-container')

      if (widgetWrapper)
        return true;

      if (!document.getElementById('beamer-script-container')) {
        let body = document.getElementsByTagName('body')[0];
        let div = document.createElement('div');
        div.setAttribute('id', 'beamer-script-container');
        body.appendChild(div);
      }

      let script = document.createElement('script')

      window.beamer_config = {
        product_id: 'rQEkrgHA34253',
        selector: '.side-menu-notification',
        lazy: true,
        user_firstname: this.user && this.user.first_name || '',
        user_lastname: this.user && this.user.last_name || '',
        user_email: this.user && this.user.email || '',
        user_id: this.user && this.user.id || '',
      };

      script.type = 'text/javascript'
      script.src = 'https://app.getbeamer.com/js/beamer-embed.js'
      script.onload = () => {
        window.Beamer && window.Beamer.init()
      }
      document.getElementById('beamer-script-container').appendChild(script);
    },

    destroy_notification_script() {
      const widgetWrapper = document.getElementById('beamerOverlay');
      const scriptWrapper = document.getElementById('beamer-script-container');
      widgetWrapper && widgetWrapper.remove();
      scriptWrapper && scriptWrapper.remove();
    },

    isParentItem(item) {
      let result = false;
      if (item.childs) {
        item.childs.forEach((child, index) => {
          if (child.to === this.$route.name)
            result = true;
          if (child.childs && child.childs.find((child1) => child1.to === this.$route.name)) {
            result = true;
          }
        });
      }
      return result
    },

    submenuOpened(item) {
      let result = item.isOpen || this.isParentItem(item)
      return result
    },

    showSubMenu(childs) {
      let result = false;
      if (childs) {
        childs.forEach((item, index) => {
          if (item.visible && this.tabShow(item))
            result = true
          if (item.childs) {
            item.childs.forEach((item1, index1) => {
              if (this.$route.name === item1.to)
                result = true
            });
          }
        });
      }
      return result
    },

    toggleMenu(item) {
      this.$store.dispatch('hideSubmenu')
      item.isOpen = !item.isOpen
      if (item.childs) {
        this.$store.dispatch('updateSubmenuOpened', item.isOpen && this.showSubMenu(item.childs))
        const isChildOpen = item.childs.some((child) => child.to === this.$route.name)
        if ((!isChildOpen || !this.showSubMenu(item.childs)) && this.$route.name !== item.childs[0].to) {
          this.$router.push({ name: item.childs[0].to })
        }
      } else {
        this.$store.dispatch('updateSubmenuOpened', false)
      }
    },

    tabShow(item) {
      const paidMemberTabs = [
        'menu.subscriptions',
      ]
      const stripeMemberTabs = [
        'menu.paymentMethods',
        'menu.businessPackages.root',
      ]
      const textTabs = [
        'menu.messaging',
        'menu.broadcast',
        'menu.templates',
      ]
      const phoneTabs = [
        'menu.calls',
        'menu.scheduledCalls',
      ]

      const departmentTabs = [
        'menu.departments',
      ]

      const smsAgent = [
        'menu.messaging',
      ]

      const voiceAgent = [
        'menu.calls',
      ]

      const templateAgency = [
        'menu.businessAccounts.root'
      ]

      const templateBusiness = [
        'menu.broadcast',
        'menu.automation',
        'menu.installs.root',
        'menu.settings',
        'menu.departments',
        'menu.templates',
        'menu.tags'
      ]

      const salesUserTabs = [
        'menu.contacts',
        'menu.tags',
      ];

      const isFree = !paidMemberTabs.includes(item.name) || (paidMemberTabs.includes(item.name) && (this.user.role_group == 'Agency' && !this.user.agency.free_fee || this.user.role_group == 'Business' && !this.user.business.free_fee && this.hasAgencyStripeEnabled))
      const isStripe = !stripeMemberTabs.includes(item.name) || (stripeMemberTabs.includes(item.name) && (this.user.role_group != 'Business' || this.hasAgencyStripeConfig))
      const isText = !textTabs.includes(item.name) || (textTabs.includes(item.name) && this.$store.getters['auth/widgetTypeText'])
      const isPhone = !phoneTabs.includes(item.name) || (phoneTabs.includes(item.name) && this.$store.getters['auth/widgetTypePhone'])
      const isDepartment = !departmentTabs.includes(item.name) || (departmentTabs.includes(item.name) && this.$store.getters['auth/departmentEnabled'])
      const isSmsAgent = !smsAgent.includes(item.name) || (smsAgent.includes(item.name) && (this.$store.getters['auth/isSmsAgent'] || this.isBusinessManager))
      const isVoiceAgent = !voiceAgent.includes(item.name) || (voiceAgent.includes(item.name) && (this.$store.getters['auth/isVoiceAgent'] || this.isBusinessManager))
      const isZapier = item.name !== 'menu.zapier' || (['Agency'].includes(this.user.role_group) && this.$store.getters['auth/agencyZapierEnabled'] && !this.$store.getters['auth/agencyZapierFree'])
      const isTemplateAgency = this.user.role_group !== 'Agency' || !this.user.agency.is_template || templateAgency.includes(item.name)
      const isTemplateBusiness = this.user.role_group !== 'Business' || !this.user.business.is_template || templateBusiness.includes(item.name)

      const isSalesUser = !salesUserTabs.includes(item.name) || this.user.role_group !== 'Business' || this.user.user_type !== '3' || this.user.business.has_sales_user_extension

      return isFree && isStripe && isText && isPhone && isDepartment && isSmsAgent && isVoiceAgent &&
        isZapier && isTemplateAgency && isTemplateBusiness && isSalesUser
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user
    },

    avatar() {
      return this.user && this.user.avatar
    },

    numberOfMessages() {
      return this.user && this.user.number_of_messages
    },

    numberOfTasks() {
      return this.user && this.user.number_of_tasks
    },

    isCompletedSetup() {
      return this.$store.getters['auth/isCompletedSetup']
    },

    isBusinessManager() {
      return this.$store.getters["auth/isBusinessManager"];
    },

    hasAgencyStripeConfig() {
      return this.$store.getters['auth/hasAgencyStripeConfig'];
    },

    hasAgencyStripeEnabled() {
      return this.$store.getters['auth/hasAgencyStripeEnabled']
    },

    menuItems() {
      const sidebarConfig = this.$store.getters['sidebarConfig']
      if (sidebarConfig)
        return sidebarConfig.menuItems
      return []
    },

    menuKey() {
      let key = this.user.role;
      if (this.user.role === 'AgencyUser') {
        switch (this.user.user_type) {
          case '2':
            key = 'FinanaceManager'
            break;
          case '3':
            key = 'MarketingManager'
            break;
          case '1':
          default:
            key = 'AccountManager'
            break;
        }
      }

      if (this.user.role === 'BusinessUser') {
        switch (this.user.user_type) {
          case '2':
            key = 'MarketingUser'
            break;
          case '3':
            key = 'SalesUser'
            break;
          case '4':
            key = 'CustomType'
            break;
          case '1':
          default:
            key = 'AdminSupport'
            break;
        }
      }

      return key
    },
    showHelpIcon() {
      return this.user.role_group === 'Business'
    },
    showNotification() {
      return this.user.role === 'AgencyAdmin'
    },
    showReleaseNote() {
      return this.user && this.user.role_group == 'Agency' && process.env.NODE_ENV !== 'production'
    },
  }
}
</script>

<style lang="scss">
@import "../../../sass/_variables.scss";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";

.sidebar {
  height: $sidebar-viewport-height;
  width: $sidebar-width;
  top: $sidebar-top;
  left: $sidebar-left;
  transition: all 0.2s ease;
  opacity: 1;
  position: fixed;
  background-color: #4e5468;
  z-index: 1000;

  &.agency {
    background-color: #05508e;

    .sidebar-link.router-link-active {
      >div {
        background-color: #1a76d2;
      }
    }

    .sidebar-submenu {
      background-color: #1a76d2;
    }
  }

  .layout.has-notification & {
    top: calc(#{$sidebar-top} + #{$top-notification-height});
    height: calc(#{$sidebar-viewport-height} - #{$top-notification-height});
    ;
  }

  .profile-link {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }

  .sidebar-menu {
    div.parent-li-wrapper {
      position: relative;
    }
    li.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .va-scrollbar {
    height: 100%;

    .scrollbar-wrapper {
      border-radius: 0 !important;
      box-shadow: $sidebar-box-shadow;
      height: 100%;
    }

    .scrollbar-content {
      color: $rb-blue;
      height: 100%;
    }
  }

  .sidebar-hidden_without-animation & {
    transition: none;
  }

  .sidebar-hidden & {
    top: $sidebar-hidden-top;
    opacity: 0;
    z-index: $min-z-index;
  }

  .number-of-notifications {
    position: absolute;
    top: 7px;
    right: 7px;
    z-index: 10;
    border-radius: 50%;
  }

  .sidebar-link {
    position: relative;
    height: $sidebar-link-height;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1rem;

    >div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0px;
    }

    &.router-link-active,
    &:hover {
      color: $white;
      border-bottom: 2px solid $white;

      .sidebar-menu-item-icon,
      .expand-icon {
        color: $white;
      }
    }

    &.router-link-active {
      >div {
        background-color: #353a4a;
      }
    }

    .expand-icon {
      position: absolute;
      right: $sidebar-arrow-right;
      top: calc(50% - #{$font-size-root}/2);
      font-weight: bold;
      transition: transform 0.3s ease;
    }

    &.expanded {
      .expand-icon {
        transform: rotate(180deg);
      }
    }

    .sidebar-menu-item-icon {
      font-size: $sidebar-menu-item-icon-size;
      color: $white;
      margin-right: 0px !important;

      &.fa-dashboard {
        /* Temp fix */
        position: relative;
        top: -2px;
      }
    }
  }

  .sidebar-submenu-link {
    height: $sidebar-submenu-link-height;
  }

  .sidebar-menu,
  .sidebar-submenu {
    list-style: none;
    padding-left: 0;

    li {
      display: block;
      padding-left: 0;
      height: $sidebar-submenu-link-height;
    }
  }

  .sidebar-submenu {
    position: absolute;
    top: 0;
    left: $sidebar-link-height;
    background-color: #353A49;
    height: 100%;
    width: $sidebar-link-height;

    .sidebar-link {
      span {
        padding-left: 15px;
        color: $white;
      }
    }
  }

  .sidebar-menu {
    max-height: 100%;
    margin-bottom: 0;
  }

  .expand-icon {
    color: $rb-blue;
  }

  a {
    color: $rb-blue;
    text-decoration: none;
  }
}

.sidebar-menu-item-icon.ion,
.sidebar-menu-item-icon.entypo,
.sidebar-menu-item-icon.fa,
.sidebar-menu-item-icon.vuestic-icon,
.sidebar-menu-item-icon.glyphicon {
  margin-right: 18px !important;
  width: 1rem;
}
</style>
