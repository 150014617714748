import axios from 'axios'

export default {
  namespaced: true,
  state: {
    loading: false,
    plans: [],
    loadedPlans: false,
    paymentMethods: [],
  },
  mutations: {
    loading (state) {
      state.loading = true
    },

    done (state) {
      state.loading = false
    },

    getPlans(state)
    {
      state.loadedPlans = false
      state.plans = []
    },

    getPlansSuccess (state, plans) {
      state.loadedPlans = true
      state.plans = plans
    },

    subscriptionPlanSuccess (state, user) {
      if (this.state.auth.user.id === user.id)
        this.state.auth.user = user
    },

    getPaymentMethodsSuccess (state, paymentMethods) {
      state.paymentMethods = paymentMethods
    },

    addPaymentMethodSuccess (state, data) {
      this.state.auth.user.has_payment_method = true
    },

    removePaymentMethodSuccess (state, paymentMethodId) {
      let index = state.paymentMethods.findIndex(method => method.id == paymentMethodId)
      state.paymentMethods.splice(index, 1)
    },

    setDefaultPaymentMethodSuccess (state, paymentMethodId) {
      state.paymentMethods = [
        ...state.paymentMethods.map(payment => {
          payment.default = payment.id === paymentMethodId
          return payment
        })
      ]
    },

    resumeSubscriptionSuccess (state, user) {
      if (this.state.auth.user.id === user.id)
        this.state.auth.user = user
    },

    cancelSubscriptionSuccess (state, user) {
      if (this.state.auth.user.id === user.id)
        this.state.auth.user = user
    },

    removePlans (state) {
      state.plans = []
      state.loadedPlans = false
    },
  },
  actions: {
    getPlans ({ commit }) {
      commit('loading')
      commit('getPlans')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/subscriptions/plans`)
          .then((res) => {
            commit('done')
            commit('getPlansSuccess', res.data.data)
            resolve(res.data)
          })
          .catch(err => {
            commit('done')
            reject(err)
          })
      })
    },

    getPlansByType ({ commit }, type) {
      commit('loading')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/subscriptions/type/${type}/plans`)
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch(err => {
            commit('done')
            reject(err)
          })
      })
    },

    getPlanByName ({ commit }, args) {
      commit('loading')

      return new Promise((resolve, reject) => {
        axios.get(`/v1/subscriptions/plans/${args.name}${args.restrict ? '?restrict=1' : ''}`)
          .then((res) => {
            commit('done')
            resolve(res.data)
          })
          .catch(err => {
            commit('done')
            reject(err)
          })
      })
    },

    getPaymentMethods ({ commit }) {
      commit('loading')

      return new Promise((resolve, reject) => {
        axios.get(`/v1/subscriptions/payment-methods`)
          .then((res) => {
            commit('done')
            commit('getPaymentMethodsSuccess', res.data.data)
            resolve(res.data)
          })
          .catch(err => {
            commit('done')
            reject(err)
          })
      })
    },

    addPaymentMethod ({ commit }, paymentMethod) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/payment-methods`, {
          paymentMethod: paymentMethod,
        })
          .then((res) => {
            commit('addPaymentMethodSuccess', res.data)
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    removePaymentMethod ({ commit, dispatch }, paymentMethodId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/subscriptions/payment-methods/${paymentMethodId}`)
          .then((res) => {
            dispatch('getPaymentMethods')
            // commit('removePaymentMethodSuccess', paymentMethodId)
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    setDefaultPaymentMethod ({ commit }, paymentMethodId) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/payment-methods/${paymentMethodId}`)
          .then((res) => {
            commit('setDefaultPaymentMethodSuccess', paymentMethodId)
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getSecret ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/subscriptions/secret`)
          .then((res) => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getSecretLanding ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/secret/${params.domain}`, params)
          .then((res) => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    subscriptionPlan ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/subscribe`, data)
          .then((res) => {
            commit('subscriptionPlanSuccess', res.data.data.user)
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    
    userSubscriptionPlan ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/${data.id}/subscribe`, data)
          .then((res) => {
            commit('subscriptionPlanSuccess', res.data.data.user)
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    getCoupon ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/coupon-info`, data)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    applyCoupon ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/coupon/apply`, data)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    orderBump ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/subscriptions/order-bump`, data)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    cancelSubscription ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const { userId, type } = data
        axios.post(`/v1/subscriptions/${userId}/cancel`, data)
          .then((res) => {
            commit('cancelSubscriptionSuccess', res.data.data.user)
            resolve(res.data.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    resumeSubscription ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const { userId, type } = data
        axios.post(`/v1/subscriptions/${userId}/resume`, data)
          .then((res) => {
            commit('resumeSubscriptionSuccess', res.data.data.user)
            resolve(res)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    subscriptionUnlimited ({ commit }, planType) {
      return new Promise((resolve, reject) => {
        const data = {
          planType: planType
        }
        axios.post(`/v1/subscriptions/unlimited-plan`, data)
          .then((res) => {
            resolve(true)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    removePlans ({ commit }) {
      commit('removePlans')
    },

  },

  getters: {
    plans (state) {
      return state.plans
    },

    paymentMethods (state) {
      return state.paymentMethods
    },

    isLoadedPlan (state) {
      return state.loadedPlans
    },
  },
}