import Vue from 'vue'
import axios from 'axios'

const initState = {
  didFetch: false,
  fetching: false,
  processing: false,
  data: [],
  page: 0,
  perPage: 10,
  total: 0,
  filter: {
  }
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    processingItem(state) {
      state.processing = true;
    },

    getList(state, query) {
      state.fetching = true;
      state.total = 0;
      state.page = query.page;
      state.perPage = query.per_page;
    },

    getListSuccess(state, payload) {
      const { data, meta } = payload;
      state.didFetch = true;
      state.fetching = false;
      state.data = state.data.concat(data);
      state.total = meta.total;
    },

    clearList(state) {
      for (let f in state) {
        Vue.set(state, f, initState[f])
      }
    }
  },

  actions: {
    fetch({ state, commit }, query) {
      commit('getList', query);
      return new Promise((resolve, reject) => {
        axios.get(`/v1/medias`, { params: query }).then(res => {
          commit('getListSuccess', {
            data: res.data.data,
            meta: res.data.meta
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    clearList({ commit }) {
      commit('clearList')
    },
  },

  getters: {
    all(state) {
      const { data } = state
      return data
    }
  }
}