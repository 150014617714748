<template>
  <div class="widget" :class="{ 'no-header': !headerText }">
    <template v-if="!loading">
      <div class="widget-header" v-if="headerText || $slots.header">
        <template v-if="headerText">
          {{ headerText }}
        </template>
        <template v-else>
          <slot name="header"></slot>
        </template>
      </div>
      <div class="widget-body">
        <slot></slot>
      </div>
    </template>
    <template v-else>
      <div class="widget-body loader">
        <div class="row justify-content-center">
          <div class="align-self-center">
            <atom-spinner :animation-duration="1000" :size="120" :color="palette.primary" />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "vuestic-widget",
  components: {
  },
  props: {
    headerText: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters(["palette"])
  }
};
</script>

<style lang="scss">
.widget {
  background: $widget-bg;
  // box-shadow: $widget-box-shadow;
  margin-bottom: $widget-mb;
  padding: 0;

  &.no-header {
    .widget-body {
      height: $widget-body-no-header-height;
    }
  }

  &.larger-padding {
    .widget-body {
      padding: $widget-larger-padding;
    }
  }

  &.no-h-padding {
    .widget-body {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.no-v-padding {
    .widget-body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.brand-info {
    background-color: $brand-info;
    color: $white;
    box-shadow: $widget-info-shadow;
  }

  &.brand-danger {
    background-color: $brand-danger;
    color: $white;
    box-shadow: $widget-danger-shadow;
  }

  &.info-widget {
    border-top: $info-widget-border;

    &.brand-info {
      border-top-color: $brand-info;
    }

    &.brand-danger {
      border-top-color: $brand-danger;
    }
  }

  .widget-body {
    height: $widget-body-with-header-height;
    padding: $widget-padding;

    &.loader {
      min-height: 17em;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .widget-header {
    height: $widget-header-height;
    padding: 5px $widget-padding;
    // border-bottom: $widget-header-border;
    border-bottom: 1px solid #dee2e6;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    color: $rb-light-gray;
    background-color: #f7f9ff;

    .title {
      font-size: 1.1em;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>

