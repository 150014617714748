import axios from 'axios'
import { installStatus } from '../../constants'
export default {
  namespaced: true,

  state: {
    cancelRequest: [],
    selectList: [],
    list: {
      rows: [],
      summary: [],
      total: 1
    },
    didFetch: false,
    fetching: false,
    processing: false,
    data: [],
    selectedId: null,
    page: 1,
    perPage: 10,
    originalForm: {
      type: 'Message',
      is_enable_lead_form: false,
      lead_form_id: null,
      sections: {
        styling: {
          BackgroundColor: '#FFFFFF',
          BorderColor: '#CCCCCC',
          Roudness: 0,
          MaxWidth: 500,
          Padding: 20,
        },
        triggerButton: {
          BackgroundColor: '#1a76d2',
          BorderColor: '#1a76d2',
          HoverBackgroundColor: '#185491',
          HoverBorderColor: '#185491',
          Roudness: 0,
          Color: '#FFFFFF',
          FontSize: 16,
          Padding: 10,
          Align: 'center',
        },
        input: {
          BackgroundColor: '#FFFFFF',
          Color: '#000000',
          BorderColor: '#dee2e6',
          Roudness: 0,
          Padding: 8,
          IsEnableName: false,
          NameLabel: 'Name',
          IsEnableEmail: false,
          IsEnableNameValidation: false,
          EmailLabel: 'Email',
          PhoneLabel: 'Mobile Number',
          MessageLabel: 'Message',
          IsRequiredName: false,
          IsRequiredEmail: false,
        },
        response: {
          SuccessColor: '#0aa60a',
          ErrorColor: '#c30b0b',
          FontSize: 16,
          Align: 'center',
          Padding: 10,
          IsEnableRedirect: false,
          RedirectUrl: '',
          IsRedirectBlankPage: false,
        },
        questionOptions : {
          BackgroundColor: '#FFFFFF',
          BorderColor: '#1a76d2',
          HoverBackgroundColor: '#185491',
          HoverBorderColor: '#185491',
          Roudness: 0,
          Color: '#1a76d2',
          FontSize: 16,
          Padding: 10,
          Align: 'center',
        },
        languageList: [
          {
            language : 'en',
            value: {
              input: {
                NameLabel: "Name",
                EmailLabel: "Email",
                PhoneLabel: "Mobile",
                MessageLabel: "Message"
              },
              response: {
                exceptionTitle: "Something went wrong",
                smsResponseTitle: "We received your message",
                callResponseTitle: "We received your call request",
                leadResponseTitle: "Thanks for your subscription"
              },
              triggerButton: {
                CallLabel: "Call Me Now",
                LeadLabel: "Subscribe",
                MessageLabel: "Send Us A Message",
              }
            },
          },
        ]
      },
    },
  },

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    addRequest (state, payload) {
      state.cancelRequest[payload.type] = payload.source;
    },

    cancelRequest (state, type) {
      if (state.cancelRequest[type]) {
        state.cancelRequest[type]();
      }
      state.cancelRequest[type] = null;
    },

    getList(state) {
      state.didFetch = false;
      state.fetching = true;
      state.selectedId = null;
    },

    getListSuccess(state, payload) {
      const { data } = payload;
      const activeList = data.filter(item => item.status == 'active');
      state.didFetch = true;
      state.fetching = false;
      state.data = data;
      state.selectedId = activeList.length ? activeList[0].id : null;
    },

    filterList(state, payload) {
      if (payload.page != 'undefined')
        state.page = payload.page
      if (payload.perPage != 'undefined')
        state.perPage = payload.perPage
    },

    processingItem(state) {
      state.processing = true;
    },

    done(state) {
      state.processing = false;
    },

    archiveSuccess(state, id) {
      const { data } = state;
      const itemIndex = data.findIndex(item => item.id === id);
      data[itemIndex].status = installStatus.ARCHIVE;

      state.processing = false;
      state.data = data;
    },

    updateSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      const itemIndex = data.findIndex(item => item.id === id);
      data[itemIndex] = payload;
      state.processing = false;
      state.data = data;
    },

    createSuccess(state, payload) {
      const { data } = state;
      state.processing = false;
      state.data = [...data, payload];
    },

    selectInstall(state, id)
    {
      state.selectedId = id;
    },

    updateInstallData(state, params)
    {
      const itemIndex = state.data.findIndex(item => item.id === params.id);
      if (itemIndex !== -1)
      {
        Object.keys(params).forEach(key => {
          if (key !== 'id')
            state.data[itemIndex][key] = params[key]
        });
      }
    },

    updateInstallsData(state, list)
    {
      list.forEach((params) => {
        const itemIndex = state.data.findIndex(item => item.id === params.id);
        if (itemIndex !== -1)
        {
          Object.keys(params).forEach(key => {
            if (key !== 'id')
              state.data[itemIndex][key] = params[key]
          });
        }
      });
    },

    clearList(state) {
      state.didFetch = false;
      state.fetching = false;
      state.processing = false;
      state.data = [];
      state.selectedId = null;
      state.page = 1;
    }
  },

  actions: {
    smsAgents({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        let requestType = 'smsAgent'
        if (state.cancelRequest[requestType])
        {
          commit('cancelRequest', requestType)
        }
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios.get(`/v1/installs/${id}/sms-agents`, { cancelToken: source.token }).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    fetchAllInstalls({ commit }, query = {}) {
      commit('getList')
      return new Promise((resolve, reject) => {
        axios.get('/v1/installs/all', { params: query }).then(res => {
          commit('getListSuccess', res.data);
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },
    fetchInstalls({ state, commit }, query) {
      return new Promise((resolve, reject) => {
        axios.get('/v1/installs', { params: query }).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },
    fetchInstall({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/installs/${id}`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    fetchInstallTwilioStatus({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/installs/${id}/twilio-status`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    createInstall({ state, commit }, payload) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        const { data } = payload
        axios.post(`/v1/installs`, data).then(res => {
          commit('createSuccess', res.data.data)
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    updateInstall({ state, commit }, payload) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        const { id, data } = payload
        axios.put(`/v1/installs/${id}`, data).then(res => {
          commit('updateSuccess', res.data.data)
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    updateSetting({ commit }, payload) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        const { id, data } = payload
        axios.put(`/v1/installs/${id}/setting`, data).then(res => {
          commit('updateSuccess', res.data.data)
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    archiveInstall({ commit }, data) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.put(`/v1/installs/${data.id}/archive`, data).then(res => {
          commit('archiveSuccess', data.id)
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      }); 
    },

    deleteInstall({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/installs/${id}`).then(res => {
          commit('done')
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      }); 
    },

    getEmbedCode ({ commid }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/installs/${id}/embed-code`)
          .then((res) => {
            resolve(res.data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },

    verifyPhoneNumber({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/verify-phone-number`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    searchPhoneNumber({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/search-phonenumber`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    purchasePhoneNumber({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.installId}/purchase-phonenumber`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    checkNumber({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.installId}/check-number`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    assignTwilioNumber({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.installId}/assign-phonenumber`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    assignLandlineNumber({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.installId}/assign-landline-number`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    disableLandlineNumber({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.installId}/disable-landline-number`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    checkCallerId({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/check-caller-id`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    toggleActive({ commit }, {id, active}) {
      return new Promise((resolve, reject) => {
        const data = {
          'active': active
        }
        axios.post(`/v1/installs/${id}/toggle-active`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    releaseNumber({ commit }, install) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${install.id}/release-number`).then(res => {
          commit('done')
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    uploadWidgetIcon({ commit }, {id, data}) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${id}/widgetIcon`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    deleteWidgetIcon({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/installs/${id}/widgetIcon`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    uploadLogo({ commit }, {id, data}) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${id}/logo`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    deleteLogo({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/installs/${id}/logo`).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    allForms({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/installs/${queries.install_id}/forms`, { params: queries }).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    findForm({ commit }, { install, id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/installs/${install}/forms/${id}`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    createForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.install_id}/forms`, data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    updateForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/installs/${data.install_id}/forms/${data.id}`, data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    deleteForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/installs/${data.install_id}/forms/${data.id}`, data).then(res => {
          resolve(true)
        }).catch(e => {
          reject(e)
        })
      });
    },

    toggleActiveForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.install_id}/forms/${data.id}/toggle-active`, data).then(res => {
          resolve(true)
        }).catch(e => {
          reject(e)
        })
      });
    },

    getTollfreeVerification({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/installs/${data.install_id}/tollfree-verification`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    createTollfreeVerification({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/installs/${data.install_id}/tollfree-verification`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    clearList ({ commit }) {
      commit('clearList')
    },
  },

  getters: {
    tableData: (state) =>  (status) => {
      const { data, page, perPage} = state
      const offset = ( page - 1 >= 0 ? page - 1 : 0 ) * perPage;
      const filterdData = data.filter(item => item.status == (status ? status : 'active'));
      return filterdData.slice(offset, offset + perPage);
    },

    tableTotal: (state) => (status) => {
      const { data } = state;
      const filterdData = data.filter(item => item.status == (status ? status : 'active'));
      return filterdData.length;
    },

    getBusinessList: state => state.list,



    originalForm: state => state.originalForm,
  },
}