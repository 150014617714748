import Vue from 'vue'
import axios from 'axios'

const initState = {
  didFetch: false,
  fetching: false,
  processing: false,
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  filter: {
    type: 'all',
    keyword: ''
  }
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    processingItem(state) {
      state.processing = true;
    },

    done(state) {
      state.processing = false;
    },

    getList(state, query) {
      state.fetching = true;
      state.total = 0;
      state.page = query.page;
      state.perPage = query.per_page;
    },

    getListSuccess(state, payload) {
      const { data, meta } = payload;
      state.didFetch = true;
      state.fetching = false;
      state.data = data;
      state.total = meta.total;
    },

    updateSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = state.data.map(item => {
        return item.id === id ? payload : item
      })
      state.processing = false;
    },

    createSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = [payload, ...state.data]
      state.processing = false;
    },

    deleteSuccess(state ,id) {
      state.data = state.data.filter(item => item.id != id)
      state.processing = false;
    },

    clearList(state) {
      state.didFetch = false;
      state.fetching = false;
      state.processing = false;
      state.data = [];
    }
  },

  actions: {
    all({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agency/users/all`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    fetch({ commit }, query) {
      commit('getList', query);
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agency/users`, { params: query }).then(res => {
          commit('getListSuccess', {
            data: res.data.data,
            meta: res.data.meta
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    get({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agency/users/${id}`, {}).then(res => {
          resolve(res.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    update({ commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agency/users/${arg.id}`, arg.data).then(res => {
          commit('updateSuccess', res.data.data)
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },
    
    updatePassword({ commit }, {id, params}) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agency/users/${id}/change-password`, params).then(res => {
          commit('done')
          resolve(true)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    create({ commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agency/users`, arg).then(res => {
          commit('createSuccess', res.data.data)
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    delete({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/agency/users/${id}`).then(() => {
          commit('deleteSuccess', id)
          resolve(id)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    changePassword({ commit }, arg) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agency/users/${arg.id}/change-password`, arg.data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    resetPassword({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agency/users/${id}/reset-password`).then(() => {
          resolve(result)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    clearList ({ commit }) {
      commit('clearList')
    },
  },

  getters: {
    all(state) {
      const { data } = state
      return data
    }
  }
}