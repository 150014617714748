import lazyLoading from './lazyLoading'
import ProductLayout from '../components/layouts/ProductLayout'

export default [{
  path: '/',
  component: ProductLayout,
  children: [
    {
      name: 'product-thankyou',
      path: '/f/thank-you',
      component: lazyLoading('order/ProductThankyou'),
    },
    {
      name: 'product-order',
      path: '/f/:slug',
      component: lazyLoading('order/Product'),
    },
  ],
}, {
  path: '*',
  redirect: '/' 
}]
