<template>
  <div class="order-page">
    <div class="container--full-width container-xl pl-0 pr-0">
      <div class="m-4">
        <div class="mt-4 mb-3">
          <router-link :to="{ path: '/' }" class="float-left">
            <img v-if="loginLogo" class="logo" :src="loginLogo" width="200" alt="logo" />
          </router-link>
          <div class="h5 float-right text-white-50 mt-4 mb-md-2 mt-md-2 text-center" v-bind:class="{ active: isActive }">
            Need help? Contact Support:
            <a class="color-white" :href="`mailto:${supportEmail}?subject=Free Trial Signup Question`">{{ supportEmail
            }}</a>
          </div>
          <div class="clearfix"></div>
        </div>
        <router-view></router-view>
        <div class="mt-5 text-white-50 text-center footer">
          <span>Copyright {{ copyRightYear }} All Rights Reserved</span>
          <a href="https://www.callwidget.co/terms-of-service" target="_blank">Terms of Service</a>
          <a href="https://www.callwidget.co/privacy-policy" target="_blank">Privacy Policy</a>
        </div>
        <div class="mt-2 mb-4 text-white-50 text-center footer">
          <span>{{ address }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  created() {
    let currentHost = window.location.host

    this.$store.dispatch('getSiteData', currentHost)
      .then(({ data }) => {
        if (!data.data.validDomain) {
          this.$router.push({ name: 'landing' });
        }
      })
  },

  data() {
    return {
      isActive: false,
    }
  },

  computed: {
    sitedata() {
      return this.$store.state.app.sitedata
    },
    loginLogo() {
      return this.sitedata && this.sitedata.loginLogo
    },
    supportEmail() {
      return this.sitedata && this.sitedata.supportEmail
    },
    address() {
      return this.sitedata && this.sitedata.address
    },
    copyRightYear() {
      return this.$store.getters["copyRightYear"]
    }
  },
}
</script>

<style lang="scss">
.order-page {
  background: #000;
  color: #333;
  min-height: 100vh;
}

a.color-white {
  color: #fff;

  &:focus,
  &:hover {
    color: #ddd;
  }
}

@media (max-width: 767px) {
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.footer {

  span,
  a {
    color: #fff;
    font-size: 16px;
    display: inline-block;
    margin-left: 20px;
  }

  a:hover {
    text-decoration: underline;
  }

  ;
}</style>
