import axios from 'axios'

export default {
  namespaced: true,

  state: {
    didFetch: false,
    fetching: false,
    processing: false,
    data: [],
    page: 1,
    perPage: 10,
    total: 0,
    filter: {
      type: 'all',
      keyword: ''
    },
    selectedId: undefined,
  },

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    processingItem(state) {
      state.processing = true;
    },

    done(state) {
      state.processing = false;
    },

    getList(state, query) {
      state.didFetch = false;
      state.fetching = true;
      state.data = [];
      state.total = 0;
      state.page = query.page;
      state.perPage = query.per_page;
    },

    getListSuccess(state, payload) {
      const { data, meta } = payload;
      state.fetching = false;
      state.didFetch = true;
      state.data = data;
      state.total = meta.total;
    },

    updateFilter(state, payload) {
      state.filter = payload;
    },

    bulkTagSuccess(state, payload) {
      const { data } = state;
      const { ids, tags, type } = payload;
      state.processing = false;
      state.data = data.map(item => {
        const obj = item;
        if (ids.includes(item.id ))
        {
          if (type === 'single')
          {
            obj.tags = tags.map(item => {
              return {
                'id': item.id,
                'name': item.name
              }
            });
          } else {
            tags.forEach(item => {
              let exists = obj.tags.findIndex((item1) => {
                return item1.id === item.id
              });
              if (exists === -1)
              {
                obj.tags.push({
                  'id': item.id,
                  'name': item.name
                })
              }
            });
          }
        }
        return obj
      })
    },

    tagSuccess(state, contact) {
      const { data } = state;
      state.processing = false;
      state.data = data.map(item => {
        return item.id === contact.id ? contact : item
      })
    },

    createSuccess(state, payload) {
      const { data, perPage } = state;
      state.processing = false;
      const index = data.findIndex(item => item.id === payload.data.id)
      if (index === -1)
      {
        state.data = [payload.data, ...data].slice(0,perPage);
      } else {
        data.splice(index, 1);
        state.data = [payload.data, ...data]
      }
      state.total = state.total + 1;
    },

    updateSuccess(state, payload) {
      const { data } = state;
      const itemIndex = data.findIndex(item => item.id === payload.data.id);
      data.splice(itemIndex, 1, payload.data);
      state.processing = false;
      state.data = data;
    },

    deleteSuccess(state, id) {
      const { data } = state;
      state.processing = false;
      state.data = data.filter(item => item.id != id);
      state.total = state.total - 1;
    },

    bulkDeleteSuccess(state, id) {
      const { data } = state;
      state.processing = false;
    },

    setSelectedId(state, id) {
      state.selectedId = id
    },

    clearList(state) {
      state.didFetch = false;
      state.fetching = false;
      state.processing = false;
      state.data = [];
      state.selectedId = undefined;
      state.page = 1;
      state.perPage = 10;
      state.total = 0;
      state.filter = {
        type: 'all',
        keyword: ''
      }
    }
  },

  actions: {
    getList ({ commit }, { installID, query }) {
      commit('getList', query)
      return new Promise((resolve, reject) => {
        axios.get(`/v1/chats/installs/${installID}/contacts`, { params: query })
          .then((res) => {
            commit('getListSuccess', res.data)
            resolve(res.data)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    bulkTags ({ commit }, payload) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/tag`, payload)
          .then((res) => {
            commit('bulkTagSuccess', payload)
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    tags ({ commit }, payload) {
      commit('processingItem')
      const { id, data } = payload
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/${id}/tag`, data)
          .then((res) => {
            commit('tagSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    fetch ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/chats/contacts/${id}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    create ({ commit }, payload) {
      commit('processingItem')
      const { id, data } = payload
      return new Promise((resolve, reject) => {
        axios.post(`/v1/chats/installs/${id}/contacts`, data)
          .then((res) => {
            commit('createSuccess', res.data)
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    find ({ commit }, contactId) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/contacts/${contactId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    update ({ commit }, payload) {
      commit('processingItem')
      const { id, data } = payload
      return new Promise((resolve, reject) => {
        axios.put(`/v1/chats/contacts/${id}`, data)
          .then((res) => {
            commit('updateSuccess', res.data)
            resolve(res.data.data)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    import ({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/import`, args)
          .then((res) => {
            resolve(res)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    export ({ commit }, { installID, queries }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/chats/installs/${installID}/contacts/export`, { params: queries, responseType: 'blob' })
          .then((res) => {
            resolve(res)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    templateDownload ({ commit }, parmas) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/contacts/template-download`, { params: parmas, responseType: 'blob' })
          .then((res) => {
            resolve(res)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    delete ({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/chats/contacts/${id}`)
          .then((res) => {
            commit('deleteSuccess', id)
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    block ({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/chats/contacts/${id}/block`)
          .then((res) => {
            commit('done', id)
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    unblock ({ commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/chats/contacts/${id}/unblock`)
          .then((res) => {
            commit('done', id)
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    bulkBlock ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/bulk-block`, payload)
          .then((res) => {
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    setOnTop ({ commit }, contactId) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/${contactId}/set-on-top`)
          .then(() => {
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    bulkDelete ({ commit }, payload) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/bulk-delete`, payload)
          .then((res) => {
            commit('bulkDeleteSuccess', payload)
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    bulkOptout ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/bulk-optout`, payload)
          .then((res) => {
            resolve(true)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },

    toggleOptOut({ commit }, contactId) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/${contactId}/opt-out`)
          .then((res) => {
            commit('updateSuccess', res.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    assignAgent({ commit }, {contactId, agentId}) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/${contactId}/agent/${agentId}`)
          .then((res) => {
            commit('updateSuccess', res.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteAgent({ commit }, contactId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/contacts/${contactId}/delete-agent`)
          .then((res) => {
            commit('updateSuccess', res.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    stopAutomation({ commit }, { contactId, automationId }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/contacts/${contactId}/automation/${automationId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    startAutomation({ commit }, { contactId, automationId }) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/${contactId}/automation/${automationId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    bulkAutomation({ commit }, { automationId, param }) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/bulk-automation/${automationId}`, param)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    fetchFields ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/contacts/fields`, { params: queries })
          .then((res) => {
            resolve(res.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    activeFields ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/contacts/fields/active`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    createField ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/contacts/fields`, payload)
          .then((res) => {
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    updateField ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/contacts/fields/${payload.id}`, payload)
          .then((res) => {
            resolve(true)
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    deleteField({ commit }, contactFieldId) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/contacts/fields/${contactFieldId}`)
          .then((res) => {
            resolve(true)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    clearList ({ commit }) {
      commit('clearList')
    },
  },

  getters: {

  },
}
