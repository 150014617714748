import store from '@/store/'

function InstallWizardRouting(to, from, next)
{
  let user = store.state.auth.user
  if (user.open_install_wizard)
  {
    if ( user.open_install_wizard === true && (to.name !== 'business.installs.create' && to.name !== 'business.installs.edit'))
    {
      store.commit('setLoading', false)
      if (to.name !== 'business.installs.edit')
      {
        next({ name: 'business.installs.create' })
      } else {
        next()
      }
    } else if (user.open_install_wizard !== true && to.name !== 'business.installs.edit') 
    {
      store.commit('setLoading', false)
      next({ name: 'business.installs.edit', params: { id: user.open_install_wizard } })
    } else {
      next();
    }
  } else {
    next()
  }
}

function BusinessGuard(to, from, next){
  let user = store.state.auth.user
    
  if (!user) {
    const query = to.query;
    if (to.path) {
      query.path = to.path
    }
    next( { name: 'login', query: query })
  } else if (user.role_group != 'Business'){
    next('/')
  } else if (store.getters['auth/hasAgencyStripeEnabled'] && 
    (!user.business.free_fee) && 
    (!user.subscribed || !user.stripe_active)
   ) {
    if (to.name !== 'business.subscriptions') {
      next({ name: 'business.subscriptions' })
    } else {
      next()
    }
  } else {
    if (!user.business.is_template)
    {
      InstallWizardRouting(to, from, next)
    } else {
      next()
    }
  }
}

export default BusinessGuard