<template>
  <div
    class="form-group with-icon-right dropdown select-form-group mb-0"
    v-dropdown.isBlocked
    :class="{'has-error': hasErrors}">
    <div class="input-group dropdown-toggle">
      <input
        type="text"
        :class="{'has-value': !!value}"
        v-model="displayValue"
        :name="name"
        disabled="true"
        autocomplete="off"
      />
      <i class="ion ion-ios-arrow-down icon-right input-icon" v-show="!disabled"></i>
      <label v-if="label" class="control-label">{{label}}</label><i class="bar"></i>
      <small v-show="hasErrors" class="help text-danger">
        {{ showRequiredError() }}
      </small>
    </div>
    <div
      class="dropdown-menu" aria-labelledby="dropdownMenuButton" v-show="!disabled">
      <scrollbar ref="scrollbar">
        <div class="dropdown-menu-content">
          <div class="dropdown-item"
            :class="{'selected': isOptionSelected(option)}"
            v-for="option in options"
            @click="selectOption(option)">
            <span class="ellipsis">{{optionKey ? option[optionKey] : option}}</span>
            <span v-if="showMessage && option['messages']" class="messages">{{ option['messages'] ? option['messages'] : '' }}</span>
          </div>
        </div>
      </scrollbar>
    </div>
  </div>
</template>

<script>
  import Dropdown from 'vuestic-directives/Dropdown'
  import Scrollbar from '../vuestic-scrollbar/VuesticScrollbar.vue'

  export default {
    name: 'vuestic-simple-dropdown',
    components: {
      Scrollbar
    },
    directives: {
      dropdown: Dropdown
    },
    props: {
      label: {
        type: String,
        default: ''
      },
      options: {
        type: Array,
        required: true,
      },
      value: {
        default: '',
        required: true
      },
      optionKey: String,
      showMessage: {
        type: Boolean,
        default: false
      },
      name: {
        type: String,
        default: 'simple-dropdown'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        displayValue: '',
      }
    },
    watch: {
      value: {
        handler (value) {
          if (this.optionKey) {
            this.displayValue = value ? value[this.optionKey] : ''
          } else {
            this.displayValue = value || ''
          }
        },
        immediate: true,
      }
    },
    computed: {
      hasErrors () {
        let hasErrors = false
        hasErrors = this.required && (hasErrors || !this.value)
        return hasErrors
      },
    },
    methods: {
      isOptionSelected (option) {
        return this.value === option
      },

      selectOption (option) {
        this.$emit('input', option)
      },

      showRequiredError () {
        return `The ${this.name} field is required`
      },
    }
  }
</script>

<style lang="scss">
  .select-form-group {
    .input-group {
      input {
        &:disabled {
          cursor: pointer;
        };
      }
      .input-icon {
        top: 13px;
      }
    }
    .dropdown-menu {
      padding: 0;
      .vuestic-scrollbar {
        // height: $dropdown-item-height * 4;
        height: 100%;
      }
      .dropdown-item {
        .messages {
          background-color: $rb-blue;
          color: #fff;
          min-width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 20px;
          padding: 0 6px;
        }
      }
      .dropdown-item:hover,
      .dropdown-item:focus,
      .dropdown-item.selected {
        background-color: $rb-blue; 
        color: $white;
        .messages {
          background-color: $white; 
          color: $rb-blue;
          position: absolute;
          right: 10px;
        }
      }
    }
    .help {
      position: absolute;
    }
  }
</style>
