import lazyLoading from './lazyLoading'
import BOrderLayout from '../components/layouts/BOrderLayout'

export default [{
  path: '/',
  component: BOrderLayout,
  children: [
    {
      name: 'auth.b.order',
      path: '/b/register',
      component: lazyLoading('auth/business/Order')
    },
    {
      name: 'auth.b.order-thankyou',
      path: '/b/register-thankyou',
      component: lazyLoading('auth/business/OrderThankyou'),
    },
  ],
}]
