<template>
  <div>
    <app-pre-loader v-show="isLoading" ref="preLoader"></app-pre-loader>
    <div 
      class="layout"
      v-resize 
      :class="{
        'full-layout': !allowNavigator,
        'has-notification': hasNotification,
        'layout-footer': showFooter,
        'sidebar-expanded': sidebarConfig && sidebarConfig.submenuOpened
      }"
      v-show="showLayout"
    >
      <div
        class="notification-bar w-100"
        :class="notificationData['status']"
      >
        <div v-if="notificationRequested" :class="{'text-white': notificationData['status'] === 'bg-primary'}">
          <i class="fa fa-info-circle pr-2" aria-hidden="true"></i>We recommend enabling desktop notification. Please <a class="pl-1 text-white text-decoration-underline" href="javascript:;" @click="enableNotification">Click Here</a> to enable.
        </div>
        <div v-else v-html="notificationData['msg']" :class="{'text-white': notificationData['status'] === 'bg-primary'}" @click="handleNotificationClick"></div>
      </div>
      <app-navbar :isOpen="opened" @toggle-menu="toggleSidebar" v-show="allowNavigator"/>
      <div class="w-100 impersonate-btn-wrapper" v-show="showImpersonateButton || showLogoutButton">
        <button class="le-btn btn btn-primary impersonate-btn" v-show="showImpersonateButton" @click="stopImpersonation">
          <i class="fa fa-sign-out" aria-hidden="true"></i> Main Account
        </button>
        <button class="le-btn btn btn-primary impersonate-btn" v-show="showLogoutButton" @click="logout">
          <i class="fa fa-sign-out" aria-hidden="true"></i> LogOut
        </button>
      </div>
      <div class="container-fluid main-content-wrapper" style="position: relative;height:100%">
        <accounts-impersonation />
        <side-bar ref="accounts" :isOpen="opened" @toggle-menu="toggleSidebar"  v-show="allowNavigator"/>
        <div class="content-wrap" id="content-wrap">
          <main class="content" role="main">
            <app-breadcrumbs v-show="false"/>
            <router-view></router-view>
          </main>
          <div class="footer" v-show="showFooter">
            <ul>
              <li><span>© RingBot, LLC {{ copyRightYear }}</span></li>
              <li><span>Contact Support at <a href="mailto:support@ringbot.io?Subject=Question" target="_top">support@ringbot.io</a></span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccountsImpersonation from '../common/drawer/AccountsImpersonation'
import VuesticLayout from "../../vuestic-theme/vuestic-components/vuestic-layout/VuesticLayout";
import AppNavbar from "../common/app-navbar/AppNavbar";
import SideBar from "../common/app-sidebar/Sidebar";
import AppBreadcrumbs from "../common/app-breadcrumbs/AppBreadcrumbs";
import Layout from "vuestic-theme/vuestic-directives/Layout";
import { mapGetters } from "vuex";
import Resize from "directives/ResizeHandler";
import AppPreLoader from '../common/PreLoader'
import * as PusherPushNotifications from "@pusher/push-notifications-web";

export default {
  name: "app-layout",

  components: {
    VuesticLayout,
    AppNavbar,
    AccountsImpersonation,
    AppBreadcrumbs,
    SideBar,
    AppPreLoader
  },
  directives: {
    layout: Layout,
    resize: Resize
  },
  data() {
    return {
      opened: window.innerWidth > 700 ? true : false,
      filterText: "",
      notificationPermiossion: undefined,
    };
  },

  created() {
    this.notificationPermiossion = Notification && Notification.permission

    this.registerPushNotification();
  },

  methods: {
    handleNotificationClick(e) {
      let clickedElId = e.target.id
      if (clickedElId === 'twProfile') {
        this.$router.push({ name: 'agency.twilioProfile.index' })
      }
      if (clickedElId === 'twCampaign') {
        this.$router.push({ name: 'agency.smsRegistration' })
      }
    },
    enableNotification() {
      Notification.requestPermission().then((result) => {
        this.notificationPermiossion = result
        if (result === 'granted')
        {
          this.registerPushNotification();
        }
      });
    },

    registerPushNotification() {
      if (!this.isBusiness || !this.notificationGranted) return;

      const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
        url: `${process.env.VUE_APP_BASE_API}api/v1/pusher/beams-auth`,
        headers: {
          Authorization: "Bearer " + localStorage.getItem('access_token'),
          'Content-Type': 'application/json'
        },
      })

      const beamsClient = new PusherPushNotifications.Client({
        instanceId: process.env.VUE_APP_PUSHER_INSTANCE_ID,
      })

      let userChannel = `${process.env.VUE_APP_PUSH_NOTIFICATION_CHANNEL}user-${this.user.id}`

      beamsClient
        .start()
        .then(() => beamsClient.setUserId(userChannel, beamsTokenProvider))
        .catch(console.error)

      beamsClient
        .getUserId()
        .then((userId) => {
          if (userId !== userChannel) {
            return beamsClient.stop();
          }
        })
        .catch(console.error)
    },

    onFilterSet(filterText) {
      this.filterText = filterText;
      Vue.nextTick(() => this.$refs.vuetable.refresh());
    },
    toggleSidebar(opened) {
      this.opened = opened;
    },
    closeRight() {
      this.$refs.rightDrawer.closeDrawer();
    },
    toggleStyle() {
      this.styleIndex === 3 ? (this.styleIndex = 0) : (this.styleIndex += 1);
    },
    stopImpersonation() {
      this.$store.dispatch('auth/stopImpersonate').then(success => {
      }).catch(error => {
        console.log('Error', error);
      });
    },
    logout() {
      this.$store.dispatch('auth/logout')
        .then(() => {
          this.$router.push({name: 'login'})
        })
        .catch(() => {})
    }
  },

  watch: {
    user: function (newVal, oldVal) {
      if (newVal) {
        this.registerPushNotification()
      }
    },
  },

  computed: {
    ...mapGetters([
      "isLoading",
      'copyRightYear',
    ]),

    sidebarConfig() {
      return this.$store.getters['sidebarConfig']
    },

    user () {
      return this.$store.state.auth.user
    },

    agency () {
      return this.user && this.user.agency
    },

    business () {
      return this.user && this.user.business
    },

    agencyEmail () {
      return this.user && this.user.agency && this.user.agency.email || '';
    },

    isBusiness() {
      return this.user && this.user.role_group === 'Business';
    },

    isAgency() {
      return this.user && this.user.role_group === 'Agency';
    },

    hasMainProfile() {
      return this.isAgency && this.agency && this.agency.properties && this.agency.properties.twProfileSid
    },

    hasCampaignRegistered() {
      return this.isAgency && this.agency && this.agency.properties && this.agency.properties.twCampaignSid
    },

    notificationRequested() {
      return this.isBusiness && this.notificationPermiossion === 'default'
    },

    notificationGranted() {
      return this.notificationPermiossion === 'granted'
    },


    hasNotification () {
      if (this.isBusiness && this.$route.name != 'business.subscriptions') {
        if (this.notificationRequested)
          return true;
        if ([2,3].includes(this.user.business.widget_type) && this.user.business.number_of_messages && this.user.business.messages >= this.user.business.number_of_messages * 0.9)
        {
          return true
        }
        if ([1,3].includes(this.user.business.widget_type) && this.user.business.number_of_calls && this.user.business.calls >= this.user.business.number_of_calls * 0.9)
        {
          return true
        }
      }
      if (this.isAgency && !['agency.subscriptions', 'agency.setup'].includes(this.$route.name)) {
        if (!this.hasMainProfile || !this.hasCampaignRegistered)
          return true
      }
      return false
    },

    notificationData() {
      let data = {
        'status': undefined,
        'msg': ''
      }
      if (this.isBusiness) {
        if (this.notificationRequested)
        {
          data = {
            'status': 'bg-primary',
          }
        } else {
          if ([1,3].includes(this.user.business.widget_type))
          {
            if (this.user.business.number_of_calls && this.user.business.calls >= this.user.business.number_of_calls * .9)
            {
              if (this.user.business.calls >= this.user.business.number_of_calls )
              {
                data = {
                  'status': 'bg-danger',
                  'msg': `You have reached your monthly call limit. Contact us at <a href="mailto:${this.agencyEmail}" target="_blank">${this.agencyEmail}</a> to increase your limit.`
                }
              } else {
                data = {
                  'status': 'bg-warning',
                  'msg': `You are at 90% of your monthly call limit. Contact us at <a href="mailto:${this.agencyEmail}" target="_blank">${this.agencyEmail}</a> to increase your limit.`
                }
              }
            }
          }
          if ([2,3].includes(this.user.business.widget_type))
          {
            if (this.user.business.number_of_messages && this.user.business.messages >= this.user.business.number_of_messages * .9)
            {
              if (this.user.business.messages >= this.user.business.number_of_messages )
              {
                data = {
                  'status': 'bg-danger',
                  'msg': `You have reached your monthly text message limit. Contact us at <a href="mailto:${this.agencyEmail}" target="_blank">${this.agencyEmail}</a> to increase your limit.`
                }
              } else {
                data = {
                  'status': 'bg-warning',
                  'msg': `You are at 90% of your monthly text message limit. Contact us at <a href="mailto:${this.agencyEmail}" target="_blank">${this.agencyEmail}</a> to increase your limit.`
                }
              }
            }
          }
        }
      }
      if (this.isAgency) {
        if (!this.hasMainProfile) {
          data = {
            'status': 'bg-primary',
            'msg': `In order to proceed SMS registration, <a href="javascript:;" class="font-weight-bold text-white text-decoration-underline" id="twProfile">Click Here</a> to register twilio profile.`
          }
        } else {
          if (!this.hasCampaignRegistered) {
            data = {
              'status': 'bg-primary',
              'msg': `In order to let businesses send SMS, <a href="javascript:;" class="font-weight-bold text-white text-decoration-underline" id="twCampaign">Click Here</a> to submit SMS Registration.`
            }
          }
        }
      }
      return data
    },

    showImpersonateButton() {
      return this.$store.getters['auth/isImpersonate'] && this.$store.getters['auth/getRole'] && !this.$store.getters['auth/allowNavigator']
    },

    showLogoutButton() {
      return !this.$store.getters['auth/isImpersonate'] && this.$store.getters['auth/getRole'] && !this.$store.getters['auth/allowNavigator']
    },

    showLayout() {
      return !this.$store.getters['auth/isImpersonating'] && this.$store.getters['auth/getRole']
    }, 

    showFooter() {
      return this.$store.state.auth.user && this.$store.state.auth.user.role_group == 'Agency'
    }, 

    allowNavigator() {
      return this.$store.getters['auth/allowNavigator']
    }
  }
};
</script>
<style lang="scss">
.impersonate-btn-wrapper 
{
  position: fixed;
  top: 0;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .impersonate-btn {
    display: block;
    margin: auto;
  }
}

.layout.has-notification {
  .main-content-wrapper {
    min-height: calc(#{$widget-viewport-height} - #{$top-notification-height})!important;
  }
  .content-wrap .content {
    top: calc(#{$sidebar-top} + #{$top-notification-height});
    min-height: calc(#{$widget-viewport-height} - #{$top-notification-height})!important;
  }
}

.layout.layout-footer {
  .main-content-wrapper {
    min-height: calc(#{$widget-viewport-height});
  }

  .content-wrap .content {
    top: calc(#{$sidebar-top} + #{$footer-height});
    min-height: calc(#{$widget-viewport-height} - #{$footer-height});
  }
}
.text-decoration-underline {
  text-decoration: underline;
}
$top-nav-height: 55px;
.layout {
  position: relative;
  padding-top: $top-nav-height;
  background-color: $white;
  .notification-bar {
    position: fixed;
    top: 0;
    z-index: 1000;
    font-size: 16px;
    text-align: center;
    color: #333;
    height: $top-notification-height;
    display: none;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    &.bg-danger {
      color: #fff;
      background-color: #e34a4a;
      a {
        color: #fff;
      }
    }
    &.bg-warning {
      background-color: #ffbe0f;
    }
    a {
      color: #333;
      padding: 0 5px;
    }
  }
  &.has-notification {
    padding-top: calc(#{$top-nav-height} + #{$top-notification-height})!important;
    & .notification-bar {
      display: flex;
    }
  }
  .main-content-wrapper {
    min-height: calc(100vh - #{$sidebar-top})!important;
    padding: 0;
    margin: 0;
  }
  &.full-layout {
    .main-content-wrapper {
      // min-height: calc(100vh - 44px);
    } 
    .content-wrap .content{
      // min-height: $widget-viewport-height!important;
    }
  }
  &-fixed {
    .content-wrap {
      padding-right: $layout-padding-right;
      padding-top: $sidebar-top;

      // @include media-breakpoint-down(md) {
      //   padding: $content-mobile-wrap-fixed-layout;
      //   margin-left: 0;
      // }
    }
  }
  .content-wrap {
    margin-left: $content-wrap-ml;
    transition: margin-left 0.3s ease;
    // padding: $layout-padding $layout-padding-right $content-wrap-pb 0;
    padding: 0;
    // @include media-breakpoint-down(md) {
    //   padding: $content-mobile-wrap;
    //   margin-left: 0;
    //   .sidebar-hidden & {
    //     margin-left: 0;
    //     padding-top: $content-mobile-wrap-sb-top;
    //   }
    // }
    .content {
      min-height: $widget-viewport-height;
    }
  }
  &.sidebar-expanded .content-wrap {
    margin-left: calc( #{$content-wrap-ml} * 2 );
  }
  .made-by-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 25px;
    padding-bottom: $made-by-footer-pb;
    position: absolute;
    bottom: 0;
    height: calc(#{$layout-padding} + #{$widget-mb});
    width: 100%;
  }
  &.full-layout {
    padding-top: $top-nav-height;
    .content-wrap {
      margin-left: 0px;
      padding-right: 0px;
      padding: 0px;
      >.content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
      .subscriptionPage {
        min-width: 1024px;
        max-width: 1024px;
        width: 100%;
        // @include media-breakpoint-down(md) {
        //   min-width: auto;
        // }
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $footer-height;
    width: 100%;
    padding: 10px 30px;
    background-color: #dadada;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        border-right: 1px solid #666;
        padding-right: 20px;
        &:last-of-type {
          border-right: none;
        };
      }
    }
    span,
    a {
      color: #666;
      font-weight: 500;
    }
    a {
      margin: 0;
      padding: 0;
    }
    // @include media-breakpoint-down(md) {
    //   ul {
    //     li {
    //       display: block;
    //       border-right: none;
    //     }
    //   }
    // }
  }
}
</style>
