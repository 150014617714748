<template>
  <div id="app" class="app">
    <router-view />
    <vuestic-modal :isOpen="isOpenBadwordModal" :hideDefaultActions="true" :force="true" :closeIconShown="false">
      <span slot="title" class="text-danger font-weight-bold">Restricted Message Content</span>
      <div class="row">
        <div class="col-12">
          <p><b class="text-danger">SHAFT (sex, hate, alcohol, firearms, and tobacco)</b> content is heavily regulated in SMS marketing.<br/>
            Any messages under forbiddend use case will be filtered out from our system.</p>
          <p v-if="badwordFileteredContent" class="font-italtic badword-preview" v-html="badwordFileteredContent"></p>
          <p class="text-danger"><b>Banned Content Categories</b></p>
          <ul>
            <li>Depictions or endorsements of violence</li>
            <li>Adult or otherwise inappropriate content</li>
            <li>Profanity</li>
            <li>Hate/discriminatory speech</li>
            <li>Endorsement of illegal or illicit drugs</li>
          </ul>
          <p>You can check more information about forbiddend use cases <a class="text-primary font-weight-bold" href="https://support.twilio.com/hc/en-us/articles/360045004974-Forbidden-Message-Categories-in-the-US-and-Canada-Short-Code-Toll-Free-and-Long-Code-" target="_blank">HERE</a></p>
          <p class="text-primary font-weight-bold">More failure with restricted content may result in further action, including permanent suspension of your account.</p>
        </div>
      </div>
      <div slot="footer">
        <button class="btn btn-primary" @click="onConfirmBadword" type="button">
          <span>Got It</span>
        </button>
      </div>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: 'app',

  data() {
    return {
      scriptType: null,
    }
  },

  methods: {
    onConfirmBadword() {
      this.$store.dispatch('auth/resetBadword')
    },
    install_support_script(type) {
      if (process.env.NODE_ENV !== 'production')
        return

      if (this.scriptType !== type)
        this.destroy_support_script();
      this.scriptType = type
      let id = '73000001030';
      if (this.scriptType === 'tw') {
        id = '73000004630';
      }
      if (!window.FreshworksWidget) {
        eval(`window.fwSettings={'widget_id':${id},'locale': 'en'};!function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://widget.freshworks.com/widgets/${id}.js",e.parentNode.insertBefore(n,e)}if(e.FreshworksWidget=n=function(t,n,a){e.FreshworksWidget.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.FreshworksWidget||function(){});window.FreshworksWidget('boot')`);
      }
    },

    destroy_support_script() {
      if (window.FreshworksWidget) {
        window.FreshworksWidget('destroy')
        window.FreshworksWidget = undefined
      }
    }
  },

  computed: {
    ...mapGetters("auth", {
      isMainAgency: "isMainAgency",
    }),

    isOpenBadwordModal() {
      return this.$store.state.auth.isOpenBadwordModal
    },

    badwordFileteredContent() {
      return this.$store.state.auth.badwordFileteredContent
    },

    sitedata() {
      return this.$store.state.app.sitedata
    },

    user() {
      return this.$store.state.auth.user;
    },
  },

  watch: {
    'sitedata.favicon': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          let titleElement = document.querySelector("link[rel*='icon']") || document.createElement('link')
          titleElement.type = 'image/x-icon'
          titleElement.rel = 'icon'
          titleElement.href = newValue
          document.getElementsByTagName('head')[0].appendChild(titleElement)
        }
      }
    },

    'sitedata.title': {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          let titleElement = document.querySelector('title')
          let productPages = ["product-order", "product-thankyou"]
          if (productPages.includes(this.$route.name))
            titleElement.text = newValue
          else
            titleElement.text = newValue + ' - Start Texting Now'
        }
      }
    },
    $route(to, from) {
      this.$store.dispatch('hideSubmenu')
      if (this.user) {
        if (this.user.role_group === 'Agency') {
          let blockList = ["landing", "login", "product-order", "product-thankyou"]
          if (to && !blockList.includes(to.name)) {
            this.install_support_script('main');
          } else {
            this.destroy_support_script();
          }
        } else if (this.user.role_group === 'Business') {
          if (this.isMainAgency)
            this.install_support_script('tw');
          else
            this.destroy_support_script();
        } else {
          this.destroy_support_script();
        }
      } else {
        this.destroy_support_script();
      }
    }
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "../sass/main";

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  #app {
    position: relative;
  }
}
</style>
