<template>
  <div class="row">
    <div class="col-md-12">
      <vuestic-widget class="no-padding no-v-padding">
        <div class="vuestic-breadcrumbs">
          <router-link v-for="(item, index) in breadcrumbs" :to="{name: item.name}" :key="index" class="crumb" :class="{ disabled: item.disabled }">
            {{ item.displayName |translate }}
          </router-link>
        </div>

      </vuestic-widget>
    </div>
  </div>
</template>

<script>
// import Breadcrumbs from './Breadcrumbs'
export default {
  name: "app-breadcrumbs",

  computed: {
    currentRoute() {
      return this.$route.name;
    },
    breadcrumbs() {
       return this.$route.matched.map(r => {
          return {
            name: r.hasOwnProperty('name') ? r.name :null,
            displayName: r.meta.displayName,
            disabled: !r.hasOwnProperty('name') && his.currentRoute == r.name
          };
        });
    }
  }
};
</script>
<style lang='scss' scoped>
  .vuestic-breadcrumbs {
    height: $breadcrumbs-height;
    display: flex;
    align-items: center;

    .crumb {
      text-transform: none;
      &.disabled {
        pointer-events: none;
      }
      &:last-child::after {
        display: none;
      }

      &::after {
        padding: 0 5px;
        display: inline-block;
        content: $breadcrumbs-arrow-content;
        vertical-align: middle;
        color: $brand-primary;
        font-size: $breadcrumbs-arrow-font;
        font-family: FontAwesome;
      }
    }

    a {
      color: $text-gray;
      &:hover {
        color: $rb-blue;
      }
    }
  }
</style>
