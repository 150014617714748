import Vue from 'vue'
import axios from 'axios'

const initState = {
  didFetch: false,
  fetching: false,
  processing: false,
  data: [],
  page: 1,
  perPage: 10,
  total: 0,
  filter: {
    type: 'all',
    keyword: ''
  },
  cancelRequest: [],
}

export default {
  namespaced: true,

  state: Vue.util.extend({}, initState),

  mutations: {
    error(state) {
      state.fetching = false;
      state.processing = false;
    },

    addRequest (state, payload) {
      state.cancelRequest[payload.type] = payload.source;
    },

    cancelRequest (state, type) {
      if (state.cancelRequest[type]) {
        state.cancelRequest[type]();
      }
      state.cancelRequest[type] = null;
    },

    processingItem(state) {
      state.processing = true;
    },

    getList(state, query) {
      state.fetching = true;
      state.total = 0;
      state.page = query.page;
      state.perPage = query.per_page;
    },

    getListSuccess(state, payload) {
      const { data, meta } = payload;
      state.didFetch = true;
      state.fetching = false;
      state.data = data;
      state.total = meta.total;
    },

    updateSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = state.data.map(item => {
        return item.id === id ? payload : item
      })
      state.processing = false;
    },

    createSuccess(state, payload) {
      const { id } = payload;
      const { data } = state;
      state.data = [payload, ...state.data]
      state.processing = false;
    },

    deleteSuccess(state ,id) {
      state.data = state.data.filter(item => item.id != id)
      state.processing = false;
    },

    clearList(state) {
      state.didFetch = false;
      state.fetching = false;
      state.processing = false;
      state.data = [];
      state.page = 1;
      state.perPage = 10;
      state.total = 0;
      state.filter = {
        type: 'all',
        keyword: ''
      }
    }
  },

  actions: {
    all({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/departments/all`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    smsDepartments({ state, commit }) {
      return new Promise((resolve, reject) => {
        let requestType = 'smsDepartment'
        if (state.cancelRequest[requestType])
        {
          commit('cancelRequest', requestType)
        }
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios.get(`/v1/departments/sms-departments`, { cancelToken: source.token }).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    smsAgents({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        let requestType = 'smsAgent'
        if (state.cancelRequest[requestType])
        {
          commit('cancelRequest', requestType)
        }
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios.get(`/v1/departments/${id}/sms-agents`, { cancelToken: source.token }).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    voiceDepartments({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/departments/voice-departments`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    fetch({ state, commit }, query) {
      commit('getList', query);
      return new Promise((resolve, reject) => {
        axios.get(`/v1/departments`, { params: query }).then(res => {
          commit('getListSuccess', {
            data: res.data.data,
            meta: res.data.meta
          })
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    get({ state, commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/departments/${id}`, {}).then(res => {
          resolve(res.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    update({ state, commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.put(`/v1/departments/${arg.id}`, arg.data).then(res => {
          commit('updateSuccess', res.data.data)
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    create({ state, commit }, arg) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/departments`, arg).then(res => {
          commit('createSuccess', res.data.data)
          resolve(res.data.data)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    delete({ state, commit }, id) {
      commit('processingItem')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/departments/${id}`).then(res => {
          commit('deleteSuccess', id)
          resolve(id)
        }).catch(e => {
          commit('error')
          reject(e)
        })
      });
    },

    toggleActive({ commit }, {id, active}) {
      return new Promise((resolve, reject) => {
        const data = {
          'active': active
        }
        axios.put(`/v1/departments/${id}/toggle-active`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    clearList ({ commit }) {
      commit('clearList')
    },
  },

  getters: {
    all(state) {
      const { data } = state
      return data
    }
  }
}