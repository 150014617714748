export const defaultAgency = {
  id: null,
  name: null,
  properties: {
    incomingCallsPhoneNumber: null,
    installs: null,
    monthlyCostIsFree: false,
    monthlyCost: null,
    sendRegistration: false,
    twPrimaryProfileSid:'',
    whiteLabel: {
      company: {
        name: null,
        address: null,
        phone: null,
      },
      poweredBy: {
        enabled: false,
        url: null,
        name: null,
      },
      landingPage: true,
    },
  },
  user: {
    id: null,
    email: null,
    properties: {
      firstName: null,
      lastName: null
    }
  },
  whitelabel: {
    id: null,
    domain: null,
    type: 'domain',
  },
  twilioConfig: {
    aSID: null,
    token: null
  },
}

export const defaultBusiness = {
  id: null,
  name: "",
  properties: {
    installs: "1",
    incomingCallsPhoneNumber: '',
  },
  twilioConfig: {
    token: '',
    aSID: '',
    sSID: ''
  },
  user: {
    id: null,
    name: "",
    email: "",
    sendEmail: true,
    properties: {
      firstName: "",
      lastName: ""
    }
  },
  widget_type: 3,
  free_fee: false,
  number_of_users: 1,
  number_of_installs: 1,
  number_of_calls: 200,
  number_of_messages: 2000,
  number_of_automations: 2,
  caller_id_enabled: true,
  custom_number_enabled: true,
  form_enabled: true,
  subscription_plan_id: '',
}

export const defaultInstallData = { 
  // DONT MODIFY THIS AT RUNTIME..
  id: null,
  smsAgents: [],
  phoneAgents: [],
  notification_type: 'all',
  notification_phone: '',
  notification_email: '',
  sms_notification_type: 'all',
  sms_notification_phone: '',
  sms_notification_email: '',
  landline_number: '',
  landline_enabled: false,
  department_enabled: false,
  caller_id: false,
  caller_id_verified: false,
  call_recording: false,
  widget_type: 3,
  press_number: 1,
  twilio_sms: 0,
  twilio_voice: 1,
  departments: [],
  properties: {
    logoUrl: '',
    headerTitle: 'Welcome, contact us below',
    departmentText: 'Got a question? Select one of the departments below to ask questions.',
    gateText: 'Got a question? Request an instant call back or text here. We offer 2 quick ways to connect with us.',
    callConnectText: 'Connect Via Phone',
    smsConnectText: 'Connect Via Text',
    recordConnectText: 'Send VoiceRecord',
    introText: 'To get connected with us immediately, enter your number below and we will call or text you in 14 seconds.',
    offlineText: 'Unfortunately, we are out of the office. Schedule a call by entering your information below.',
    smsText: 'Enter your mobile number below and our team will text you shortly.',
    voiceRecordText: 'Enter your mobile number below and our team will text you shortly.',
    smsResponseTitleText: 'We received your message.',
    smsResponseText: 'One of our team representatives will be texting you back from the number below.',
    voiceResponseTitleText: 'We received your call request.',
    voiceResponseText: 'One of our team representatives will call you from the number below.',
    scheduledVoiceResponseText: 'One of our team representatives will call you at scheduled time from the number below.',
    voiceRecordResponseTitleText: 'We received your voice record.',
    voiceRecordResponseText: 'One of our team representatives will be texting you back from the number below.',
    gdprText: 'This call will be recorded for quality and training purposes.',
    gdprSmsText: 'By submitting you agree to receive text messages at the number provided. Message/data rates apply.',
    promotionText: 'I agree to receive calls and texts via automated system about promotions and I understand the consent is not a condition of purchase.',
    autoResponderText: 'Thanks for your contacting us, We will text you soon.',
    autoResponderMissedCallText: 'Hi there, sorry we weren\'t able to take your phone call in time. How can we help you?',
    autoResponderScheduledCallText: 'You scheduled a call on [time] from website [site], we will be contacting you shortly.',
    tooltipText: 'Click here for an instant callback in 14 seconds.',
    tooltipSmsText: 'Hi there, do you have a question? Text us here...',
    gdprEnabled: false,
    gdprSmsEnabled: false,
    promotionEnabled: false,
    promotionCheckRequired: false,
    autoResponderEnabled: false,
    autoResponderMissedCallEnabled: true,
    autoResponderScheduledCallEnabled: true,
    formIntegration: false,
    buttonLabel: 'Call Me Now',
    scheduleButtonLabel: 'Schedule a Call',
    smsButtonLabel: 'Send Us A Message',
    voiceRecordButtonLabel: 'Send Us A Record',
    backButtonLabel: 'Back',
    mobileNumberLabel: 'Mobile Number',
    messageLabel: 'Message',
    nameLabel: 'Name',
    emailLabel: 'Email',
    callLabel: 'Call us now',
    callScheduleLabel: 'Choose a time for us to call you',
    poweredByLabel: 'Powered By',
    callWhisperEnabled: false,
    callWhisperText: 'Your call may be monitored or recorded for quality and training purposes.',
    website: "https://",
    type: {
      id: 3,
      description: "Phone & SMS"
    },
    phone: '',
    notificationPhone: [],
    notificationSms: [],
    callingType: {
      id: 1,
      description: "Call all at once (reccomended)"
    },
    timeout: "60",
    callerId: false,
    // page 2 : Hours //
    hours: {
      mon: { openTime: "08:00AM", closeTime: "05:00PM", open: true },
      tue: { openTime: "08:00AM", closeTime: "05:00PM", open: true },
      wed: { openTime: "08:00AM", closeTime: "05:00PM", open: true },
      thu: { openTime: "08:00AM", closeTime: "05:00PM", open: true },
      fri: { openTime: "08:00AM", closeTime: "05:00PM", open: true },
      sat: { openTime: "00:00AM", closeTime: "00:00AM", open: false },
      sun: { openTime: "00:00AM", closeTime: "00:00AM", open: false }
    },
    timezone: {
      value: 'America/Los_Angeles',
      description: "(UTC-08:00) America/Los_Angeles"
    },
    buttonApp: true,
    mobileApp: true,
    showOffBizHrs: true,
    widgetPosition: 'right',
    popupCenterEnabled: false,
    widgetPrimaryColor: '#2991D1',
    widgetSecondaryColor: '#e34a4a',
    widgetTooltipEnabled: true,
    widgetAutoPopUpEnabled: true,
    widgetAutoPopUpDelay: 10,
    widgetExitIntentPopUpEnabled: true,
    widgetExitIntentPopUpOnlyOnce: false,
    widgetDimPageOnPopUpEnabled: true,
    widgetDimOverlayColor: '#888888',
    widgetNameEnabled: false,
    widgetNameRequired: true,
    widgetNameValidationEnabled: false,
    widgetEmailEnabled: false,
    widgetEmailRequired: true,
    callScheduling: true,
    callSchedulingHoursOpen: true,
    callSchedulingHoursClose: true,
    smsRequest: false,
    agents: [],
    mobileButtonEnabled: false,
    mobileButtonCallLabel: 'Call Us',
    mobileButtonSmsLabel: 'Text Us',
    mobileSmsDefaultText: 'How can we help you? (delete this content before sending)',
    widgetMarginX: 20,
    widgetMarginY: 20,
    customFields: {},
    language: 'en',
    languageList: [ {'language' : 'en', 'value' : {
      headerTitle: 'Welcome, contact us below',
      departmentText: 'Got a question? Select one of the departments below to ask questions.',
      gateText: 'Got a question? Request an instant call back or text here. We offer 2 quick ways to connect with us.',
      callConnectText: 'Connect Via Phone',
      smsConnectText: 'Connect Via Text',
      recordConnectText: 'Send VoiceRecord',
      introText: 'To get connected with us immediately, enter your number below and we will call or text you in 14 seconds.',
      offlineText: 'Unfortunately, we are out of the office. Schedule a call by entering your information below.',
      smsText: 'Enter your mobile number below and our team will text you shortly.',
      voiceRecordText: 'Enter your mobile number below and our team will text you shortly.',
      smsResponseTitleText: 'We received your message.',
      smsResponseText: 'One of our team representatives will be texting you back from the number below.',
      voiceResponseTitleText: 'We received your call request.',
      voiceResponseText: 'One of our team representatives will call you from the number below.',
      scheduledVoiceResponseText: 'One of our team representatives will call you at scheduled time from the number below.',
      voiceRecordResponseTitleText: 'We received your voice record.',
      voiceRecordResponseText: 'One of our team representatives will be texting you back from the number below.',
      gdprText: 'This call will be recorded for quality and training purposes.',
      gdprSmsText: 'By submitting you agree to receive text messages at the number provided. Message/data rates apply.',
      promotionText: 'I agree to receive promotional calls and texts via an automated system and I understand this consent is not a condition to purchase.',
      autoResponderText: 'Thanks for your contacting us, We will text you soon.',
      autoResponderMissedCallText: 'Hi there, sorry we weren\'t able to take your phone call in time. How can we help you?',
      autoResponderScheduledCallText: 'You scheduled a call on [time] from website [site], we will be contacting you shortly.',
      tooltipText: 'Click here for an instant callback in 14 seconds.',
      tooltipSmsText: 'Hi there, do you have a question? Text us here...',
      buttonLabel: 'Call Me Now',
      scheduleButtonLabel: 'Schedule a Call',
      smsButtonLabel: 'Send Us A Message',
      voiceRecordButtonLabel: 'Send Us A Record',
      backButtonLabel: 'Back',
      mobileNumberLabel: 'Mobile Number',
      messageLabel: 'Message',
      nameLabel: 'Name',
      emailLabel: 'Email',
      callLabel: 'Call us now',
      callScheduleLabel: 'Choose a time for us to call you',
      poweredByLabel: 'Powered By',
      callWhisperText: 'Your call may be monitored or recorded for quality and training purposes.'
      }}, 
      { language : 'es', value: {
      headerTitle: 'Bienvenido, contáctanos a continuación',
      departmentText: '¿Tiene alguna pregunta? Seleccione uno de los departamentos a continuación para hacer preguntas.',
      gateText: '¿Tiene alguna pregunta? Solicite una llamada instantánea o un mensaje de texto aquí. Ofrecemos 2 formas rápidas de conectarse con nosotros.',
      callConnectText: 'Conéctese por teléfono',
      smsConnectText: 'Conéctese por mensaje de texto',
      recordConnectText: 'Enviar registro de voz',
      introText: 'Para conectarse con nosotros de inmediato, ingrese su número a continuación y le llamaremos o le enviaremos un mensaje de texto en 14 segundos.',
      offlineText: 'Lamentablemente estamos fuera de la oficina. Programe una llamada ingresando su información a continuación.',
      smsText: 'Ingrese su número de teléfono móvil a continuación y nuestro equipo le enviará un mensaje de texto en breve.',
      voiceRecordText: 'Ingrese su número de teléfono móvil a continuación y nuestro equipo le enviará un mensaje de texto en breve.',
      smsResponseTitleText: 'Recibimos tu mensaje.',
      smsResponseText: 'Uno de los representantes de nuestro equipo le responderá un mensaje de texto desde el número que aparece a continuación.',
      voiceResponseTitleText: 'Recibimos su solicitud de llamada.',
      voiceResponseText: 'Uno de los representantes de nuestro equipo lo llamará desde el número que aparece a continuación.',
      scheduledVoiceResponseText: 'Uno de los representantes de nuestro equipo lo llamará a la hora programada desde el número que aparece a continuación.',
      voiceRecordResponseTitleText: 'Recibimos su registro de voz.',
      voiceRecordResponseText: 'Uno de los representantes de nuestro equipo le responderá un mensaje de texto desde el número que aparece a continuación.',
      gdprText: 'Esta llamada será grabada con fines de calidad y capacitación.',
      gdprSmsText: 'Al enviar, aceptas recibir mensajes de texto al número proporcionado. Se aplican tarifas de mensajes/datos.',
      promotionText: 'I agree to receive calls and texts via automated system about promotions and I understand the consent is not a condition of purchase.',
      autoResponderText: 'Gracias por contactarnos, le enviaremos un mensaje de texto pronto.',
      autoResponderMissedCallText: 'Hola, lamentamos no haber podido atender tu llamada a tiempo. cómo podemos ayudarte?',
      autoResponderScheduledCallText: 'Programó una llamada a las [hora] desde el sitio web [sitio], nos comunicaremos con usted en breve.',
      tooltipText: 'Haga clic aquí para recibir una devolución de llamada instantánea en 14 segundos.',
      tooltipSmsText: 'Hola, ¿tienes alguna pregunta? Envíanos un mensaje de texto aquí...',
      buttonLabel: 'Llámame ahora',
      scheduleButtonLabel: 'Programe una llamada',
      smsButtonLabel: 'Mandanos un mensaje',
      voiceRecordButtonLabel: 'Envíanos un registro',
      backButtonLabel: 'Atrás',
      mobileNumberLabel: 'Número de teléfono móvil',
      messageLabel: 'Mensaje',
      nameLabel: 'Nombre',
      emailLabel: 'Email',
      callLabel: 'Llámanos ahora',
      callScheduleLabel: 'Elige una hora para que te llamemos',
      poweredByLabel: 'Auspiciado por',
      callWhisperEnabled: false,
      callWhisperText: 'Su llamada puede ser monitoreada o grabada con fines de calidad y capacitación.'
      }}] //english, spanish
  }
}

export const installStatus = {
  ACTIVE: 'active',
  ARCHIVE: 'archived',
  DELETED: 'deleted',
}

export const contactTypes = [
  {
    label: 'All',
    id: 'all',
  },
  {
    label: 'SMS',
    id: 'sms',
  },
  {
    label: 'Voice',
    id: 'voice',
  },
  {
    label: 'CMS',
    id: 'cms',
  },
]

export const stripePlanInterval = [
  { key: 'month', label: 'Monthly' },
  { key: 'year', label: 'Yearly' }
]

export const stripePlanMetadata = [
  { key: 'Installs', label: 'Number of Installs', value: 1, visible: true, type: 'text'},
  { key: 'Users', label: 'Number of Users', value: 10, visible: true, type: 'text'},
  { key: 'Department', label: 'Department Capability', value: 'true', visible: true, type: 'text'},
  { key: 'Zapier', label: 'Zapier Available', value: 'true', visible: true, type: 'text'},
  { key: 'RemoveBranding', label: 'Remove RingBot Branding', value: 'true', visible: true, type: 'text'},
  { key: 'ZapierFree', label: 'Zapier Included', value: 'false', visible: true, type: 'text'},
]

export const agencyStripePlanMetadata = [
  { key: 'Default', label: 'Default Plan', value: 'false', visible: true, type: 'checkbox'},
  { key: 'Visible', label: 'Visible', value: 'true', visible: true, type: 'checkbox'},
  { key: 'RemoveBranding', label: 'Remove Branding', value: 'false', visible: true, type: 'checkbox'},
  { key: 'Zapier', label: 'Zapier Available', value: 'false', visible: true, type: 'checkbox'},
  { key: 'Department', label: 'Department Capability', value: 'false', visible: true, type: 'checkbox'},
  { key: 'AiIntegration', label: 'AI Integration', value: 'false', visible: true, type: 'checkbox'},
  { key: 'Users', label: 'Number of Users', value: 1, visible: true, type: 'text'},
  { key: 'Installs', label: 'Number of Installs', value: 1, visible: true, type: 'text'},
  { key: 'Automations', label: 'Number of Automations', value: 2, visible: true, type: 'text'},
  { key: 'Messages', label: 'Number of Messages', value: 200, visible: true, type: 'text'},
  { key: 'Calls', label: 'Number of Calls', value: 20, visible: true, type: 'text'},
  { key: 'Description', label: 'Description', value: '', visible: false}
]

export const formEmbeddedTypes = [
  { value: 1, label: 'Call' },
  { value: 2, label: 'Message' },
  { value: 3, label: 'Lead' }
]

export const defaultLeadFormData = { 
  id: null,
  name: "lead",
  is_allow_multi_select: false,
  steps :[
    {
        options: [
            {
                tags: []
            },
        ]
    },
  ],
  questions: [
    {
      language : 'en',
      steps: [
        {
          question: "this is question",
          options: [{"option":"option"}]
        },
      ],
    },
    {
      language : 'es',
      steps: [
        {
          question: "this is question",
          options: [{"option":"option"}]
        },
      ],
    },
  ],
  properties: 
    {
      "styling": {
        "Padding": 20,
        "MaxWidth": 500,
        "Roudness": 0,
        "BorderColor": "#CCCCCC",
        "BackgroundColor": "#FFFFFF"
      },
      "question": {
        "Align": "center",
        "Padding": 10,
        "FontSize": 16,
        "TextColor": "#0aa60a",
        "HoverTextColor": "#CCCCCC",
      },
      "languageList": [
        {
          "value": {
            "triggerButton": {
              "BackLabel": "Prev",
              "NextLabel": "Next",
            }
          },
          "language": "en"
        },
        {
          "value": {
            "triggerButton": {
              "BackLabel": "Prev",
              "NextLabel": "Next",
            }
          },
          "language": "es"
        }
      ],
      "triggerButton": {
        "Align": "center",
        "Color": "#FFFFFF",
        "Padding": 10,
        "FontSize": 16,
        "Roudness": 0,
        "BorderColor": "#1a76d2",
        "BackgroundColor": "#1a76d2",
        "HoverBorderColor": "#185491",
        "HoverBackgroundColor": "#185491"
      },
      "questionOptions": {
        "Align": "center",
        "Color": "#1a76d2",
        "Padding": 10,
        "FontSize": 16,
        "Roudness": 0,
        "BorderColor": "#1a76d2",
        "BackgroundColor": "#FFFFFF",
        "HoverBorderColor": "#185491",
        "HoverBackgroundColor": "#185491",
        "HoverTextColor": "#FFFFFF",
      }
    }

}