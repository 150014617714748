import axios from 'axios'

export default {
  namespaced: true,

  state: {
    originalForm: {
      is_allow_multi_select: false,
      questions: [
        {
          "language": "en",
          "steps": [
            {
              "question": "Here goes the question",
              "options": [{ "option": "Option1" }, { "option": "Option2" }]
            }
          ]
        },
        {
          "language": "es",
          "steps": [
            {
              "question": "this is question",
              "options": [{ "option": "option" }, { "option": "test1" }]
            }
          ]
        }
      ],
      steps: [
        {
          "options": [
            { "tags": [] },
            { "tags": [] }
          ]
        }
      ],
      properties: {
        "styling": {
          "Padding": 20,
          "MaxWidth": 500,
          "Roudness": 0,
          "BorderColor": "#CCCCCC",
          "BackgroundColor": "#FFFFFF"
        },
        "question": {
          "Align": "center",
          "Padding": 10,
          "FontSize": "21",
          "TextColor": "#1a76d2",
        },
        "languageList": [
          {
            "value": {
              "triggerButton": {
                "BackLabel": "Prev",
                "NextLabel": "Next"
              }
            },
            "language": "en"
          },
          {
            "value": {
              "triggerButton": {
                "BackLabel": "Prev",
                "NextLabel": "Next"
              }
            },
            "language": "es"
          }
        ],
        "triggerButton": {
          "Align": "center",
          "Color": "#FFFFFF",
          "Padding": 10,
          "FontSize": 16,
          "Roudness": 0,
          "BorderColor": "#1a76d2",
          "BackgroundColor": "#1a76d2",
          "HoverBorderColor": "#185491",
          "HoverBackgroundColor": "#185491"
        },
        "questionOptions": {
          "Align": "center",
          "Color": "#1a76d2",
          "Padding": 10,
          "FontSize": 16,
          "Roudness": 0,
          "BorderColor": "#1a76d2",
          "HoverTextColor": "#fff",
          "BackgroundColor": "#FFFFFF",
          "HoverBorderColor": "#1a76d2",
          "HoverBackgroundColor": "#1a76d2"
        }
      }
    }
  },

  mutations: {},

  actions: {
    createLeadForm({ commit }, data) {

      return new Promise((resolve, reject) => {
        axios.post(`/v1/leadForm`, data).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    allLeadForms({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/allLeadForms`, { params: queries }).then(res => {
          resolve(res.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    findLeadForm({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/leadForm/${id}`).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

    deleteLeadForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/leadForm/${data.id}`, data).then(res => {
          resolve(true)
        }).catch(e => {
          reject(e)
        })
      });
    },
    toggleActiveLeadForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/leadForm/${data.id}/toggle-active`, data).then(res => {
          resolve(true)
        }).catch(e => {
          reject(e)
        })
      });
    },
    updateLeadForm({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/leadForm/${data.id}`, data).then(res => {
          resolve(res.data.data)
        }).catch(e => {
          reject(e)
        })
      });
    },

  },
  getters: {
    originalForm: state => state.originalForm,
  },
}
