import lazyLoading from './lazyLoading'
import AppLayout from '../components/layouts/AppLayout'
import BusinessGuard from './guards/BusinessGuard'

const EmptyParentComponent = {
  template: '<router-view></router-view>'
};

let routes = [
  {
    path: '/business/',
    component: AppLayout,
    redirect: { name: 'messaging' },
    meta: {
      displayName: 'menu.home'
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: lazyLoading('business/Analytic/Index'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.dashboard',
          displayName: 'menu.dashboard',
          iconClass: 'fa fa-bar-chart'
        }
      },
      {
        path: 'blacklist',
        name: 'business.blacklist',
        component: lazyLoading('business/Blacklist/Index'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.blacklist',
          displayName: 'menu.blacklist',
          iconClass: 'fa fa-ban'
        }
      },
      {
        path: 'whitelist',
        name: 'business.whitelist',
        component: lazyLoading('business/Whitelist/Index'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.whitelist',
          displayName: 'menu.whitelist',
          iconClass: 'fa fa-check-square-o'
        }
      },
      {
        path: 'messaging/:installId?',
        name: 'messaging',
        component: lazyLoading('business/Inbox/Index'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.messaging',
          displayName: 'menu.messaging',
          iconClass: 'entypo entypo-address-book-o'
        }
      },
      {
        path: 'contacts',
        name: 'business.contacts',
        component: EmptyParentComponent,
        redirect: {name: 'business.contacts.index'},
        children: [
          {
            path: '',
            name: 'business.contacts.index',
            component: lazyLoading('business/Contact/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.contacts.create',
            component: lazyLoading('business/Contact/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id',
            name: 'business.contacts.edit',
            component: lazyLoading('business/Contact/Edit'),
            beforeEnter: BusinessGuard,
          }
        ]
      },
      {
        path: 'tasks',
        name: 'business.tasks',
        component: EmptyParentComponent,
        redirect: {name: 'business.tasks.index'},
        children: [
          {
            path: '',
            name: 'business.tasks.index',
            component: lazyLoading('business/Task/Index'),
            beforeEnter: BusinessGuard,
          },
        ]
      },
      {
        path: 'calls',
        name: 'calls',
        component: lazyLoading('business/Call/Calls'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.calls',
          displayName: 'menu.calls',
          iconClass: 'entypo entypo-mobile'
        }
      },
      {
        path: 'schedule/calls',
        name: 'callSchedule',
        component: lazyLoading('business/Call/CallSchedule'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.scheduledCalls',
          displayName: 'menu.scheduledCalls',
          iconClass: 'fa fa-calendar'
        }
      },
      {
        path: 'voice-records',
        name: 'voiceRecords',
        component: lazyLoading('business/Call/VoiceRecords'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.voiceRecords',
          displayName: 'menu.voiceRecords',
          iconClass: 'fa fa-microphone'
        }
      },
      {
        path: 'installs',
        name: 'business.installs',
        component: EmptyParentComponent,
        redirect: {name: 'business.installs.active'},
        children: [
          {
            path: '',
            name: 'business.installs.active',
            component: lazyLoading('business/Installs/Active'),
            beforeEnter: BusinessGuard,
            meta: {
              displayName: 'menu.installs.active',
              sidebarName: 'menu.installs.active',
            }
          },
          {
            path: 'archived',
            name: 'business.installs.archived',
            component: lazyLoading('business/Installs/Archived'),
            beforeEnter: BusinessGuard,
            meta: {
              displayName: 'menu.installs.archived',
              sidebarName: 'menu.installs.archived',
              iconClass: 'fa fa-folder',
            }
          },
          {
            path: 'deleted',
            name: 'business.installs.deleted',
            component: lazyLoading('business/Installs/Deleted'),
            beforeEnter: BusinessGuard,
            meta: {
              displayName: 'menu.installs.deleted',
              sidebarName: 'menu.installs.deleted',
              // routerLinkPath: '/installs/deleted',
            }
          },
          {
            path: 'add',
            name: 'business.installs.create',
            component: lazyLoading('business/Installs/Add'),
            beforeEnter: BusinessGuard,
            meta: {
              displayName: 'menu.add',
            },
          },
          {
            path: ':id',
            name: 'business.installs.edit',
            component: lazyLoading('business/Install'),
            beforeEnter: BusinessGuard,
            meta: {
              displayName: 'menu.edit',
            }
          },
        ]
      },
      {
        path: 'setting',
        component: EmptyParentComponent,
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.settings',
          displayName: 'menu.settings'
        },
        children: [
          {
            path: '',
            name:  'business.setting',
            component: lazyLoading('business/Settings/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'location',
            name:  'business.location',
            redirect: {name: 'business.location.create'},
            beforeEnter: BusinessGuard,
          },
          {
            path: 'location/create',
            name:  'business.location.create',
            component: lazyLoading('business/Settings/GoogleLocation/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'location/:id',
            name:  'business.location.edit',
            component: lazyLoading('business/Settings/GoogleLocation/Edit'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'smtp',
            name:  'business.smtp',
            redirect: {name: 'business.smtp.create'},
            beforeEnter: BusinessGuard,
          },
          {
            path: 'smtp/create',
            name: 'business.smtp.create',
            component: lazyLoading('business/Settings/Smtp/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'smtp/:id',
            name: 'business.smtp.edit',
            component: lazyLoading('business/Settings/Smtp/Edit'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'integration/create',
            name: 'business.integration.create',
            component: lazyLoading('business/Settings/Integration/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'integration/edit',
            name: 'business.integration.edit',
            component: lazyLoading('business/Settings/Integration/Edit'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'number/:id',
            name: 'business.number.edit',
            component: lazyLoading('business/Settings/Numbers/Edit'),
            beforeEnter: BusinessGuard,
          },
        ]
      },
      {
        path: 'calls-report',
        name: 'business.callsReport',
        component: lazyLoading('business/CallsReport'),
        beforeEnter: BusinessGuard,
        meta: {
          sidebarName: 'menu.callsReport',
          displayName: 'menu.callsReport'
        },
      },
      {
        name: 'business.profile',
        path: 'profile',
        component: lazyLoading('auth/Profile'),
        beforeEnter: BusinessGuard,
      },
      {
        path: 'subscriptions',
        name: 'business.subscriptions',
        meta: {
          displayName: 'menu.subscriptions',
          sidebarName: 'menu.subscriptions',
          iconClass: 'fa fa-list-alt'
        },
        component: lazyLoading('business/Subscription'),
        beforeEnter: BusinessGuard,
        children: []
      },
      {
        path: 'packages',
        name: 'business.packages',
        meta: {
          displayName: 'menu.businessPackages.root',
          sidebarName: 'menu.businessPackages.root',
          iconClass: 'fa fa-suitcase'
        },
        component: lazyLoading('business/Package/Index'),
        beforeEnter: BusinessGuard,
        children: []
      },
      {
        path: 'payment-methods',
        name:  'business.paymentMethods',
        component: lazyLoading('business/PaymentMethods'),
        beforeEnter: BusinessGuard,
      },
      // {
      //   path: 'sms-registration',
      //   name:  'business.smsRegistration',
      //   component: lazyLoading('business/SmsRegistration/Index'),
      //   beforeEnter: BusinessGuard,
      // },
      {
        path: 'tags',
        name:  'business.tags',
        component: lazyLoading('business/Tags/Index'),
        beforeEnter: BusinessGuard,
      },
      {
        path: 'users',
        name:  'business.users',
        component: EmptyParentComponent,
        redirect: {name: 'business.users.index'},
        beforeEnter: BusinessGuard,
        children: [
          {
            path: '',
            name: 'business.users.index',
            component: lazyLoading('business/Users/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.users.create',
            component: lazyLoading('business/Users/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id',
            name: 'business.users.edit',
            component: lazyLoading('business/Users/Edit'),
            beforeEnter: BusinessGuard,
          }
        ]
      },
      {
        path: 'departments',
        name:  'business.departments',
        component: EmptyParentComponent,
        redirect: {name: 'business.departments.index'},
        beforeEnter: BusinessGuard,
        children: [
          {
            path: '',
            name: 'business.departments.index',
            component: lazyLoading('business/Departments/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.departments.create',
            component: lazyLoading('business/Departments/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id',
            name: 'business.departments.edit',
            component: lazyLoading('business/Departments/Edit'),
            beforeEnter: BusinessGuard,
          }
        ]
      },
      {
        path: 'automation',
        name:  'business.automation',
        component: EmptyParentComponent,
        redirect: {name: 'business.automation.index'},
        beforeEnter: BusinessGuard,
        children: [
          {
            path: '',
            name: 'business.automation.index',
            component: lazyLoading('business/Automation/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.automation.create',
            component: lazyLoading('business/Automation/Automation'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id',
            name: 'business.automation.edit',
            component: lazyLoading('business/Automation/Automation'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':token/share',
            name: 'business.automation.share',
            component: lazyLoading('business/Automation/Share'),
            beforeEnter: BusinessGuard,
          }
        ]
      },
      {
        path: 'leadForms',
        name:  'business.lead-forms',
        component: EmptyParentComponent,
        redirect: { name: 'business.lead-forms.index' },
        beforeEnter: BusinessGuard,
        children: [
          {
            path: '',
            name: 'business.lead-forms.index',
            component: lazyLoading('business/LeadForms/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.lead-forms.create',
            component: lazyLoading('business/LeadForms/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id/edit',
            name: 'business.lead-forms.update',
            component: lazyLoading('business/LeadForms/Edit'),
            beforeEnter: BusinessGuard,
          },
        ]
      },
      {
        path: 'broadcast',
        name: 'business.broadcast',
        component: EmptyParentComponent,
        redirect: {name: 'business.broadcast.index'},
        children: [
          {
            path: '',
            name: 'business.broadcast.index',
            component: lazyLoading('business/Broadcast/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.broadcast.create',
            component: lazyLoading('business/Broadcast/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id',
            name: 'business.broadcast.edit',
            component: lazyLoading('business/Broadcast/Edit'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id/duplicate',
            name: 'business.broadcast.duplicate',
            component: lazyLoading('business/Broadcast/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id/report',
            name: 'business.broadcast.report',
            component: lazyLoading('business/Broadcast/Report'),
            beforeEnter: BusinessGuard,
          },
        ]
      },
      {
        path: 'templates',
        name:  'business.templates',
        component: EmptyParentComponent,
        redirect: {name: 'business.templates.index'},
        beforeEnter: BusinessGuard,
        children: [
          {
            path: '',
            name: 'business.templates.index',
            component: lazyLoading('business/Templates/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.templates.create',
            component: lazyLoading('business/Templates/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id',
            name: 'business.templates.edit',
            component: lazyLoading('business/Templates/Edit'),
            beforeEnter: BusinessGuard,
          }
        ]
      },
      {
        path: 'installs/:install/forms',
        name:  'business.embedded-forms',
        component: EmptyParentComponent,
        redirect: { name: 'business.embedded-forms.index' },
        beforeEnter: BusinessGuard,
        children: [
          {
            path: '',
            name: 'business.embedded-forms.index',
            component: lazyLoading('business/EmbeddedForms/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: 'create',
            name: 'business.embedded-forms.create',
            component: lazyLoading('business/EmbeddedForms/Create'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id/edit',
            name: 'business.embedded-forms.update',
            component: lazyLoading('business/EmbeddedForms/Edit'),
            beforeEnter: BusinessGuard,
          },
        ]
      },
      {
        path: 'numbers',
        name:  'business.numbers',
        component: EmptyParentComponent,
        redirect: {name: 'business.numbers.index'},
        beforeEnter: BusinessGuard,
        children: [
          {
            path: '',
            name: 'business.numbers.index',
            component: lazyLoading('business/Numbers/Index'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id',
            name: 'business.numbers.edit',
            component: lazyLoading('business/Numbers/Edit'),
            beforeEnter: BusinessGuard,
          },
          {
            path: ':id/verification',
            name: 'business.numbers.verification',
            component: lazyLoading('business/Numbers/Verification/Index'),
            beforeEnter: BusinessGuard,
          },
        ]
      },
    ]
  }
];

export default routes
