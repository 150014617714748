import Vue from 'vue'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

Vue.filter('phone', function (val, type = 'national') {
  if (!val)
    return '';
  if (!val.startsWith('+'))
    val = '+' + val;

  const phoneNumber = parsePhoneNumberFromString(val)
  if (phoneNumber) {
    if (type === 'national')
      return phoneNumber.formatNational()
    else
      return phoneNumber.formatInternational()
  }
  return val
})