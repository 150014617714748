import lazyLoading from './lazyLoading'
import AppLayout from '../components/layouts/AppLayout'
import AgencyGuard from './guards/AgencyGuard'

const EmptyParentComponent = {
  template: '<router-view></router-view>'
}


let routes = [
  {
    path: '/agency/',
    component: AppLayout,
    redirect: { name: 'businessAccountsActive' },
    meta: {
      displayName: 'menu.home'
    },
    children: [
      {
        path: 'dashboard',
        name: 'agency.dashboard',
        component: lazyLoading('agency/Dashboard/Index'),
        beforeEnter: AgencyGuard,
        meta: {
          sidebarName: 'menu.dashboard',
          displayName: 'menu.dashboard',
          iconClass: 'fa fa-bar-chart'
        }
      },
      {
        path: 'landing-page',
        name: 'agency.landingPage',
        component: lazyLoading('agency/LandingPage'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'business/accounts',
        name: 'businessAccounts',
        component: EmptyParentComponent,
        redirect: {name: 'businessAccountsActive'},
        meta: {
          displayName: 'menu.businessAccounts.root',
          sidebarName: 'menu.businessAccounts.root',
          iconClass: 'fa fa-user',
          onExpand: {
            route: {name: 'businessAccountsActive'}
          }
        },
        children: [
          {
            path: 'active',
            name: 'businessAccountsActive',
            component: lazyLoading('business/Accounts/Active'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'menu.businessAccounts.active',
              sidebarName: 'menu.businessAccounts.active',
            }
          },
          {
            path: 'archived',
            name: 'businessAccountsArchived',
            component: lazyLoading('business/Accounts/Archived'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'menu.businessAccounts.archived',
              sidebarName: 'menu.businessAccounts.archived',
              iconClass: 'fa fa-folder',
            }
          },
          {
            path: 'deleted',
            name: 'businessAccountsDeleted',
            component: lazyLoading('business/Accounts/Deleted'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'menu.businessAccounts.deleted',
              sidebarName: 'menu.businessAccounts.deleted',
            },
          },
          {
            path: "add",
            name: "businessAccountAdd",
            component: lazyLoading('business/Account'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'menu.add',
            },
          },
          {
            path: 'edit/:id',
            name: 'businessAccountEdit',
            component: lazyLoading('business/Account'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'menu.edit'
            },
          },
        ],
      },
      {
        path: 'popup',
        name: 'agency.popup',
        component: lazyLoading('agency/Popup'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'subscriptions',
        name: 'agency.subscriptions',
        meta: {
          displayName: 'menu.subscriptions',
          sidebarName: 'menu.subscriptions',
          iconClass: 'fa fa-list-alt'
        },
        component: lazyLoading('agency/Subscription'),
        beforeEnter: AgencyGuard,
        children: []
      },
      {
        path: "setup",
        name:  "agency.setup",
        meta: {
          displayName: 'menu.setup',
        },
        component: lazyLoading('agency/Setup'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'payment-methods',
        name:  'agency.paymentMethods',
        component: lazyLoading('agency/PaymentMethods'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'settings',
        name: 'agency.settings',
        meta: {
          displayName: 'menu.settings',
          sidebarName: 'menu.settings',
          iconClass: 'fa fa-cogs'
        },
        component: lazyLoading('agency/Settings/Index'),
        beforeEnter: AgencyGuard,
        children: []
      },
      {
        path: 'integrations',
        name: 'agency.integrations',
        meta: {
          displayName: 'menu.integrations',
          sidebarName: 'menu.integrations',
          iconClass: 'fa fa-wrench'
        },
        component: lazyLoading('agency/Integrations/Index'),
        beforeEnter: AgencyGuard,
        children: []
      },
      {
        path: 'twilioProfile',
        name: 'agency.twilioProfile',
        component: EmptyParentComponent,
        redirect: {name: 'agency.twilioProfile.index'},
        meta: {
          displayName: 'menu.plans.root',
          sidebarName: 'menu.plans.root',
          iconClass: 'fa fa-dollar-sign',
          onExpand: {
            route: {name: 'agency.twilioProfile.index'}
          }
        },
        children: [
          {
            path: '',
            name: 'agency.twilioProfile.index',
            component: lazyLoading('agency/TwilioProfile/Index'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.twilioProfile.index',
              sidebarName: 'agency.twilioProfile.index',
            }
          },
          {
            path: "create",
            name: "agency.twilioProfile.create",
            component: lazyLoading('agency/TwilioProfile/Detail'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.twilioProfile.create',
            },
          },
          {
            path: "edit/:id",
            name: "agency.twilioProfile.edit",
            component: lazyLoading('agency/TwilioProfile/Detail'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.twilioProfile.edit',
            },
          },
        ]
      },
      {
        path: 'sms-registration',
        name: 'agency.smsRegistration',
        component: lazyLoading('agency/SmsRegistration/Index'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'international',
        name: 'agency.international',
        component: lazyLoading('agency/International'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'zapier',
        name: 'agency.zapier',
        component: lazyLoading('agency/Zapier'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'training',
        name: 'agency.training',
        component: lazyLoading('agency/Training'),
        beforeEnter: AgencyGuard,
      },
      {
        name: 'agency.profile',
        path: 'profile',
        component: lazyLoading('auth/Profile'),
        beforeEnter: AgencyGuard,
      },
      {
        path: 'plans',
        name: 'agency.plans',
        component: EmptyParentComponent,
        redirect: {name: 'agency.plans.index'},
        meta: {
          displayName: 'menu.plans.root',
          sidebarName: 'menu.plans.root',
          iconClass: 'fa fa-dollar-sign',
          onExpand: {
            route: {name: 'agency.plans.index'}
          }
        },
        children: [
          {
            path: '',
            name: 'agency.plans.index',
            component: lazyLoading('agency/plans/Index'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.plans.index',
              sidebarName: 'agency.plans.index',
            }
          },
          {
            path: "add",
            name: "agency.plans.create",
            component: lazyLoading('agency/plans/Create'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.plans.create',
            },
          },
          {
            path: "edit/:id",
            name: "agency.plans.edit",
            component: lazyLoading('agency/plans/Edit'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.plans.edit',
            },
          },
        ]
      },
      {
        path: 'packages',
        name: 'agency.packages',
        component: EmptyParentComponent,
        redirect: {name: 'agency.packages.index'},
        meta: {
          displayName: 'agency.packages.root',
          sidebarName: 'agency.packages.root',
          iconClass: 'fa fa-suitcase',
          onExpand: {
            route: {name: 'agency.packages.index'}
          }
        },
        children: [
          {
            path: '',
            name: 'agency.packages.index',
            component: lazyLoading('agency/packages/Index'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.packages.index',
              sidebarName: 'agency.packages.index',
            }
          },
          {
            path: "add",
            name: "agency.packages.create",
            component: lazyLoading('agency/packages/Create'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.packages.create',
            },
          },
          {
            path: "edit/:id",
            name: "agency.packages.edit",
            component: lazyLoading('agency/packages/Edit'),
            beforeEnter: AgencyGuard,
            meta: {
              displayName: 'agency.packages.edit',
            },
          },
        ]
      },
      {
        path: 'users',
        name:  'agency.users',
        component: EmptyParentComponent,
        redirect: {name: 'agency.users.index'},
        beforeEnter: AgencyGuard,
        children: [
          {
            path: '',
            name: 'agency.users.index',
            component: lazyLoading('agency/Users/Index'),
            beforeEnter: AgencyGuard,
          },
          {
            path: 'create',
            name: 'agency.users.create',
            component: lazyLoading('agency/Users/Create'),
            beforeEnter: AgencyGuard,
          },
          {
            path: ':id',
            name: 'agency.users.edit',
            component: lazyLoading('agency/Users/Edit'),
            beforeEnter: AgencyGuard,
          }
        ]
      },
    ]
  }
]

export default routes
