import axios from 'axios'

export default {
  archiveBusiness(id) {
    let url = `/v1/business/${id}/archive`;
    let method = "put";

    return App.$http.request({url,method});
  },
  restoreBusiness(id) {
    let url = `/v1/business/${id}/restore`;
    let method = "put";

    return App.$http.request({url,method});
  },
  deleteBusiness(id) {
    let url = `/v1/business/${id}`;
    let method = "delete";

    return App.$http.request({url,method});
  },
 
  businessCanAddInstall() {
    let url = `/v1/business/can-create-install`;
    let method = "post";

    return App.$http.request({url,method});
  },

  restoreInstall(id) {
    let url = `/v1/installs/${id}/restore`;
    let method = "put";

    return App.$http.request({url,method});
  },
}
