import axios from 'axios'
 
const initPlanData = {
  didFetch: false,
  fetching: false,
  processing: false,
  data: [],
}

export default {
  namespaced: true,
  state: {
    loading: false,
    plans: JSON.parse(JSON.stringify(initPlanData))
  },
  mutations: {
    loading (state) {
      state.loading = true
    },

    done (state) {
      state.loading = false
    },

    updateSettingsSuccess (state, agency) {
      this.state.auth.user.agency = agency
    },

    setupTwilioConfigSuccess (state, agency) {
      this.state.auth.user.agency = agency
      this.state.auth.user.has_twilio_setup = true
    },

    updateStripeSettingSuccess (state, agency) {
      this.state.auth.user.agency = agency
    },

    updatePopupPaymentSuccess (state, agency) {
      this.state.auth.user.agency = agency
    },

    getPlans(state) {
      state.plans = {
        ...state.plans,
        fetching: true,
      }
    },

    getPlansSuccess(state, payload) {
      state.plans = {
        ...state.plans,
        data: payload.data,
        didFetch: true,
        fetching: false,
      }
    },

    processingPlan(state) {
      state.plans = {
        ...state.plans,
        processing: true,
      }
    },

    createPlanSuccess(state, payload) {
      const { data } = state.plans
      const newData = [...data, payload.data]
      state.plans = {
        ...state.plans,
        data: newData,
        processing: false,
      }
    }
  },
  actions: {
    createAgency ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.post(`/v1/agencies`, data)
          .then((res) => {
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    getAgency ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.get(`/v1/agencies/${id}`)
          .then((res) => {
            commit('done')
            resolve(res.data)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    getAgencies ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.get(`/v1/agencies`, {params: queries})
          .then((res) => {
            commit('done')
            resolve(res.data)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    updateAgency ({ commit }, args) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.put(`/v1/agencies/${args.id}`, args)
          .then((res) => {
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    }, 

    archiveAgency ({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.delete(`/v1/agencies/${id}`)
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    bulkArchiveAgencies ({ commit }, agencyIds) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.delete(`/v1/agencies/bulk-archive`, {
          data: { ids: agencyIds }
        })
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    bulkRestoreAgencies ({ commit }, agencyIds) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.post(`/v1/agencies/bulk-restore`, {
          data: { ids: agencyIds }
        })
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    restoreAgency ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/${id}/restore`)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteAgencies ({ commit }, agencyIds) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.delete(`/v1/agencies/bulk`, {
          data: { ids: agencyIds }
        })
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    updateSettingsNew ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.put(`/v1/agencies/settings`, data)
          .then((res) => {
            commit('updateSettingsSuccess', res.data.data)
            commit('done')

            resolve(res.data.data)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    updateSettings ({ commit }, args) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.put(`/v1/agencies/${args.id}/settings`, args.data)
          .then((res) => {
            commit('updateSettingsSuccess', res.data.data)
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    updateStripeSetting ({ commit }, args) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.put(`/v1/agencies/${args.id}/stripe-setting`, args.data)
          .then((res) => {
            commit('updateStripeSettingSuccess', res.data.data)
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    updatePopupPayment ({ commit }, args) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.put(`/v1/agencies/${args.id}/popup-payment`, args.data)
          .then((res) => {
            commit('updatePopupPaymentSuccess', res.data.data)
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    getPopupScript ({ commit }, data) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.post(`/v1/agencies/popup-script`, data)
          .then((res) => {
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    addOptinUser ({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/optin-users`, user)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    addModalUser ({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/modal-users`, user)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    chargeModalUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/modal-users/charge`, data)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getModalUsers ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/modal-users`, { params: queries })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getOptinUsers ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/optin-users`, { params: queries })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateTwilioConfig ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agencies/${data.business_id}/twilio-config`, data)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    getTwilioStatus ({ commit }, agencyId) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/${agencyId}/twilio-status`)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => reject(error))
      })
    },

    setupTwilioConfig ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agencies/twilio-config`, data)
          .then(res => {
            commit('setupTwilioConfigSuccess', res.data.data)
            resolve(res)
          })
          .catch(error => reject(error))
      })
    },

    updateCustomScript ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agencies/custom-script`, data)
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    changePassword ({ commit }, {id, params}) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agencies/${id}/change-password`, params)
          .then(res => resolve(res))
          .catch(error => resolve(error))
      })
    },

    allPlans({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/${queries.agency_id}/subscription/plans/all`)
          .then(res => {
            resolve(res)
          })
          .catch(error => resolve(error))
      })
    },

    getPlans({ commit }, queries) {
      commit('getPlans')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/${queries.agency_id}/subscription/plans`, { params: queries })
          .then(res => {
            commit('getPlansSuccess', res.data)
            resolve(res)
          })
          .catch(error => resolve(error))
      })
    },

    hasDefaultPlan({ commit }, agency_id) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/${agency_id}/subscription/has-default`)
          .then(res => {
            resolve(res)
          })
          .catch(error => resolve(error))
      })
    },

    createPlan({ commit }, { agency_id, data }) {
      commit('processingPlan')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/${agency_id}/subscription/plans`, data)
          .then(res => {
            commit('createPlanSuccess', res.data)
            resolve(res)
          })
          .catch(error => resolve(error))
      })
    },

    getPlan({ commit }, { agency_id, plan_id }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/${agency_id}/subscription/plans/${plan_id}`)
          .then(res => resolve(res))
          .catch(error => resolve(error))
      })
    },

    updatePlan({ commit }, { agency_id, plan_id, data }) {
      return new Promise((resolve, reject) => {
        axios.put(`/v1/agencies/${agency_id}/subscription/plans/${plan_id}`, data)
          .then(res => resolve(res))
          .catch(error => resolve(error))
      })
    },

    deletePlan({ commit }, { agency_id, plan_id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/agencies/${agency_id}/subscription/plans/${plan_id}`)
          .then(res => resolve(res))
          .catch(error => resolve(error))
      })
    },

    fetchAllBusinesses({ commit }, query) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agency/business/all`, { params: query })
          .then(res => {
            resolve(res.data)
          })
          .catch(error => resolve(error))
      })
    },

    fetchAnalytic ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agency/business/analytics`, data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    exportUsers ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/export-users`, { params: queries, responseType: 'blob' })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    exportBusinesses ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/export-businesses`, { params: queries, responseType: 'blob' })
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sendNotification ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/send-notification`, params)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getIntegrations ({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/agencies/integartions`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateIntegration ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/integartions`, params)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteIntegration ({ commit }, type) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/agencies/integartions/${type}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateImage ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/agencies/image/${params.id}`, params.data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    deleteImage ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/agencies/image/${params.id}/${params.type}`)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },

  getters: {
    plans: state => state.plans.data,
    planDidFetch: state => state.plans.didFetch,
  },
}
