<template>
  <div class="order-page">
    <div class="container--full-width container-xl pl-0 pr-0">
      <div class="m-4">
        <div class="mt-4 mb-3">
          <router-link :to="{path: '/'}" class="float-left">
            <img class="logo" src="logo-header-white.webp" width="200" alt="logo"/>
          </router-link>
          <div class="h5 float-right text-white-50 mt-4 mb-md-2 mt-md-2 text-center" v-bind:class="{ active: isActive }">
            Need help? Text us at 
            <a class="color-white" href="sms:+18582938500">858-293-8500</a>
          </div>
          <div class="clearfix"></div>
        </div>
        <router-view></router-view>
        <div class="mt-5 text-white text-center footer">
          <span>Copyright {{copyRightYear}} All Rights Reserved</span>
          <a href="https://www.callwidget.co/terms-of-service" target="_blank">Terms of Service</a>
          <a href="https://www.callwidget.co/privacy-policy" target="_blank">Privacy Policy</a>
        </div>
        <div class="mt-2 mb-4 text-white text-center footer">
          <span>11111 N Scottsdale Road, Suite 205V, Scottsdale, AZ 85254</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  created () {
    this.$store.dispatch('getSiteData', window.location.host)
      .then(({ data }) => {
        if (!data.data.isMainDomain) {
          this.$router.push({ name: 'landing' });
        }
      })
  },

  data () {
    return {
      isActive: false,
    }
  },

  computed: {
    sitedata () {
      return this.$store.state.app.sitedata
    },
    copyRightYear () {
      return this.$store.getters["copyRightYear"]
    }
  },
}
</script>

<style lang="scss">
.order-page {
  background: #000;
  color: #333;
  min-height: 100vh;
}
a.color-white {
  color: #fff;
  &:focus,
  &:hover {
    color: #ddd;
  }
}
@media (max-width: 767px) {
  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}
.footer {
  span, a {
    color: #fff;
    font-size: 16px;
    display: inline-block;
    margin-left: 20px;
  }
  a:hover {
    text-decoration: underline;
  };
}
</style>
