import axios from 'axios'

export default {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    all ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/blacklist`, { params: queries })
          .then(res => {
            resolve(res.data)
          })
          .catch(e => {
            reject(e)
          })
      })
    },

    create ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/blacklist`, params)
          .then(() => {
            resolve(true)
          }).catch(e => {
            reject(e)
          })
      })
    },

    delete ({ commit }, id ) {
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/blacklist/${id}`)
          .then(() => {
            resolve(true)
          })
          .catch((e) => {
            reject(e)
          })
      })
    },

    import ({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/blacklist/import`, args)
          .then((res) => {
            resolve(res)
          })
          .catch(error => {
            commit('error')
            reject(error)
          })
      })
    },
  },
}
