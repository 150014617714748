import {Pie, mixins} from 'vue-chartjs'
import DefaultOptions from '../DefaultOptions'
import Vue from 'vue'

export default Vue.component('pie-chart', {
  extends: Pie,
// Pie.extend({
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],

  data() {
    return {
      defaultOptions: {}
    }
  },

  mounted() {
    let options = Object.assign({}, DefaultOptions, this.defaultOptions, this.options)
    this.renderChart(this.chartData, options)
  }
})
