<template>
  <div class="container fullContainer noTopMargin padding20-top padding20-bottom padding40H noBorder borderSolid border3px cornersAll radius0 shadow0 bgNoRepeat emptySection" id="section--28325" data-title="OptIn" data-block-color="0074C7" style="padding-top: 50px; padding-bottom: 50px; outline: none;" data-trigger="none" data-animate="fade" data-delay="500">

    <div ref="modal" class="modal fade hide" tabindex="-1" role="dialog" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">

          <a class="close-modal" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </a>

          <div class="row bgCover borderSolid cornersAll radius0 P0-top P0-bottom P0H noTopMargin border1px noBorder shadow30" id="row--68043" data-trigger="none" data-animate="fade" data-delay="500" data-title="2 column row" style="padding: 0px 10px 15px; margin: 0px; outline: none;" col-array="6,6">
            <div id="col-left-131" class="innerContent col_left ui-resizable col-md-6" data-col="left" data-trigger="none" data-animate="fade" data-delay="500" data-title="1st column" style="outline: none;">
              <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin" style="padding: 0 10px">
                <div class="de elHeadlineWrapper ui-droppable de-editable" id="tmp_headline1-11985" data-de-type="headline" data-de-editing="false" data-title="headline" data-ce="true" data-trigger="none" data-animate="fade" data-delay="500" style="margin-top: 15px; outline: none; cursor: pointer; font-family: Montserrat, Helvetica, sans-serif !important;" data-google-font="Montserrat" aria-disabled="false">
                  <div class="ne elHeadline hsSize3 lh4 elMargin0 elBGStyle0 hsTextShadow0" style="text-align: center; font-size: 16px; color: rgb(59, 89, 153);" data-bold="inherit" data-gramm="false" contenteditable="false">
                    <b>Please enter the details of the person&nbsp;</b>
                    <div><b>responsible within your company.</b></div>
                  </div>
                </div>
                <form @submit.prevent="submit">
                  <div class="de elInputWrapper de-input-block elAlign_center elMargin0 ui-droppable de-editable" id="tmp_input-71125" data-de-type="input" data-de-editing="false" data-title="input" data-ce="false" data-trigger="none" data-animate="fade" data-delay="500" type="email" style="margin-top: 25px; outline: none; cursor: pointer;" aria-disabled="false" data-element-theme="customized">
                    <input v-model="user.email" ref="email" type="email" placeholder="Your Email Address Here..." name="email" class="elInput elInput100 elAlign_left elInputMid elInputStyl0 elInputBG1 elInputBR5 elInputI0 elInputIBlack elInputIRight es-lightgreyInput required1 garlic-auto-save" data-type="extra" style="">
                  </div>
                  <div class="de elInputWrapper de-input-block elAlign_center elMargin0 ui-droppable de-editable" id="input-10498" data-de-type="input" data-de-editing="false" data-title="input" data-ce="false" data-trigger="none" data-animate="fade" data-delay="500" type="not-set" style="margin-top: 10px; outline: none; cursor: pointer;" aria-disabled="false" data-element-theme="customized">
                    <input v-model="user.full_name" ref="full_name" type="text" placeholder="Your Full Name Here..." name="full_name" class="elInput elInput100 elAlign_left elInputMid elInputStyl0 elInputBG1 elInputBR5 elInputI0 elInputIBlack elInputIRight es-lightgreyInput required1 garlic-auto-save" data-type="extra" style="">
                  </div>
                  <div class="de elInputWrapper de-input-block elAlign_center elMargin0 ui-droppable de-editable" id="input-30543" data-de-type="input" data-de-editing="false" data-title="input" data-ce="false" data-trigger="none" data-animate="fade" data-delay="500" type="not-set" style="margin-top: 10px; outline: none; cursor: pointer;" aria-disabled="false" data-element-theme="customized">
                    <input v-model="user.business_name" ref="business_name" type="text" placeholder="Your Business Name Here..." name="business_name" class="elInput elInput100 elAlign_left elInputMid elInputStyl0 elInputBG1 elInputBR5 elInputI0 elInputIBlack elInputIRight es-lightgreyInput required1 garlic-auto-save" data-type="extra" style="">
                  </div>
                  <div class="de elInputWrapper de-input-block elAlign_center elMargin0 ui-droppable de-editable" id="input-18431" data-de-type="input" data-de-editing="false" data-title="input" data-ce="false" data-trigger="none" data-animate="fade" data-delay="500" type="not-set" style="margin-top: 10px; outline: none; cursor: pointer;" aria-disabled="false" data-element-theme="customized">
                    <input v-model="user.phone" ref="phone" type="text" placeholder="Your Phone Number Here..." name="phone" class="elInput elInput100 elAlign_left elInputMid elInputStyl0 elInputBG1 elInputBR5 elInputI0 elInputIBlack elInputIRight es-lightgreyInput required1 garlic-auto-save" data-type="extra" style="">
                  </div>
                  <div class="de elBTN elMargin0 elAlign_left ui-droppable de-editable" id="tmp_button-89788" data-de-type="button" data-de-editing="false" data-title="button" data-ce="false" data-trigger="none" data-animate="fade" data-delay="500" style="margin-top: 25px; outline: none; cursor: pointer;" aria-disabled="false" data-elbuttontype="1">
                    <button type="submit" :disabled="submitting" class="elButton elButtonSize1 elButtonColor1 elButtonRounded elButtonPadding2 elBtnVP_10 elBTN_b_1 elButtonShadowN1 elButtonTxtColor1 elButtonCorner5 elBtnHP_40 elButtonFull" style="color: rgb(255, 255, 255); font-weight: 600; background-color: rgb(59, 89, 153); font-size: 20px;" rel="noopener noreferrer">
                      <template v-if="!submitting">
                        <span class="elButtonMain">Request A Free Demo Today!</span>
                        <span class="elButtonSub">Get More Leads &amp; Customers Right now!</span>
                      </template>
                      <span v-else class="elButtonMain">Submitting...</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div id="col-right-161" class="innerContent col_right ui-resizable col-md-6" data-col="right" data-trigger="none" data-animate="fade" data-delay="500" data-title="2nd column" style="outline: none;">
              <div class="col-inner bgCover  noBorder borderSolid border3px cornersAll radius0 shadow0 P0-top P0-bottom P0H noTopMargin" style="padding: 0 10px">
                <div class="de elImageWrapper de-image-block elMargin0 ui-droppable elAlign_center de-editable" id="tmp_image-18624" data-de-type="img" data-de-editing="false" data-title="image" data-ce="false" data-trigger="none" data-animate="fade" data-delay="500" style="margin-top: 35px; outline: none; cursor: pointer;" aria-disabled="false">
                  <img src="../../assets/images/landing/Header-2-2.webp" class="elIMG ximg" alt="" width="300" tabindex="0">
                </div>
                <div class="de elHeadlineWrapper de-editable" id="tmp_subheadline-33568" data-de-type="headline" data-de-editing="false" data-title="sub-headline" data-ce="true" data-trigger="none" data-animate="fade" data-delay="500" data-gramm="false" style="margin-top: 30px; outline: none; cursor: pointer;">
                  <h2 class="ne elHeadline hsSize2 lh3 elMargin0 elBGStyle0 hsTextShadow0" style="text-align: center;font-size: 23px" data-bold="inherit" data-gramm="false" contenteditable="false">Join over 1000 companies that use our platform to generate additional sales calls!</h2>
                </div>
              </div>
            </div>
          <div class="thanks-div" v-if="submitted">
            <span class="success" v-if="success">Thanks for your submission. We will reach out to you soon!</span>
            <span class="fail" v-if="!success">Ops, Something went wrong. Please try again later!</span>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      submitting: false,
      submitted: false,
      success: false,
      user: {},
      initUser: {
        email: '',
        full_name: '',
        business_name: '',
        phone: '',
        domain: window.location.host,
      },
    }
  },
  mounted () {
    this.user = {...this.initUser}
  },
  methods: {
    openModal () {
      this.submitting = false
      this.submitted = false;
      this.success = false
      $(this.$refs.modal).modal('show')
    },
    submit () {
      if (!this.validate()) {
        return console.warn('form validation failed')
      }
      this.submitting = true
      this.$store.dispatch('agency/addOptinUser', this.user)
        .then(() => {
          this.submitting = false
          this.user = {...this.initUser}
          this.submitted = true
          this.success = true
        }, () => {
          this.submitted = true
          this.submitting = false
          this.success = false
        })
    },
    validate () {
      return ['email', 'full_name', 'business_name', 'phone'].reduce((result, input) => {
        $(this.$refs[input]).toggleClass('error', !this.user[input])
        return result && this.user[input]
      }, true)
    },
  },
}
</script>

<style scoped lang="scss">
  .elInputMid {
    &::placeholder {
      font-size: inherit;
      font-family: inherit;
    }
    &.error {
      border-color: rgb(185, 21, 23);
      border-width: 3px;
    }
  }

  .close-modal {
    position: absolute;
    right: 10px;
    top: 3px;
    font-size: 40px;
    line-height: 1;
    z-index: 10000;
  }

  .modal-dialog {
    width: 1200px;
    max-width: 80%;
    margin: 30px auto;
    top: 50%;
    transform: translateY(-70%) !important;
  }

  .thanks-div {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    margin: 20px 0;
    color: #ae2036;
    .success {
      color: #0c9d58;
    }
  }

  @media (max-width: 769px) {
    .dialog {
      overflow: scroll !important;
      height: 100%;
    }
    .modal-dialog {
      position: static !important;
      width: calc(100% - 40px);
      max-width: calc(100% - 40px);
      margin: 30px auto;
      transform: translateY(0) !important;
    }
  }
</style>

<style>
  .toasted.vuestic-toast.register-page-toast {
    font-size: 17px;
  }
</style>
