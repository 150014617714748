import store from '@/store/'

function AgencyGuard (to, from, next) {
    let user = store.state.auth.user

    if (!user) {
      const query = to.query;
      if (to.path) {
        query.path = to.path
      }
      next( { name: 'login', query: query })
    } else if (user.role_group != 'Agency'){
      next('/')
    } else if (to.name == 'agency.training') {
      next()
    } else if (!user.agency.free_fee && (!user.subscribed || !user.stripe_active)) {
      if (to.name !== 'agency.subscriptions') {
        store.commit('setLoading', false)
        next({ name: 'agency.subscriptions' })
      } else {
        next()
      }
    } else if (to.name != 'agency.setup' && user.agency && !user.has_twilio_setup && !user.agency.is_template) {
      store.commit('setLoading', false)
      next({name: 'agency.setup'})
    } else if (to.name == 'agency.setup' && user.agency && user.has_twilio_setup) {
      store.commit('setLoading', false)
      next({name: 'agency.landingPage'})
    } else if (to.name.startsWith('agency.users') && user.role !== 'AgencyAdmin'){
      next({name: 'businessAccountsActive'})
    } else if (to.name.startsWith('agency.zapier') && !store.getters['auth/agencyZapierEnabled']){
      next({name: 'businessAccountsActive'})
    } else {
      next()
    }
}

export default AgencyGuard
  