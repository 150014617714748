import axios from 'axios'
import Pusher from 'pusher-js';
import router from '../../router'
import BadWordsNext from 'bad-words-next'
const en = require('bad-words-next/data/en.json')
const badwords = new BadWordsNext({ data: en })

var newMessageAudio = new Audio('https://ringbot-prod.s3.amazonaws.com/assets/new-message.mp3');

export default {
  namespaced: true,
  state: {
    access_token: localStorage.getItem('access_token'),
    loading: false,
    processing: false,
    user: null,
    countries: ['US', 'CA'],
    impersonating: false,
    isImpersonating: false,
    pusherObj: new Pusher(process.env.VUE_APP_PUSHER_APP_KEY, {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
    }),
    isOpenBadwordModal: false,
    badwordFileteredContent: '',
  },
  mutations: {
    loading(state) {
      state.loading = true
    },

    processing(state) {
      state.processing = true
    },

    done(state) {
      state.loading = false
      state.processing = false
    },

    isImpersonating(state) {
      state.isImpersonating = true;
    },

    authSuccess(state, data) {
      localStorage.removeItem('main_access_token')
      state.access_token = data.access_token
      localStorage.setItem('access_token', data.access_token)
      localStorage.setItem('role_group', data.role_group)
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token
    },

    registerAgencySuccess(state, { user, res: data } ) {
      
      // Enable Tracking code 
      try{
        if (typeof $FPROM != 'undefined')
        {
          $FPROM.trackSignup({
            email: user.email,
            uid: data.data.uid
          },() => console.log('Callback received!'));
        }

        let eventData = {
          'company_name': user.company_name,
          'full_name': user.full_name,
          'email': user.email,
          'phone_number': user.phone_number,
          'plan': user.plan,
          'live': true,
        }

        if (Vue.analytics && Vue.analytics.fbq)
        {
          Vue.analytics.fbq.event('OrderSuccess', {
            ...eventData
          })
        }

        if (typeof gtag != 'undefined')
        {
          gtag('event', 'conversion', {
            'send_to': 'AW-10990715388/zk04CPzXo4AYEPyD5Pgo',
            ...eventData
          });
        }
      }catch(e){
        console.log(e)
      }

      // localStorage.removeItem('main_access_token')
      // state.access_token = data.access_token
      // localStorage.setItem('access_token', data.access_token)
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token
    },


    registerFromProductSuccess(state, { user, res: data } ) {
      
      // Enable Tracking code 
      try{
        if (typeof $FPROM != 'undefined')
        {
          $FPROM.trackSignup({
            email: user.email,
            uid: data.data.uid
          },() => console.log('Callback received!'));
        }

        let eventData = {
          'company_name': user.company_name,
          'full_name': user.full_name,
          'email': user.email,
          'phone_number': user.phone_number,
          'plan': user.plan,
          'live': true,
        }

        if (Vue.analytics && Vue.analytics.fbq)
        {
          Vue.analytics.fbq.event('OrderSuccess', {
            ...eventData
          })
        }

        if (typeof gtag != 'undefined')
        {
          gtag('event', 'conversion', {
            'send_to': 'AW-10990715388/zk04CPzXo4AYEPyD5Pgo',
            ...eventData
          });
        }
      }catch(e){
        console.log(e)
      }
    },

    businessOrderSuccess (state, { user, res: data }) {
      try{
        if (process.env.NODE_ENV === 'production') {
          let agencyId = data.user.agency.id  
          if (agencyId === 4212 && typeof window.fpr != 'undefined')
          {
            window.fpr("referral",{
              email: user.email
            })
          }
        }
      }catch(e){
        console.log(e)
      }
    },

    logout(state) {
      state.impersonating = false
      state.user = null
      state.countries = []
      state.access_token = null
      delete axios.defaults.headers.common['Authorization']
      localStorage.removeItem('access_token')
      localStorage.removeItem('role_group')
      localStorage.removeItem('main_access_token')

      this.dispatch('subscription/removePlans', {}, { root: true });
      this.dispatch('install/clearList', {}, { root: true });
      this.dispatch('contact/clearList', {}, { root: true });
      this.dispatch('media/clearList', {}, { root: true });
      this.dispatch('automation/clearList', {}, { root: true });
      this.dispatch('broadcast/clearList', {}, { root: true });
      this.dispatch('tag/clearList', {}, { root: true });
      this.dispatch('template/clearList', {}, { root: true });
      this.dispatch('department/clearList', {}, { root: true });
      this.dispatch('clearList', {}, { root: true });
    },

    getProfileSuccess (state, data) {
      state.user = data
      if (data.countries.length)
        state.countries = data.countries
      if (localStorage.getItem('main_access_token')) {
        state.impersonating = true
      }
    },

    getCountriesSuccess (state, data) {
      if (data.length)
        state.countries = data;
    },

    impersonateSuccess (state, data) {
      if (!localStorage.getItem('main_access_token')) {
        localStorage.setItem('main_access_token', state.access_token)
      }
      const redirectUrl = state.user.role_group == 'Admin' ? 'agency' : (state.user.agency.is_template ? 'business/automation' : 'business')

      this.dispatch('hideSubmenu', { root: true })
      state.impersonating = true
      state.isImpersonating = false;
      state.user = null
      localStorage.setItem('access_token', data.access_token)
      state.access_token = data.access_token
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token

      this.dispatch('subscription/removePlans', {}, { root: true });
      this.dispatch('install/clearList', {}, { root: true });
      this.dispatch('contact/clearList', {}, { root: true });
      this.dispatch('media/clearList', {}, { root: true });
      this.dispatch('automation/clearList', {}, { root: true });
      this.dispatch('broadcast/clearList', {}, { root: true });
      this.dispatch('tag/clearList', {}, { root: true });
      this.dispatch('template/clearList', {}, { root: true });
      this.dispatch('department/clearList', {}, { root: true });
      this.dispatch('getSiteData', window.location.host, { root: true })
      router.push(`/${redirectUrl}`).catch();
    },

    stopImpersonateSuccess (state, data) {
      this.dispatch('hideSubmenu', { root: true })

      const role_group = localStorage.getItem('role_group')
      let redirectUrl = 'dashboard';
      if (role_group)
      {
        redirectUrl = role_group == 'Admin' ? 'admin' : (role_group == 'Agency' ? 'agency' : 'business')
      }

      state.impersonating = false
      state.isImpersonating = false;
      state.user = null
      localStorage.setItem('access_token', localStorage.getItem('main_access_token'))
      state.access_token = localStorage.getItem('main_access_token')
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('main_access_token')
      localStorage.removeItem('main_access_token')

      this.dispatch('subscription/removePlans', {}, { root: true });
      this.dispatch('install/clearList', {}, { root: true });
      this.dispatch('contact/clearList', {}, { root: true });
      this.dispatch('media/clearList', {}, { root: true });
      this.dispatch('automation/clearList', {}, { root: true });
      this.dispatch('broadcast/clearList', {}, { root: true });
      this.dispatch('tag/clearList', {}, { root: true });
      this.dispatch('template/clearList', {}, { root: true });
      this.dispatch('department/clearList', {}, { root: true });
      this.dispatch('getSiteData', window.location.host, { root: true })
      router.push(`/${redirectUrl}`).catch();;
    },

    updateNumberOfMessages(state, number) {
      state.user.number_of_messages = number > 0 ? number : 0;
    },

    subscribeNotificationChannel(state, data) {
      let pusherChannel = `${process.env.VUE_APP_NOTIFICATION_CHANNEL}_user_${data.id}`
      let channel = state.pusherObj.subscribe(pusherChannel);

      channel.bind('app.user-data', ({ data }) => {
        const { type, contact, install, number_of_messages, number_of_tasks } = data
        if (type === 'new_message')
        {
          if (this.state.contact.selectedId != contact.id)
          {
            state.user.number_of_messages = number_of_messages

            this.commit('install/updateInstallData', install, { root: true });
          }
          
          try {
            newMessageAudio.play();
          } catch (e) {
          }
        } else if (type === 'new_task') {
          state.user.number_of_tasks = number_of_tasks
        }
      });
    },

    unsubscribeNotificationChannel(state) {
      if (state.user && state.user.id)
      {
        let pusherChannel = `${process.env.VUE_APP_NOTIFICATION_CHANNEL}_user_${state.user.id}`
        if (pusherChannel)
        {
          state.pusherObj.unsubscribe(pusherChannel);
        }
      }
    },

    getLiveDataSuccess(state, data)
    {
      const  { installs, business } = data
      if (installs && installs.length)
      {
        this.commit('install/updateInstallsData', installs, { root: true });
      }
      if (business)
      {
        state.user.number_of_messages = business.messages > 0 ? business.messages : 0;
      }
    },

    toggleBadwordModal(state, { value, str }) {
      state.isOpenBadwordModal = value
      state.badwordFileteredContent = str
    },
  
    resetBadword(state) {
      state.isOpenBadwordModal = false
      state.badwordFileteredContent = ''
    },
  },
  actions: {
    checkBadword({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        let { content } = payload
        let value = false,
          str = ''
        if (state.user && state.user.agency && state.user.agency.profanity_enabled && badwords.check(content)) {
          str = badwords.filter(content)
          value = true
        }
        let res = {
          value,
          str,
        }
        commit('toggleBadwordModal', res)
        resolve(res)
      })
    },
  
    resetBadword({ commit }) {
      commit('resetBadword')
    },

    oauthLogin({commit}, credentials)
    {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post('/v1/oauth-login', credentials)
          .then((res) => {
            commit('done')
            resolve(res.data)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    login({commit}, credentials) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post('/v1/login', credentials)
          .then((res) => {
            commit('authSuccess', res.data)
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    registerAgency ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.post('/v1/register/agency', user)
          .then((res) => {
            commit('registerAgencySuccess', {
              user, 
              res: res.data
            })
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    }, 

    orderBump ({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.post('/v1/register/order-bump', params)
          .then((res) => {
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    }, 

    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('loading')
        commit('unsubscribeNotificationChannel')
        axios.post('/v1/logout')
          .then((res) => {
            commit('logout')
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    },

    getProfile({commit}) {
      return new Promise((resolve, reject) => {
        commit('loading')
        let param = localStorage.getItem('main_access_token') ? `?i=1` : '';

        axios.get(`/v1/profile${param}`)
          .then((res) => {
            commit('getProfileSuccess', res.data.data)
            commit('subscribeNotificationChannel', res.data.data)
            commit('done')

            resolve(res.data.data)
          })
          .catch((error) => {
            commit('done')
            commit('logout')
            reject(error)
          })
      })
    },

    getLiveData({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('/v1/live-data')
          .then((res) => {
            commit('getLiveDataSuccess', res.data.data)
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getDiscounts({commit}, type) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/discounts/${type}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateProfile({commit}, user) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.put('/v1/profile', user)
        .then((res) => {
          commit('done')
          commit('getProfileSuccess', res.data.data.user)
          resolve(true)
        })
        .catch((error) => {
          commit('done')
          reject(error)
        })
      })
    },

    updateSettings({commit}, settings) {
      return new Promise((resolve, reject) => {
        commit('processing')

        axios.put('/v1/setting', settings)
        .then((res) => {
          commit('done')
          commit('getProfileSuccess', res.data.data.user)
          resolve(true)
        })
        .catch((error) => {
          commit('done')
          reject(error)
        })
      })
    },

    getCountries({commit}, user) {
      return new Promise((resolve, reject) => {
        axios.put('/v1/countries', user)
        .then((res) => {
          commit('getCountriesSuccess', res.data.data)
          resolve(true)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    impersonate ({ commit, dispatch }, id) {
      commit('isImpersonating')
      commit('setLoading', true, { root: true })
      commit('unsubscribeNotificationChannel')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/impersonate/${id}`).then(response => {
          commit('impersonateSuccess', response.data)
          commit('setLoading', false, { root: true })
          resolve(true)
        }).catch((e) => {
          commit('setLoading', false, { root: true })
          reject(e)
        })
      })
    },

    stopImpersonate ({ commit, dispatch }) {
      commit('isImpersonating')
      commit('setLoading', true, { root: true })
      commit('unsubscribeNotificationChannel')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/impersonate`).then(response => {
          commit('stopImpersonateSuccess', response.data)
          commit('setLoading', false, { root: true })
          resolve(true)
        }).catch((e) => {
          commit('setLoading', false, { root: true })
          reject()
        })
      })
    },

    sendResetPasswordEmail ({commit}, user) {
      commit('loading')

      return new Promise((resolve, reject) => {
        axios.post('/v1/password/email', user)
          .then(() => {
            resolve(true)
            commit('done')
          })
          .catch((err) => {
            commit('done')
            reject(err)
          })
      })
    },

    resetPassword({commit}, user) {
      commit('loading')
      return new Promise((resolve, reject) => {
        axios.post('/v1/password/reset', user)
          .then(() => {
            resolve(true)
            commit('done')
          })
          .catch((err) => {
            commit('done')
            reject(err)
          })
      })
    },

    verifyToken({commit}, data) {
      commit('loading')
      return new Promise((resolve, reject) => {
        axios.post('/v1/token/verify', data)
          .then((data) => {
            resolve(data.data)
            commit('done')
          })
          .catch((err) => {
            commit('done')
            reject(err)
          })
      })
    },

    unsubscribe({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('/v1/unsubscribe', data)
          .then(() => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    businessOrder ({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios.post('/v1/business/order', user)
          .then((res) => {
            commit('businessOrderSuccess', {
              user, 
              res: res.data.data
            })
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    verifyEmail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post('/v1/verify-email', params)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    checkEmail ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post('/v1/uniquemailchk', params)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    
    checkDomain ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post('/v1/uniquedomain', params)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    postUserInfo ({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.post('/v1/userinfo', params)
          .then((res) => {
            resolve(true)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    getOrderPage({ commit }, slug) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v1/order-page/${slug}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    orderProduct ({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('loading')

        axios.post('/v1/register/from-product', user)
          .then((res) => {
            commit('registerFromProductSuccess', {
              user, 
              res: res.data
            })
            commit('done')

            resolve(res)
          })
          .catch((error) => {
            commit('done')
            reject(error)
          })
      })
    }, 
  },

  getters: {
    user: state => state.user,
  
    isImpersonate: state => state.impersonating,

    isImpersonating: state => state.isImpersonating,

    isLoggedIn: state => !!state.access_token,

    countries: state => state.countries,

    settings: state => {
      return state.user && state.user.settings
    },

    zapierSubscription(state) {
      return state.user ? state.user.subscriptions.zapier : null
    },

    getRole (state) {
      return state.user ? state.user.role : null
    },

    getRoleGroup (state) {
      return state.user ? state.user.role_group : null
    },

    stripeConfig(state) {
      const { user } = state
      if (user && user.role_group == 'Business') {
        return user.agency.stripeConfig.public_key
      }
      return process.env.VUE_APP_STRIPE_KEY;
    },

    dashboardLink (state, getters) {
      if (getters.getRoleGroup) {
        return '/' + getters.getRoleGroup.toLocaleLowerCase() + '/'
      }

      return '/'
    },

    isCompletedSetup (state) {
      if (!state.user) return false

      if (state.user.role_group === 'Agency' && (!state.user.has_twilio_setup && !state.user.agency.is_template)) {
        return false
      }

      return true
    },

    allowNavigator (state) {
      if (!state.user) return false

      if (state.user.role_group == 'Agency') {
        return state.user.role_group === 'Agency' && (state.user.agency.free_fee || state.user.stripe_active) && (state.user.has_twilio_setup || state.user.agency.is_template)
      }

      if (state.user.role_group == 'Business') {
        return state.user.role_group === 'Business' && 
          (!(state.user.agency.stripeConfig && state.user.agency.stripeConfig.public_key) ||
          (state.user.business.free_fee || state.user.stripe_active))
      }
      
      return true;
    },

    canAddBusiness (state) {
      if (!state.user) return false

      if (state.user.role_group === 'Admin') return true

      return (state.user.agency.free_fee || state.user.stripe_active) && state.user.agency.active && (state.user.has_twilio_setup || state.user.agency.is_template)
    },

    hasAgencyStripeConfig(state) {
      return state.user && state.user.agency_has_stripe_config;
    },

    hasAgencyStripeEnabled (state) {
      if (!state.user) return false

      if (state.user.role_group === 'Admin') return false;

      if (state.user.role_group === 'Agency') {
        return state.user.agency.stripeConfig && state.user.agency.stripeConfig.secretKey;
      }

      if (state.user.role_group === 'Business') {
        return state.user.agency.stripeConfig && state.user.agency.stripeConfig.public_key;
      }
      return false;
    },

    agencyZapierEnabled(state) {
      return state.user && state.user.agency && state.user.agency.zapier_enabled === 1
    },

    agencyZapierFree(state) {
      return state.user && state.user.agency && state.user.agency.zapier_free === 1
    },
    
    agencyDepartmentEnabled(state) {
      return state.user && state.user.agency && state.user.agency.department_enabled === 1
    },

    agencyRemoveBrandingEnabled(state) {
      return state.user && state.user.agency && state.user.agency.remove_branding
    },

    widgetTypePhoneOnly(state) {
      return state.user && state.user.business && state.user.business.widget_type === 1
    },

    widgetTypeTextOnly(state) {
      return state.user && state.user.business && state.user.business.widget_type === 2
    },

    widgetTypePhone(state) {
      return state.user && state.user.business && [1,3].includes(state.user.business.widget_type)
    },

    widgetTypeText(state) {
      return state.user && state.user.business && [2,3].includes(state.user.business.widget_type)
    },

    isBusinessTrial(state) {
      return state.user && state.user.business && state.user.business.is_on_trial
    },

    optText(state) {
      return state.user && state.user.business && state.user.business.opt_out_msg
    },

    businessSetting: state => {
      return state.user && state.user.business && state.user.business.settings
    },

    departmentEnabled(state) {
      return state.user && state.user.business && state.user.business.dep_enabled
    },

    sendgridEnabled(state) {
      return state.user && state.user.business && state.user.business.sendgrid_enabled
    },
    
    aiEnabled(state) {
      return state.user && state.user.business && state.user.business.ai_enabled
    },

    hasSendgridAvailable(state) {
      return state.user && state.user.business && state.user.business.sendgrid_enabled && state.user.business.has_integrations.sendgrid
    },

    hasEmailClientAvailable(state) {
      return state.user && state.user.business  && state.user.business.has_integrations.email
    },

    isSmsAgent(state) {
      return state.user && state.user.role === 'BusinessUser' && (state.user.agent_type === 'agent' || state.user.agent_type === 'smsAgent')
    },

    isVoiceAgent(state) {
      return state.user && state.user.role === 'BusinessUser' && (state.user.agent_type === 'agent' || state.user.agent_type === 'voiceAgent')
    },

    businessOwner(state) {
      return state.user && state.user.business && state.user.business.owner
    },

    isBusinessManager(state) {
      return state.user && (state.user.role == 'BusinessAdmin' || state.user.role === 'BusinessUser' && ['2'].includes(state.user.user_type ))
    },

    business(state) {
      return state.user && state.user.business
    },

    maxDepartments(state) {
      return state.user && state.user.max_departments
    },

    isMainAgency(state) {
      return state.user && state.user.agency && state.user.agency.is_main
    },

    contactFields(state) {
      return state.user && state.user.business && state.user.business.contact_fields
    },

    customPageType(state) {
      return state.user && state.user.settings && state.user.settings.custom_type_pages
    },
  },
}
