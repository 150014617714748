import Vue from 'vue'
import Router from 'vue-router'
import GlobalRoutes from './Global'
import AdminRoutes from './Admin'
import BusinessRoutes from './Business'
import AgencyRoutes from './Agency'
import OrderRoutes from './Order'
import PopupRoutes from './Popup'
import CommonRoutes from './Common'
import BusinessOrderRoutes from './BusinessOrder'
import BOrderRoutes from './BOrder'
import ProductRoutes from './Product'

Vue.use(Router)

const createRouter = () => {
  const routes = [
    ...GlobalRoutes,
    ...AdminRoutes,
    ...BusinessRoutes,
    ...AgencyRoutes,
    ...OrderRoutes,
    ...PopupRoutes,
    ...BusinessOrderRoutes,
    ...BOrderRoutes,
    ...CommonRoutes,
    ...ProductRoutes,
  ]

  const router = new Router({
    mode: 'history',
    routes: routes
  });

  return router;
};

const router = createRouter()

Router.prototype.resetRouter = function () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // the relevant part
};


export default router
