<template>
  <div class="horizontal">
    <div v-if="size != 'thick'" class="value">{{animatedValue + '%'}}</div>
    <div class="progress" :class="size" >
      <div class="progress-bar" ref="progressBar" :style="'width: ' + value + '%'">
        <span v-if="size == 'thick'" :class="{hidden: animatedValue == min}" class="value">
          {{animatedValue + '%'}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import {color, lightness} from 'kewler'

  export default {
    props: [
      'value',
      'animatedValue',
      'min',
      'max',
      'color',
      'size',
      'isActive'
    ],
    mounted () {
      let progressBar = this.$refs.progressBar
      let progressColor = color(this.color)
      let current = progressColor
      setInterval(() => {
        if (this.animatedValue === 100) {
          current = progressColor
          progressBar.style.backgroundColor = current()
          return
        }
        if (progressColor(lightness(30))() !== current()) {
          current = progressColor(lightness(30))
        } else {
          current = progressColor
        }
        progressBar.style.backgroundColor = current()
      }, 500)
    }
  }
</script>

<style lang="scss">
  .vuestic-progress-bar .horizontal {
    display: inline-block;
    width: 100%;

    .progress-bar {
      transition: background-color ease .5s, width 3s linear !important;
    }

    .value {
      text-align: center;
      &.hidden {
        visibility: hidden;
      }
    }

    .basic {
      border-radius: $progress-bar-width-basic;
      height: $progress-bar-width-basic;
      .progress-bar {
        border-radius: inherit;
      }
    }

    .thin {
      height: $progress-bar-width-thin;
    }

    .thick {
      border-radius: $progress-bar-width-thick;
      height: $progress-bar-width-thick;
      .progress-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: inherit;
      }
    }

  }

</style>
