<template>
  <div class="fadeMe">
    <atom-spinner class="pre-loader" :animation-duration="1000" :size="300" :color="palette.white" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PreLoader",
  components: {
  },
  computed: {
    ...mapGetters(["palette"])
  }
}
</script>

<style lang="scss" scoped>
.pre-loader {
  position: absolute;
  left: $vuestic-preloader-left;
  top: $vuestic-preloader-top;
}

p.loading {
  font-size: x-large;
  position: absolute;
  left: calc(50% - 100px / 2);
  top: calc(50% + 400px/ 2);
}

div.fadeMe {
  opacity: 0.5;
  background: #000;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}

@keyframes blink {

  /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
  0% {
    opacity: .2;
  }

  /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
  20% {
    opacity: 1;
  }

  /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
  100% {
    opacity: .2;
  }
}

.loading span {
  /**
     * Use the blink animation, which is defined above
     */
  animation-name: blink;
  /**
     * The animation should take 1.4 seconds
     */
  animation-duration: 1.4s;
  /**
     * It will repeat itself forever
     */
  animation-iteration-count: infinite;
  /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
  animation-fill-mode: both;
}

.loading span:nth-child(2) {
  /**
     * Starts the animation of the third dot
     * with a delay of .2s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: .2s;
}

.loading span:nth-child(3) {
  /**
     * Starts the animation of the third dot
     * with a delay of .4s, otherwise all dots
     * would animate at the same time
     */
  animation-delay: .4s;
}
</style>
