import lazyLoading from './lazyLoading'
import PopupLayout from '../components/layouts/PopupLayout'

export default [{
  path: '/',
  component: PopupLayout,
  children: [
    {
      name: 'popup',
      path: '/popup',
      component: lazyLoading('popup/Popup'),
    },
  ],
}]
