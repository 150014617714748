<template>
  <bs-drawer
    ref="rightDrawer"
    name="rightDrawer"
    side="right"
    :showDrawer="false"
    :btnStyle="{}"
    :showToggleBtn="false"
    class="bs-drawer__animate"
    v-click-outside="vcoConfig"
  >
    <div class="col text-right">
      <div class="form-group with-icon-left">
        <div class="input-group mt-4">
          <input id="input-icon-left" name="input-icon-left" v-model="filterText" />
          <i class="fa fa-search icon-left input-icon search-icon"></i>
          <label class="control-label" for="input-icon-left">Search</label>
          <i class="bar"></i>
        </div>
      </div>
    </div>

    <b-nav vertical>
      <b-nav-item v-for="user in users" :key="user.id" @click="impersonate(user.id)" :data-id="user.id">
        {{ user.name }}
      </b-nav-item>
    </b-nav>
  </bs-drawer>
</template>

<script>
import filt from 'lodash/filter'
import {mapMutations} from 'vuex'

export default {
  data() {
    return {
      filterText: "",
      userList : [],
      vcoConfig: {
        handler: this.vcoHandler,
        middleware: this.vcoMiddleware,
        events: ['touchstart', 'click'],
      },
      xprops: {
        eventbus: new Vue()
      },
      userListTotal: null,
    };
  },
  computed: {
    impersonating () {
      return this.$store.state.auth.impersonating
    },

    users () {
      let fields = ['name', 'email', 'subdomain_url', 'domain']

      return this.$store.state.sidebarUserList.filter(item => {
        for(let field of fields) {
          let value = (item[field] || "").toLocaleLowerCase()
          if(value.includes(this.filterText)) {
            return true
          }
        }

        return false
      })
    },
  },

  methods: {
    ...mapMutations([
      'setSidebarList'
    ]),
    impersonate(id) {
      this.$store.state.app.isLoading = true;
      this.$refs.rightDrawer.closeDrawer();
      this.$store.dispatch("auth/impersonate", id).then(success => {
        this.$store.state.app.isLoading = false;
      }, error => {
        this.$store.state.app.isLoading = false;
        this.$refs.rightDrawer.openDrawer();
      }).catch(error => {
        console.log('Error', error);
        this.$store.state.app.isLoading = false;
      });
    },

    stopImpersonation() {
      this.$store.state.app.isLoading = true;
      this.$refs.rightDrawer.closeDrawer();
      this.$store.dispatch('auth/stopImpersonate').then(success => {
        this.$store.state.app.isLoading = false;
      }, error => {
        this.$store.state.app.isLoading = false;
        this.$refs.rightDrawer.openDrawer();
      }).catch(error => {
        this.$store.state.app.isLoading = false;
      });
    },

    vcoHandler (event, el) {
      this.$refs.rightDrawer.closeDrawer();
    },
    // Note: The middleware will be executed if the event was fired outside the element.
    //       It should have only sync functionality and it should return a boolean to
    //       define if the handler should be fire or not
    vcoMiddleware (event, el) {
      return (
        event.target.className.indexOf != undefined && event.target.className.indexOf('btn-right-drawer-toggle') === -1 && // ignore if clicked on a button that toggles drawer
        this.$refs.rightDrawer.open //ignore if drawer is closed
      );
    }
  }
};
</script>

<style lang="scss">
.bs-drawer {
  top: 55px !important;
}
.bs-drawer__nav-container {
  box-shadow: none !important;
  border-left: 1px solid $rb-table-border;
}
.bs-drawer__animate {
  min-width: 300px;
}
.bs-drawer.bs-drawer__right {
  transform: translateX(100%) translateX(0px)!important;
  -webkit-transform: translate(-webkit-calc(100% - 0px), 0)!important;
  -moz-transform: translate(-moz-calc(100% - 0px), 0)!important;
}
.bs-drawer.bs-drawer__right.bs-drawer__drawer-open {
  transform: translate(0, 0)!important;
  -webkit-transform: translate(0, 0)!important;
  -moz-transform: translate(0, 0)!important;
  -ms-transform: translate(0, 0)!important;
  -o-transform: translate(0, 0)!important;
}
.bs-drawer__nav-container-right {
  margin-right: 0!important;
  padding-top:20px;
  label {
    color: #b3b3b3 !important;
  }
}
</style>
<style scoped>
  .sidebar-loading{
    text-align: center !important;
  }
</style>
