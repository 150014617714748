import axios from 'axios'

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  mutations: {
    loading(state) {
      state.loading = true
    },

    done(state) {
      state.loading = false
    },
  },

  actions: {
    get ({ commit }) {
      commit('loading')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/internationals`)
          .then(res => {
            commit('done')
            resolve(res)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    update ({ commit }, data) {
      commit('loading')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/internationals`, data)
          .then(res => {
            commit('done')
            resolve(res)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },
  },

  getters: {
    loading: state => state.loading,
  },
}
