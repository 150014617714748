<template>
  <div class="vuestic-tabs">
    <div v-if="showTab" class="tab-wrapper d-none d-md-block">
      <div class="row" style="margin-left: 0; margin-right: 0">
        <a
          v-for="name in names"
          v-on:click="setActive(name)"
          class="btn col tab-header-btn"
          :class="{
            'btn-primary text-white': name === currentActive,
            'btn-outline text-primary': name !== currentActive,
          }"
        >{{name}}</a>
      </div>
    </div>
    <vuestic-simple-select class="d-flex d-md-none d-lg-none d-xl-none simple-select" v-bind:options="names" v-model="currentActive"></vuestic-simple-select>
    <div class="tab-content">
      <div class="tab-pane"
           :class="{active: name === currentActive}" v-for="name in names">
        <slot :name="name"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'vuestic-tabs',
    props: {
      names: {
        type: Array,
        default: () => []
      },
      value: {
        type: String
      },
      showTab: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      underscoreClass () {
        return 'underscore-' + this.names.length + '-' + this.names.indexOf(this.currentActive)
      }
    },
    methods: {
      setActive (name) {
        this.currentActive = name
        this.$emit('input', this.currentActive)
      }
    },
    data () {
      return {
        currentActive: '',
      }
    },
    mounted () {
      this.currentActive = this.value
    },
    watch: {
      value: {
        handler (value) {
          this.currentActive = this.value
        },
        immediate: true,
      }
    },
  }
</script>

<style lang="scss">
  .vuestic-tabs {
    background-color: white;
    .simple-select {
      padding-top: 1.5rem;
    }
    .tab-header-btn {
      border-radius: 0!important;
      &:first-of-type {
        border-top-left-radius: .25rem!important;
        border-bottom-left-radius: .25rem!important;
      };
      &:last-of-type {
        border-top-right-radius: .25rem!important;
        border-bottom-right-radius: .25rem!important;
      };
    }
    .nav {
      margin: 0;
      .nav-item {
        flex-grow: 1;
        text-align: center;
        padding: 0;
        .nav-link {
          padding: 0;
          color: $gray;
          transition: all .3s ease;
          text-transform: capitalize;
        }
        &:hover {
          cursor: pointer;
          .nav-link {
            color: $vue-darkest-blue;
          }
        }
        &.active {
          .nav-link {
            color: $vue-darkest-blue;
          }
        }
      }
    }
    .track {
      overflow: hidden;
      width: 100%;
      height: .125rem;
      position: relative;
      div[class^='underscore-']{
        background-color: $brand-primary;
        height: .125rem;
        position: absolute;
      }
      $koeff: 0.8;
      @for $all from 1 through 10 {
        $width: 1/$all;
        div[class^='underscore-#{$all}'] {
          width: $width * $koeff * 100%;
          transition: left .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
        }
        @for $place from 0 through $all {
          .underscore-#{$all}-#{$place} {
            left: ($place + (1 - $koeff)/2) * $width * 100%;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: $tab-content-pb;
      padding-top: $tab-content-pt;
      > .tab-pane {
        width: 100%
      }
    }
  }
</style>
