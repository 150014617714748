import axios from 'axios'

export default {
  namespaced: true,

  state: {
    loading: false,
    processing: false,
  },

  mutations: {
    loading(state) {
      state.loading = true
    },

    processing(state) {
      state.processing = true
    },

    done(state) {
      state.loading = false
      state.processing = false
    },
  },

  actions: {
    profileList({ commit }) {
      commit('loading')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/twilio/profiles`)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    profileGet({ commit }, sid) {
      commit('loading')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/twilio/profiles/${sid}`)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    profileCreate({ commit }, params) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/twilio/profiles`, params)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    validateCreate({ commit }, sid) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/twilio/profiles/${sid}/validate`)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    registerProfile({ commit }, params) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/twilio/profiles/${params.sid}/register`, params)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    profileDelete({ commit }, sid) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.delete(`/v1/twilio/profiles/${sid}`)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    profileSetMain({ commit }, sid) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/twilio/profiles/${sid}/set-mian`)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },

    getCampaign({ commit }) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.get(`/v1/twilio/campaign`)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },
    registerCampaign({ commit }, params) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/twilio/register-campaign`, params)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },
    syncCampaign({ commit }, params) {
      commit('processing')
      return new Promise((resolve, reject) => {
        axios.post(`/v1/twilio/sync-campaign`, params)
          .then(res => {
            commit('done')
            resolve(res.data)
          })
          .catch(error => {
            commit('done')
            reject(error)
          })
      })
    },
  },

  getters: {
    loading: state => state.loading,
  },
}
