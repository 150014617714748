import lazyLoading from './lazyLoading'
import BusinessOrderLayout from '../components/layouts/BusinessOrderLayout'

export default [{
  path: '/',
  component: BusinessOrderLayout,
  children: [
    {
      name: 'auth.business.order',
      path: '/business/register',
      component: lazyLoading('auth/BusinessOrder')
    },
    {
      name: 'auth.business.order-thankyou',
      path: '/business/order-thankyou',
      component: lazyLoading('auth/BusinessOrderThankyou'),
    },
  ],
}]
