import store from '@/store/'

function AdminGuard(to, from, next){
    let user = store.state.auth.user
      
    if (!user) {
      const query = to.query;
      if (to.path) {
        query.path = to.path
      }
      next( { name: 'login', query: query })
    } else if (user.role_group != 'Admin'){
      next('/')
    } else {
      next()
    }
  }
  
  export default AdminGuard