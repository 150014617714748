
<template>
  <vuestic-navbar class="app-navbar">
    <div slot="logo" >
      <img :src="sitedata.headerLogo" style="z-index: 9999; height: 28px;" v-if="sitedata.headerLogo" />
    </div>
    <div class="call-div d-lg-flex d-sm-block" slot="logo-right" style="margin-left: 30px" v-if="user && userRoleGroup == 'Business'">
      <div v-if="widgetTypePhone" class="text-center" :class="{ 'text-danger': user.business.number_of_calls && user.business.calls >= user.business.number_of_calls }">
        {{ user.business.calls }} / {{ user.business.number_of_calls ? user.business.number_of_calls : '∞' }} <small>Calls</small>
      </div>
      <div v-if="widgetTypeText" class="text-center" :class="{ 'text-danger': user.business.number_of_messages && user.business.messages >= user.business.number_of_messages }">
        {{ user.business.messages }} / {{ user.business.number_of_messages ? user.business.number_of_messages : '∞' }} <small>Messages</small>
      </div>
      <div v-if="isOnTrialBusiness" class="text-center" style="color: red;">
        ( Trial )
      </div>
    </div>
    <div  v-if="user" class="col nav-item d-flex align-items-center justify-content-end" slot="center" style="text-align: right">
      <div class="d-flex align-items-center justify-content-between">
        <div class="name-div">
          <span v-if="user.role_group == 'Agency'">{{user.agency.name}}</span>
          <span v-if="user.role_group == 'Business'">{{user.business.name}}</span>
        </div>
        <template v-if="userRoleGroup == 'Admin' || userRoleGroup == 'Agency' || impersonating">
          <button class="btn btn-sm btn-primary" v-if="impersonating" @click="stopImpersonation" style="margin-right: 5px;">
            Main Account <i class="fa fa-sign-out" aria-hidden="true"></i>
          </button>
          <router-link :to="{name: 'addagencyaccount'}" v-if="userRoleGroup == 'Admin'" style="margin-right: 5px;">
            <button class="btn btn-sm btn-primary">
              Add Agency
              <i aria-hidden="true" class="fa fa-plus"></i>
            </button>
          </router-link>
          <button v-on:click="callback" class="btn btn-sm btn-primary " v-if="userRoleGroup == 'Agency' && $store.getters['auth/canAddBusiness']">
            Add Business
            <i aria-hidden="true" class="fa fa-plus"></i>
          </button>
        </template>
      </div>  
    </div>
    
    <vuestic-modal
      :isOpen="isOpenModalRestrict"
      @cancel="isOpenModalRestrict = false"
      :cancelShown="true"
      :okShown="false"
      cancelText="Close"
      cancelClass="btn btn-primary"
    >
      <span slot="title" class="text-danger font-weight-bold">No Installs Available</span>
      <div class="d-block text-center">
        <span class="no-install-available-span" style="color: #313540">You don't have any installs available. If you would like to add a new business you must delete an existing install or upgrade your subscription to add additional installs. Please reach out to <a href="mailto:support@ringbot.io"><b>support@ringbot.io</b></a> if you have any questions</span>
      </div>
    </vuestic-modal>
  </vuestic-navbar>

</template>

<script>
import axios from 'axios'
import VuesticNavbar from "../../../vuestic-theme/vuestic-components/vuestic-navbar/VuesticNavbar";

export default {
  name: "app-navbar",
  created(){
    this.getMetaInfo();
  },
  data(){
    return{
      totalInstalls: null,
      availableInstalls: null,
      isOpenModalRestrict: false,
      agencyEmail: '',
    }
  },
  components: {
    VuesticNavbar,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    valueProxy: {
      get() {
        return this.isOpen;
      },
      set(opened) {
        this.$emit("toggle-menu", opened);
      }
    },

    userRole () {
      return this.$store.getters['auth/getRole']
    },

    userRoleGroup () {
      return this.$store.getters['auth/getRoleGroup']
    },
    
    impersonating () {
      return this.$store.state.auth.impersonating
    },

    user () {
      return this.$store.state.auth.user
    },

    sitedata () {
      return this.$store.state.app.sitedata
    },

    isCompletedSetup () {
      return this.$store.getters['auth/isCompletedSetup']
    },

    widgetTypePhone() {
      return this.$store.getters['auth/widgetTypePhone']
    },

    widgetTypeText() {
      return this.$store.getters['auth/widgetTypeText']
    },

    isOnTrialBusiness() {
      return this.$store.getters['auth/isBusinessTrial']
    },

    agencyOwnerEmail () {
      return this.user.agency && this.user.agency.email
    },
  },

  methods: {
    callback(){
      axios.request({
        url:`/v1/agencies/${this.user.agency.id}/install-stats`,
        method:'POST',
      }).then((resp)=>{
        this.totalInstalls = resp.data.data.total_installs;
        this.availableInstalls = resp.data.data.available_installs;
        if(this.availableInstalls > 0){
          this.$router.push({name: 'businessAccountAdd'});
        }else{
          this.isOpenModalRestrict = true
        }
      })
    },

    stopImpersonation() {
      
      this.$store.dispatch('auth/stopImpersonate').then(success => {
      }).catch(error => {
      });
    },

    getMetaInfo () {
      this.$store.dispatch('getSiteData', window.location.host)
    },
  },
};
</script>

<style lang='scss' scoped>
  @import "../../../sass/_variables.scss";
  $top-nav-height: 55px;
  .app-navbar {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1002;
    box-shadow: none!important;
    border-bottom: 1px solid #dee2e6;

    .layout.has-notification & {
      top: $top-notification-height;
    }
    .nav-item {
      button {
        i, span {
          color: #fff;
        }
      }
    }
  }
  .call-div {
    display: flex; 
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    small {
      color: inherit!important;
      font-weight: bold;
    }
    > div {
      color: #4e5468;
      font-weight: bold;
      line-height: 20px;
      margin-right: 10px;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .name-div {
    font-size: 18px;
    font-weight: bold;
    margin-right: 20px;
  }
  .btn {
    padding-left: 10px;
    padding-right: 10px;
  }
</style>

<style lang="scss">
.edit-page-modal {
  .modal-dialog {
    max-width: 100%;
    width: 1080px;
    .ql-editor {
      color: #414141;
    }
  }
}
</style>
