import lazyLoading from './lazyLoading'
import OrderLayout from '../components/layouts/OrderLayout'

export default [{
  path: '/',
  component: OrderLayout,
  children: [
    {
      name: 'register',
      path: '/register',
      component: lazyLoading('order/Register'),
    },
    {
      name: 'order',
      path: '/order',
      component: lazyLoading('order/Order'),
    },
    {
      name: 'trial',
      path: '/trial',
      component: lazyLoading('order/Order'),
    },
    {
      name: 'order-thankyou',
      path: '/order-thankyou',
      component: lazyLoading('order/OrderThankyou'),
    },
  ],
}]
