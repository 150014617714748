//Temporary
const messages = [
  {
    text:"Aliquip labore sunt tempor in consectetur dolore ullamco adipisicing amet aute enim. Minim nulla ad sunt officia tempor id. Do sit amet laborum sunt tempor sunt. Ad cupidatat enim ea nostrud enim cillum pariatur velit qui dolore ad anim exercitation. Cupidatat Lorem labore enim aliquip nisi Lorem et commodo reprehenderit ea est pariatur. Incididunt sunt irure laboris magna ex deserunt est ad sunt duis proident.",
    yours: false,
    created_at: new Date('2018-08-21 08:00'),

  },
  {
    text: "Yeah, that would be cool. Maybe this Sunday at 3 pm?",
    yours: true,
    created_at: new Date('2018-08-21 08:01'),
  },
  {
    text:"Exercitation nulla laboris ipsum sit id adipisicing enim do commodo ea. Duis ea ad proident deserunt labore Lorem excepteur commodo exercitation labore quis laboris. Ut veniam veniam magna nulla aliquip sit enim do fugiat irure ad elit.",
    yours: false,
    created_at: new Date('2018-08-21 08:02'),
  },
  {
    text: "Should I bring a lightbox with me?",
    yours: true,
    created_at: new Date('2018-08-21 08:03'),
  },
  {
    text:"Sunt Lorem est nostrud occaecat minim enim duis dolor nulla magna. Eiusmod non ea deserunt deserunt. Ipsum et duis amet eiusmod officia magna consequat non deserunt anim commodo pariatur quis. Cupidatat anim magna eiusmod aliquip eiusmod cillum pariatur. Dolor nisi non fugiat aliquip tempor cillum labore voluptate.",
    yours: false,
    created_at: new Date('2018-08-21 08:04'),
  },
  {
    text: "Voluptate veniam mollit proident quis anim fugiat in ut aute ea. Adipisicing nulla laborum minim Lorem ullamco occaecat sint aute non mollit qui occaecat sint id. Dolor tempor reprehenderit aute sunt incididunt dolore labore irure mollit adipisicing.",
    yours: true,
    created_at: new Date('2018-08-21 08:05'),
  }
]
const state = {
  activeCustomer: {
    id:1,
    name: "Zach Anderson",
    messages
  },
  customers: [
    {
      id:1,
      name: "Zach Anderson",
      messages
    },
    {
      id:2,
      name: "David Sielert",
      messages
    },
    {
      id:3,
      name: "Jeff",
      messages
    },
    {
      id:4,
      name: "Gary",
      messages
    },
    {
      id:5,
      name: "Valerie",
      messages
    }
  ]
}

const mutations = {

}

const actions = {

}

export default {
  state,
  mutations,
  actions
}
