export default {
  apply(router, store) {
    const noAuthGuardList = ['landing', 'popup', 'auth', 'unsubscribe-email', 'product-order', 'product-thankyou', 'release-notes'];
    router.beforeEach((to, from, next) => {
      if (store.getters['auth/isLoggedIn'] && !store.state.auth.user) {
        store.dispatch('auth/getProfile')
          .then((res) => {
            if (noAuthGuardList.includes(to.name)) {
              next()
            } else if (!to.path || !to.path.match('/' + res.role_group.toLowerCase() + '/')) {
              next('/' + res.role_group.toLowerCase() + '/')
              return;
            } else {
              next();
            }
          })
          .catch(() => {
            next('/')
          })
      } else {
        next()
      }
    })
  }
}