<template>
  <div class="vuestic-modal">
    <transition name="modal" :duration="duration">
      <div v-show="show" class="modal-container">
        <div class="modal" @click.self="clickMask">
          <div class="modal-dialog" :class="modalClass">
            <div class="modal-content">
              <!--Header-->
              <div class="modal-header" v-if="showHeader">
                <slot name="header">
                  <div class="modal-title">
                    <slot name="title"></slot>
                  </div>
                </slot>
              </div>
              <i
                class="ion ion-md-close close-modal"
                v-if="closeIconShown"
                @click.prevent="cancel"
              />
              <!--Container-->
              <div class="modal-body">
                <slot></slot>
              </div>
              <!--Footer-->
              <div class="modal-footer">
                <slot name="footer">
                  <button v-if="okShown" type="button" :class="okClass" @click="ok" :disabled="okDisabled || processing">
                    <span v-if="processing" class="atom-spinner-wrapper">
                      <atom-spinner slot="loading" :animation-duration="1500" :size="14" color="#FFF"/>
                    </span>
                    <span v-else>{{okText}}</span>
                  </button>
                  <button v-if="cancelShown" type="button" :class="cancelClass" @click="cancel" :disabled="cancelDisabled">{{cancelText}}</button>
                </slot>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-backdrop"></div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'vuestic-modal',
    components: {
    },
    props: {
      isOpen: {
        type: Boolean,
        default: false
      },
      transition: {
        type: String,
        default: 'modal'
      },
      small: {
        type: Boolean,
        default: false
      },
      large: {
        type: Boolean,
        default: false
      },
      force: {
        type: Boolean,
        default: false
      },
      okText: {
        type: String,
        default: 'CONFIRM'
      },
      cancelText: {
        type: String,
        default: 'CANCEL'
      },
      okClass: {
        type: String,
        default: 'btn btn-primary'
      },
      cancelClass: {
        type: String,
        default: 'btn btn-secondary'
      },
      closeIconShown: {
        type: Boolean,
        default: true
      },

      okShown: {
        type: Boolean,
        default: true
      },
      cancelShown: {
        type: Boolean,
        default: true
      },

      okDisabled: {
        type: Boolean,
        default: false
      },
      cancelDisabled: {
        type: Boolean,
        default: false
      },
      closeOnOk: {
        type: Boolean,
        default: true,
      },
      showHeader: { 
        type: Boolean,
        default: true,
      },
      center: { 
        type: Boolean,
        default: false,
      },
      processing: {
        type: Boolean,
        default: false,
      },
      fixed: {
        type: Boolean,
        default: false,
      },
      xl: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        show: false,
        duration: 500
      }
    },
    computed: {
      modalClass () {
        return {
          'modal-lg': this.large,
          'modal-sm': this.small,
          'modal-xl': this.xl,
          'modal-dialog-centered': this.center,
          'modal-fixed': this.fixed,
        }
      }
    },
    created () {
      this.show = this.isOpen;
      if (this.show) {
        document.body.className += ' modal-open'
      }
    },
    beforeDestroy () {
      document.body.className = document.body.className.replace(/\s?modal-open/, '')
    },
    watch: {
      isOpen (value) {
        this.show = value;
      },
      show (value) {
        if (value) {
          document.body.className += ' modal-open'
        } else {
          window.setTimeout(() => {
            document.body.className = document.body.className.replace(/\s?modal-open/, '')
          }, this.duration)
        }
      }
    },
    methods: {
      listenKeyUp (event) {
        if (event.key === 'Escape') {
          this.cancel()
        }
      },
      ok () {
        this.$emit('ok');
        this.$emit('close');
        this.show = !this.closeOnOk
        window.removeEventListener('keyup', this.listenKeyUp);
      },
      cancel () {
        this.$emit('cancel');
        this.$emit('close');
        this.show = false;
        window.removeEventListener('keyup', this.listenKeyUp);
      },
      clickMask () {
        if (!this.force) {
          this.cancel()
        }
      },
      open () {
        this.show = true
        window.addEventListener('keyup', this.listenKeyUp)
      }
    }
  }
</script>

<style lang="scss">
  .vuestic-modal {
    height: 0;
    width: 0;

    // For Transitioning
    .modal {
      display: block;
    }
    .modal-fixed {
      .modal-body {
        max-height: 85vh;
        overflow-y: auto;
      }
    }

    .modal-dialog, .modal-backdrop {
      transition: all .5s ease;
    }

    .modal-enter .modal-dialog, .modal-leave-active .modal-dialog {
      opacity: 0;
      transform: translateY(-30%);
    }

    .modal-enter .modal-backdrop, .modal-leave-active .modal-backdrop {
      opacity: 0;
    }

    .modal-backdrop {
      opacity: 0.5;
    }

    //Modal styles

    .modal-header {
      min-height: $modal-header-height;
      padding: $modal-header-padding-y $modal-header-padding-x;
      border-bottom: $modal-header-border;
      font-size: $font-size-larger;
      display: flex;
      align-items: center;
    }

    .close-modal {
      margin-left: 1rem;
      font-size: $font-size-large;
      line-height: $font-size-large;
      cursor: pointer;
      position: absolute;
      right: -15px;
      top: -15px;
      background-color: #000;
      border-radius: 50%;
      width: 30px;
      height: 31px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px solid #fff;
      -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.7);
      -moz-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.7);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.7);
    }

    .modal-content {
      border-radius: $modal-content-border-radius;
    }

    .modal-footer {
      justify-content: center;
      padding: 0 $modal-inner-padding;
      padding-bottom: calc(#{$modal-inner-padding} - #{$modal-footer-btns-padding-bottom});
      flex-wrap: wrap;
      .btn {
        margin: 0 $modal-footer-btns-margin-x $modal-footer-btns-padding-bottom $modal-footer-btns-margin-x;
      }
    }

    .modal-content {
      box-shadow: $modal-content-box-shadow-sm-up;
    }
  }
</style>
