import 'expose-loader?$!jquery'
import 'expose-loader?jQuery!jquery'
import 'bootstrap'
import "normalize.css"
import "vue2-datatable-component/dist/min.css"
// import 'buefy/lib/buefy.css'
import 'vue-toast-notification/dist/theme-default.css';

// Polyfills
import 'es6-promise/auto'
import 'babel-polyfill'
// The Vue build version to load with the `import` commandd
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'

Vue.config.productionTip = process.env.NODE_ENV === 'development' ? true: false
Vue.config.devtools = process.env.NODE_ENV === 'development' ? true: false

import Editor from '@tinymce/tinymce-vue'
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm';
import VueTelInput from 'vue-tel-input'
import store from '../store'
import App from './App'
import router from '../router'
import VuesticPlugin from 'vuestic-theme/vuestic-plugin'
import '../i18n'
import YmapPlugin from 'vue-yandex-maps'
import Datatable from 'vue2-datatable-component'

import routerCallbacks from '../router/callbacks.js';
import lazyLoading from '../router/lazyLoading';
import * as VueDeepSet from 'vue-deepset';
import axios from 'axios';
import Cookies from 'browser-cookies';
import VueClipboard from 'vue-clipboard2'
import VueBsDrawer from '../components/common/AppDrawer'
import BootstrapVue from 'bootstrap-vue'
import api from '../utils/api'
import VueTheMask from 'vue-the-mask'
import vClickOutside from 'v-click-outside'
import VueApexCharts from 'vue-apexcharts'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import Multiselect from 'vue-multiselect'
import VueToast from 'vue-toast-notification';
import EmojiPicker from '@zaichaopan/emoji-picker'
import AudioRecorder from 'vue-audio-recorder'
import GAuth from 'vue-google-oauth2'
// import * as Sentry from '@sentry/browser';
// import { Vue as VueIntegration } from '@sentry/integrations';
import linkify from 'vue-linkify'
import VueFacebookPixel from 'vue-facebook-pixel'
import { AtomSpinner } from 'epic-spinners'
const { SegmentedMessage } = require('sms-segments-calculator');
import VueCountdown from '@chenfengyuan/vue-countdown';


// Filters
import '../filters/avatar'
import '../filters/prune'
import '../filters/shorttime'
import '../filters/longtime'
import '../filters/phone'
import '../filters/date'
import '../filters/birthday'

Vue.use(EmojiPicker);
Vue.directive('tooltip', VTooltip)
Vue.directive('linkified', linkify)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)
Vue.component('multiselect', Multiselect)
Vue.use(AudioRecorder)
Vue.use(VueFacebookPixel)
Vue.component('atom-spinner', AtomSpinner)
Vue.use(VueApexCharts)
Vue.component(VueCountdown.name, VueCountdown);

// Sentry.init({
//   environment: process.env.NODE_ENV || 'development',
//   dsn: process.env.VUE_APP_SENTRY_DNS,
//   integrations: [new VueIntegration({Vue, attachProps: true})],
// });

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  // scope: 'profile email https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/plus.business.manage https://www.googleapis.com/auth/businesscommunications',
  scope: 'profile email https://mail.google.com',
  prompt: 'select_account consent',
}

Vue.use(GAuth, gauthOption)

Vue.component('editor', Editor)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueTelInput, {
  validCharactersOnly: true,
  mode: 'international',
  name: 'phone_number',
  // disabledFetchingCountry: true,
  preferredCountries: ["US", "CA"],
  dropdownOptions: {
    disabledDialCode: false
  },
  inputOptions: {
    showDialCode: false
  }
})
Vue.use(VueToast, {
  position: 'bottom',
  duration: 5000
});
Vue.use(vClickOutside)
Vue.use(VueTheMask)
Vue.use(BootstrapVue)
Vue.component('bs-drawer', VueBsDrawer)
Vue.use(VueClipboard)
Vue.prototype.$api = api
Vue.prototype.$eventBus = new Vue()
window.Vue = Vue
window.Cookies = Cookies

//apply middleware
routerCallbacks.apply(router, store)

Vue.mixin({
  methods: {
    msgCount: msg => {
      let segmentedMessage = new SegmentedMessage(msg)
      return segmentedMessage.segmentsCount
    },
    isValidNumber: (number) => {
      let tmp = number;
      if (!tmp)
        return '';
      if (!tmp.startsWith('+'))
        tmp = '+' + tmp;

      let phoneNumber = parsePhoneNumberFromString(tmp)
      if (phoneNumber)
        return phoneNumber.isValid();
      return false
    },
    scrollToError: (form) => {
      setTimeout(() => {
        const errors = Object.entries(form.errors)
          .map(([key, value]) => ({ key, value }))
          .filter(error => error["value"].length);
        form.refs[errors[0]["key"]].$el.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      }, 100);
    },
    fileSize: (fileSizeInBytes) => {
      var i = -1;
      var byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
          fileSizeInBytes = fileSizeInBytes / 1024;
          i++;
      } while (fileSizeInBytes > 1024);
  
      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    },
    difference(object, base) {
      function changes(object, base) {
        return _.transform(object, function(result, value, key) {
          if (base)
          {
            let baseValue = base[key];
            if (key === 'message') {
              if (value)
                value = value.replace( /(<([^>]+)>)/ig, '').replace(/[^a-zA-Z0-9 ]/gi, '').trim();
              if (baseValue)
                baseValue = baseValue.replace( /(<([^>]+)>)/ig, '').replace(/[^a-zA-Z0-9 ]/gi, '').trim();
            }
            if (!_.isEqual(value, baseValue) && key !== 'level') {
              let tmp = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
              if (!_.isEmpty(tmp)) {
                result[key] = tmp
              }
            }
          } else {
            result[key] = value
          }
        });
      }
      return changes(object, base);
    },
  }
})

extend('phone', {
  validate: value => {
    const parsedNumber = parsePhoneNumberFromString(value)
    if (parsedNumber) {
      return parsedNumber.isValid()
    }
  },
  message: 'Wrong phone number format!!',
})

extend('subdomain', {
  validate: value => {
    return value.match(/^[a-z0-9\-]+$/)
  },
  message: 'SubDomain format is invalid.',
})

extend('customurl', {
  validate: value => {
    return !value.trim().startsWith('http') && value.match(/^[a-zA-Z0-9|-]{1,61}[a-zA-Z0-9|-](?:\.[a-zA-Z0-9|-]{2,})+$/)
  },
  message: 'Custom URL format is invalid.',
})

extend('url', {
  validate: value => {
    var pattern = /(http|https):\/\/([a-z0-9-]+((\.[a-z0-9-]+)+)?)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return pattern.test(value);
  },
  message: 'URL format is invalid.',
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

//setup axios
axios.defaults.baseURL = process.env.VUE_APP_BASE_API + 'api'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token')
axios.interceptors.response.use((response) => {
  // Show success message
  if (response.data && response.data.message && response.data.success) {
    Vue.$toast.open({
      message: response.data.message,
      type: 'success',
    })
  }

  return response
}, (error) => {
  // Show error message
  if (error.response) {
    if (error.response.status === 422) {
      const { errors } = error.response.data
      const keys = Object.keys(errors);
      const message = errors[keys[0]] ? errors[keys[0]][0] : ''
      if (message)
      {
        Vue.$toast.open({
          message: errors[keys[0]][0],
          type: 'error',
        })
      }
    } else if(error.response.data && error.response.data.message) {
      if (error.response.data.type != 1)
      {
        Vue.$toast.open({
          message: error.response.data.message,
          type: 'error',
        })
      }
    }
  }
  
  // Catch 401 request
  if (error.response && error.response.status === 401) {
    store.dispatch('logout', false)
    router.push('/login')
  } else {
    return Promise.reject(error)
  }
})

const axiosInstance = axios.create({})
Vue.prototype.$http = axiosInstance

Vue.use(Datatable)
Vue.use(VuesticPlugin)
Vue.use(YmapPlugin)

// NOTE: workaround for VeeValidate + vuetable-2
Vue.use(VueDeepSet)

//Global comps
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('Button', lazyLoading('common/Button'))
Vue.component('BirthdayInput', lazyLoading('common/BirthdayInput'))
Vue.component('PhoneNumberInput', lazyLoading('forms/PhoneNumberInput'))
Vue.component('TextInput', lazyLoading('forms/TextInput'))
Vue.component('SelectInput', lazyLoading('forms/SelectInput'))
Vue.component('TextareaInput', lazyLoading('forms/TextareaInput'))

let mediaHandler = () => {
  if (window.matchMedia(store.getters.config.windowMatchSizeLg).matches) {
    store.dispatch('toggleSidebar', true)
  } else {
    store.dispatch('toggleSidebar', false)
  }
};


router.beforeEach((to, from, next) => {
  //toggle expanded property
  from.matched.forEach(menuItem => {
    if(!menuItem.meta.hasOwnProperty('expanded')) Vue.set(menuItem.meta,'expanded',false);
    store.dispatch('toggleExpandMenuItem', {menuItem: menuItem, expand: false});
  });
  to.matched.forEach(menuItem => {
    if(!menuItem.meta.hasOwnProperty('expanded')) Vue.set(menuItem.meta,'expanded',true);
    store.dispatch('toggleExpandMenuItem', {menuItem: menuItem, expand: true});
  });

  if (to.name !== from.name)
  {
    //show global spinner
    store.commit('setLoading', true)
    
    //continue to actual route
    next()
  }
});

router.afterEach((to, from) => {
  mediaHandler()
  store.commit('setLoading', false)
});

const app = window.App = new Vue({
  config: {productionTip: false},
  el: '#app',
  router,
  store,
  render: h => h(App)
});

//redirect if Unauthorized
app.$http.interceptors.response.use(
  (response) => { return response; },
  (error) => {
    if (!('response' in error) || error.response.status !== 401) 
    {
      return Promise.reject(error);
    }

    clearInterval(window.pinger);
    window.location.href = '/auth/login';

    return Promise.reject(error);
});

//check if Authorized once in a minute
window.pinger = setInterval(() => {
  if(!store.state.user || !store.state.user.id) return;
  app.$http.get('/v1/auth/check').catch((error) => { });
}, 60*1000);

var onresize = function() {
  let height = document.body.clientHeight
  let profileLink = document.querySelectorAll('.profile-link')
  let links = document.querySelectorAll('.sidebar-link')
  let links1 = document.querySelectorAll('.sidebar-menu li')
  if (height < 700 ) {
    if (profileLink && profileLink[0]) {
      profileLink[0].style.position = 'relative'
    }
    links.forEach(item => {
      item.style.height = '40px'
    });
    links1.forEach(item => {
      item.style.height = '40px'
    });
  } else {
    if (profileLink && profileLink[0]) {
      profileLink[0].style.position = 'absolute'
    }
    links.forEach(item => {
      item.style.height = '55px'
    });
    links1.forEach(item => {
      item.style.height = '55px'
    });
  }
}
window.addEventListener("resize", onresize)
$(document).ready(function() {
  setTimeout(() => {
    onresize()
  }, 3000);
})
