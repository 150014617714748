import { Bubble, mixins } from 'vue-chartjs'
import DefaultOptions from '../DefaultOptions'
import Vue from 'vue'
export default Vue.component('bubble-chart',{
// Bubble.extend({
  extends: Bubble,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options'],

  data () {
    return {
      defaultOptions: {

      }
    }
  },

  mounted () {
    let options = Object.assign({}, DefaultOptions, this.defaultOptions, this.options)
    this.renderChart(this.chartData, options)
  }
})
