<template>
  <div class="common-page-wrapper">
    <div class="main-content">
      <div class="container--full-width container-xl">
        <router-view></router-view>
      </div>
    </div>
    <div class="text-white text-center footer">
      <div class="container container--full-width container-xl">
        <router-link v-if="sitedata.headerLogo" :to="{path: '/'}">
            <img :src="sitedata.headerLogo" class="logo-img" alt=""/>
        </router-link>
        <div class="w-100">
          <span>@ Copyright {{ copyRightYear }} {{sitedata.title}}. All rights reserved</span> | <a href="javascript:;" @click.prevent="openPage('terms-of-services')">Terms of Service</a> | <a href="javascript:;" @click.prevent="openPage('privacy-policy')">Privacy Policy</a>
        </div>
      </div>
    </div>
    <page-modal ref="pageModal"></page-modal>
  </div>
</template>

<script>

import PageModal from '../landing/PageModal'

export default {
  components: {
    PageModal,
  },
  created () {
    this.$store.dispatch('getSiteData', window.location.host)
  },

  data () {
    return {
      isActive: false,
    }
  },

  methods: {
    openModal () {
      this.$refs.form.openModal()
    },

    openPage (key) {
      this.$refs.pageModal.openModal(key)
    },
  },

  computed: {
    sitedata () {
      return this.$store.state.app.sitedata
    },
    copyRightYear () {
      return this.$store.getters["copyRightYear"]
    },
  },
}
</script>

<style lang="scss">
.common-page-wrapper {
  background: #fff;
  color: #333;
  min-height: 100vh;
  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -webkit-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
    min-height: 76px;
    .logo-img {
      height: 32px;
      max-height: 32px;
      @media (max-width: 767px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .main-content {
    display: flex;
    justify-content: flex-start;
    min-height: calc(100vh - 72px);
    padding: 50px 20px;
    @media (max-width: 480px) {
      min-height: calc(100vh - 110px);
    }
  }
  .footer {
    width: 100%;
    background-color: #f3f3f3;
    .container {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #333333;
      @media (max-width: 480px) {
        display: block;
        padding: 10px 0;
        height: 110px;
      }
    }
    .logo-img {
      height: 32px;
      max-height: 32px;
      @media (max-width: 767px) {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    span, a {
      color: #333;
      font-size: 16px;
      display: inline-block;
      margin: 0;
      padding: 0 5px;
    }
    a:hover {
      text-decoration: underline;
    };
  }
}
</style>
