import * as types from '../mutation-types'
import axios from 'axios'

const state = {
  sidebar: {
    opened: false,
    withoutAnimation: false,
    submenuOpened: false,
    menuItems: {
      SuperAdmin: [
        {
          name: 'menu.agency', icon: 'fa fa-building', isOpen: false,
          childs: [
            { name: 'menu.accounts', to: 'agencyaccounts', icon: 'fa fa-users' },
            { name: 'menu.archived', to: 'admin.agencies.archived', icon: 'fa fa-folder' },
            { name: 'menu.add', to: 'addagencyaccount', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'editAgency', icon: 'fa fa-edit' },
          ],
        },
        { name: 'menu.whitelabel', icon: 'fa fa-globe', to: 'admin.whitelabel' },
        { name: 'menu.training', icon: 'fa fa-book', to: 'admin.training' },
        { name: 'menu.blacklist', icon: 'fa fa-ban', to: 'admin.blacklist', visible: true },
        { name: 'menu.whitelist', icon: 'fa fa-check-square-o', to: 'admin.whitelist', visible: true },
        { name: 'menu.users', icon: 'fa fa-users', to: 'admin.users.index', visible: true },
        { name: 'menu.releaseNote', icon: 'fa fa-bookmark', to: 'admin.releaseNote' },
      ],
      Admin: [
        {
          name: 'menu.agency', icon: 'fa fa-building', isOpen: false,
          childs: [
            { name: 'menu.accounts', to: 'agencyaccounts', icon: 'fa fa-users' },
            { name: 'menu.archived', to: 'admin.agencies.archived', icon: 'fa fa-folder' },
            { name: 'menu.add', to: 'addagencyaccount', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'editAgency', icon: 'fa fa-edit' },
          ],
        },
        { name: 'menu.whitelabel', icon: 'fa fa-globe', to: 'admin.whitelabel' },
        { name: 'menu.training', icon: 'fa fa-book', to: 'admin.training' },
        { name: 'menu.blacklist', icon: 'fa fa-ban', to: 'admin.blacklist', visible: true },
        { name: 'menu.whitelist', icon: 'fa fa-check-square-o', to: 'admin.whitelist', visible: true },
        { name: 'menu.releaseNote', icon: 'fa fa-bookmark', to: 'admin.releaseNote' },
      ],
      AgencyAdmin: [
        {
          name: 'menu.businessAccounts.root', icon: 'fa fa-users', isOpen: false,
          childs: [
            { name: 'menu.businessAccounts.active', to: 'businessAccountsActive', icon: 'fa fa-line-chart' },
            { name: 'menu.businessAccounts.archived', to: 'businessAccountsArchived', icon: 'fa fa-folder' },
            { name: 'menu.businessAccounts.deleted', to: 'businessAccountsDeleted', icon: 'fa fa-trash' },
            { name: 'menu.add', to: 'businessAccountAdd', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'businessAccountEdit', icon: 'fa fa-edit' },
          ]
        },
        { name: 'menu.analytics', icon: 'fa fa-bar-chart', to: 'agency.dashboard' },
        { name: 'menu.widgetLandingPage', icon: 'fa fa-dashboard', to: 'agency.landingPage' },
        { name: 'menu.international', icon: 'fa fa-globe', to: 'agency.international' },
        { name: 'menu.plans.root', icon: 'fa fa-list', to: 'agency.plans.index' },
        { name: 'menu.packages.root', icon: 'fa fa-suitcase', to: 'agency.packages.index' },
        { name: 'menu.zapier', type: 'img', filename: 'zapier.webp', to: 'agency.zapier' },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            { name: 'menu.settings', icon: 'fa fa-cog', to: 'agency.settings', 'visible': true },
            { name: 'menu.subscriptions', icon: 'fa fa-list-alt', to: 'agency.subscriptions', 'visible': true },
            { name: 'menu.paymentMethods', icon: 'fa fa-credit-card', to: 'agency.paymentMethods', 'visible': true },
            { name: 'menu.popupTab', icon: 'fa fa-bell', to: 'agency.popup', 'visible': true },
            {
              name: 'menu.users', icon: 'fa fa-users', to: 'agency.users.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'agency.users.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'agency.users.edit', icon: 'fa fa-edit' },
              ],
            },
            { name: 'menu.integrations', icon: 'fa fa-wrench', to: 'agency.integrations', 'visible': true },
            { name: 'menu.twilioProfile', type: 'img', filename: 'twilio.webp', to: 'agency.twilioProfile.index', 'visible': true,
              childs: [
                { name: 'menu.create', to: 'agency.twilioProfile.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'agency.twilioProfile.edit', icon: 'fa fa-edit' },
              ],
            },
            { name: 'menu.smsRegistration', icon: 'fa fa-commenting-o', to: 'agency.smsRegistration', 'visible': true },
          ]
        },
        { name: 'menu.training', icon: 'fa fa-book', to: 'agency.training' },
      ],
      AccountManager: [
        {
          name: 'menu.businessAccounts.root', icon: 'fa fa-users', isOpen: false,
          childs: [
            { name: 'menu.businessAccounts.active', to: 'businessAccountsActive', icon: 'fa fa-line-chart' },
            { name: 'menu.businessAccounts.archived', to: 'businessAccountsArchived', icon: 'fa fa-folder' },
            { name: 'menu.businessAccounts.deleted', to: 'businessAccountsDeleted', icon: 'fa fa-trash' },
            { name: 'menu.add', to: 'businessAccountAdd', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'businessAccountEdit', icon: 'fa fa-edit' },
          ]
        },
        { name: 'menu.international', icon: 'fa fa-globe', to: 'agency.international' },
        { name: 'menu.plans.root', icon: 'fa fa-list', to: 'agency.plans.index' },
        { name: 'menu.zapier', type: 'img', filename: 'zapier.webp', to: 'agency.zapier' },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            { name: 'menu.settings', icon: 'fa fa-cog', to: 'agency.settings', 'visible': true },
            { name: 'menu.subscriptions', icon: 'fa fa-list-alt', to: 'agency.subscriptions', 'visible': true },
            { name: 'menu.paymentMethods', icon: 'fa fa-credit-card', to: 'agency.paymentMethods', 'visible': true },
            { name: 'menu.popupTab', icon: 'fa fa-bell', to: 'agency.popup', 'visible': true },
          ]
        },
        { name: 'menu.training', icon: 'fa fa-book', to: 'agency.training' },
      ],
      FinanaceManager: [
        {
          name: 'menu.businessAccounts.root', icon: 'fa fa-users', isOpen: false,
          childs: [
            { name: 'menu.businessAccounts.active', to: 'businessAccountsActive', icon: 'fa fa-line-chart' },
            { name: 'menu.businessAccounts.archived', to: 'businessAccountsArchived', icon: 'fa fa-folder' },
            { name: 'menu.businessAccounts.deleted', to: 'businessAccountsDeleted', icon: 'fa fa-trash' },
            { name: 'menu.add', to: 'businessAccountAdd', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'businessAccountEdit', icon: 'fa fa-edit' },
          ]
        },
        { name: 'menu.plans.root', icon: 'fa fa-list', to: 'agency.plans.index' },
        { name: 'menu.zapier', type: 'img', filename: 'zapier.webp', to: 'agency.zapier' },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            { name: 'menu.settings', icon: 'fa fa-cog', to: 'agency.settings', 'visible': true },
            { name: 'menu.subscriptions', icon: 'fa fa-list-alt', to: 'agency.subscriptions', 'visible': true },
            { name: 'menu.paymentMethods', icon: 'fa fa-credit-card', to: 'agency.paymentMethods', 'visible': true },
          ]
        },
      ],
      MarketingManager: [
        {
          name: 'menu.businessAccounts.root', icon: 'fa fa-users', isOpen: false,
          childs: [
            { name: 'menu.businessAccounts.active', to: 'businessAccountsActive', icon: 'fa fa-line-chart' },
            { name: 'menu.businessAccounts.archived', to: 'businessAccountsArchived', icon: 'fa fa-folder' },
            { name: 'menu.businessAccounts.deleted', to: 'businessAccountsDeleted', icon: 'fa fa-trash' },
            { name: 'menu.add', to: 'businessAccountAdd', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'businessAccountEdit', icon: 'fa fa-edit' },
          ]
        },
        { name: 'menu.widgetLandingPage', icon: 'fa fa-dashboard', to: 'agency.landingPage' },
        { name: 'menu.zapier', type: 'img', filename: 'zapier.webp', to: 'agency.zapier' },
      ],
      BusinessAdmin: [
        {
          name: 'menu.messaging', icon: 'fa fa-commenting-o', to: 'messaging'
        },
        {
          name: 'menu.analytics', icon: 'fa fa-bar-chart', to: 'dashboard'
        },
        {
          name: 'menu.tasks', icon: 'fa fa-tasks', to: 'business.tasks'
        },
        {
          name: 'menu.contacts', icon: 'fa fa-address-book', to: 'business.contacts'
        },
        {
          name: 'menu.broadcast', icon: 'fa fa-podcast', to: 'business.broadcast'
        },
        {
          name: 'menu.calls', icon: 'fa fa-phone-square', isOpen: false,
          childs: [
            { name: 'menu.calls', icon: 'fa fa-phone', to: 'calls', visible: true },
            { name: 'menu.scheduledCalls', icon: 'fa fa-calendar', to: 'callSchedule', visible: true },
            { name: 'menu.voiceRecords', icon: 'fa fa-microphone', to: 'voiceRecords', visible: true },
          ]
        },
        {
          name: 'menu.automation', icon: 'fa fa-magic', to: 'business.automation',
        },
        {
          name: 'menu.lead_form', icon: 'fa fa-list-alt', to: 'business.lead-forms.index',
        },
        {
          name: 'menu.installs.root', icon: 'fa fa-download', to: 'business.installs',
        },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            {
              name: 'menu.settings', icon: 'fa fa-cog', to: 'business.setting', 'visible': true,
              childs: [
                { name: 'menu.edit', to: 'business.number.edit', icon: 'fa fa-edit' },
                { name: 'menu.create', to: 'business.smtp.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.smtp.edit', icon: 'fa fa-edit' },
                { name: 'menu.create', to: 'business.integration.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.integration.edit', icon: 'fa fa-edit' },
              ],
            },
            // { name: 'menu.smsRegistration', icon: 'fa fa-check', to: 'business.smsRegistration', visible: true },
            {
              name: 'menu.numbers', icon: 'fa fa-phone', to: 'business.numbers.index', visible: true,
              childs: [
                { name: 'menu.edit', to: 'business.numbers.edit', icon: 'fa fa-edit' },
                { name: 'menu.verify', to: 'business.numbers.verification', icon: 'fa fa-check' },
              ],
            },
            { name: 'menu.tags', icon: 'fa fa-tags', to: 'business.tags', visible: true },
            { name: 'menu.report', icon: 'fa fa-line-chart', to: 'business.callsReport', visible: true },
            { name: 'menu.subscriptions', icon: 'fa fa-list-alt', to: 'business.subscriptions', visible: true },
            { name: 'menu.businessPackages.root', icon: 'fa fa-suitcase', to: 'business.packages', visible: true },
            { name: 'menu.paymentMethods', icon: 'fa fa-credit-card', to: 'business.paymentMethods', visible: true },
            {
              name: 'menu.users', icon: 'fa fa-users', to: 'business.users.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.users.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.users.edit', icon: 'fa fa-edit' },
              ],
            },
            {
              name: 'menu.departments', icon: 'fa fa-building', to: 'business.departments.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.departments.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.departments.edit', icon: 'fa fa-edit' },
              ],
            },
            {
              name: 'menu.templates', icon: 'fa fa-envelope', to: 'business.templates.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.templates.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.templates.edit', icon: 'fa fa-edit' },
              ],
            },
            { name: 'menu.blacklist', icon: 'fa fa-ban', to: 'business.blacklist', visible: true },
            { name: 'menu.whitelist', icon: 'fa fa-check-square-o', to: 'business.whitelist', visible: true },
          ]
        }
      ],
      BusinessUser: [
        {
          name: 'menu.messaging', icon: 'fa fa-commenting-o', to: 'messaging'
        },
        {
          name: 'menu.calls', icon: 'fa fa-phone-square', isOpen: false,
          childs: [
            { name: 'menu.calls', icon: 'fa fa-phone', to: 'calls', visible: true },
            { name: 'menu.scheduledCalls', icon: 'fa fa-calendar', to: 'callSchedule', visible: true },
            { name: 'menu.voiceRecords', icon: 'fa fa-microphone', to: 'voiceRecords', visible: true },
          ]
        },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            { name: 'menu.settings', icon: 'fa fa-cog', to: 'business.setting', 'visible': true },
          ]
        },
      ],
      AdminSupport: [
        {
          name: 'menu.analytics', icon: 'fa fa-bar-chart', to: 'dashboard'
        },
        {
          name: 'menu.contacts', icon: 'fa fa-address-book', to: 'business.contacts'
        },
        {
          name: 'menu.calls', icon: 'fa fa-phone-square', isOpen: false,
          childs: [
            { name: 'menu.calls', icon: 'fa fa-phone', to: 'calls', visible: true },
            { name: 'menu.scheduledCalls', icon: 'fa fa-calendar', to: 'callSchedule', visible: true },
            { name: 'menu.voiceRecords', icon: 'fa fa-microphone', to: 'voiceRecords', visible: true },
          ]
        },
        {
          name: 'menu.installs.root', icon: 'fa fa-download', to: 'business.installs',
        },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            {
              name: 'menu.settings', icon: 'fa fa-cog', to: 'business.setting', 'visible': true,
              childs: [
                { name: 'menu.create', to: 'business.smtp.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.smtp.edit', icon: 'fa fa-edit' },
              ],
            },
            { name: 'menu.tags', icon: 'fa fa-tags', to: 'business.tags', visible: true },
            { name: 'menu.report', icon: 'fa fa-line-chart', to: 'business.callsReport', visible: true },
            {
              name: 'menu.users', icon: 'fa fa-users', to: 'business.users.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.users.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.users.edit', icon: 'fa fa-edit' },
              ],
            },
            {
              name: 'menu.departments', icon: 'fa fa-building', to: 'business.departments.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.departments.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.departments.edit', icon: 'fa fa-edit' },
              ],
            },
            {
              name: 'menu.templates', icon: 'fa fa-envelope', to: 'business.templates.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.templates.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.templates.edit', icon: 'fa fa-edit' },
              ],
            },
            { name: 'menu.blacklist', icon: 'fa fa-ban', to: 'business.blacklist', visible: true },
            { name: 'menu.whitelist', icon: 'fa fa-check-square-o', to: 'business.whitelist', visible: true },
          ]
        }
      ],
      MarketingUser: [
        {
          name: 'menu.messaging', icon: 'fa fa-commenting-o', to: 'messaging'
        },
        {
          name: 'menu.analytics', icon: 'fa fa-bar-chart', to: 'dashboard'
        },
        {
          name: 'menu.tasks', icon: 'fa fa-tasks', to: 'business.tasks'
        },
        {
          name: 'menu.contacts', icon: 'fa fa-address-book', to: 'business.contacts'
        },
        {
          name: 'menu.broadcast', icon: 'fa fa-podcast', to: 'business.broadcast'
        },
        {
          name: 'menu.calls', icon: 'fa fa-phone-square', isOpen: false,
          childs: [
            { name: 'menu.calls', icon: 'fa fa-phone', to: 'calls', visible: true },
            { name: 'menu.scheduledCalls', icon: 'fa fa-calendar', to: 'callSchedule', visible: true },
            { name: 'menu.voiceRecords', icon: 'fa fa-microphone', to: 'voiceRecords', visible: true },
          ]
        },
        {
          name: 'menu.automation', icon: 'fa fa-magic', to: 'business.automation',
        },
        {
          name: 'menu.installs.root', icon: 'fa fa-download', to: 'business.installs',
        },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            {
              name: 'menu.settings', icon: 'fa fa-cog', to: 'business.setting', 'visible': true,
              childs: [
                { name: 'menu.create', to: 'business.smtp.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.smtp.edit', icon: 'fa fa-edit' },
              ],
            },
            { name: 'menu.tags', icon: 'fa fa-tags', to: 'business.tags', visible: true },
            { name: 'menu.report', icon: 'fa fa-line-chart', to: 'business.callsReport', visible: true },
            {
              name: 'menu.users', icon: 'fa fa-users', to: 'business.users.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.users.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.users.edit', icon: 'fa fa-edit' },
              ],
            },
            {
              name: 'menu.departments', icon: 'fa fa-building', to: 'business.departments.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.departments.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.departments.edit', icon: 'fa fa-edit' },
              ],
            },
            {
              name: 'menu.templates', icon: 'fa fa-envelope', to: 'business.templates.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.templates.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.templates.edit', icon: 'fa fa-edit' },
              ],
            },
            { name: 'menu.blacklist', icon: 'fa fa-ban', to: 'business.blacklist', visible: true },
            { name: 'menu.whitelist', icon: 'fa fa-check-square-o', to: 'business.whitelist', visible: true },
          ]
        }
      ],
      SalesUser: [
        {
          name: 'menu.messaging', icon: 'fa fa-commenting-o', to: 'messaging'
        },
        {
          name: 'menu.tasks', icon: 'fa fa-tasks', to: 'business.tasks'
        },
        {
          name: 'menu.calls', icon: 'fa fa-phone-square', isOpen: false,
          childs: [
            { name: 'menu.calls', icon: 'fa fa-phone', to: 'calls', visible: true },
            { name: 'menu.scheduledCalls', icon: 'fa fa-calendar', to: 'callSchedule', visible: true },
            { name: 'menu.voiceRecords', icon: 'fa fa-microphone', to: 'voiceRecords', visible: true },
          ]
        },
        {
          name: 'menu.contacts', icon: 'fa fa-address-book', to: 'business.contacts'
        },
        {
          name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
          childs: [
            { name: 'menu.settings', icon: 'fa fa-cog', to: 'business.setting', 'visible': true },
            { name: 'menu.tags', icon: 'fa fa-tags', to: 'business.tags', visible: true },
            {
              name: 'menu.templates', icon: 'fa fa-envelope', to: 'business.templates.index', visible: true,
              childs: [
                { name: 'menu.create', to: 'business.templates.create', icon: 'fa fa-plus' },
                { name: 'menu.edit', to: 'business.templates.edit', icon: 'fa fa-edit' },
              ],
            },
          ]
        },
      ]
    },
  },
  config: {
    windowMatchSizeLg: '(min-width: 992px)',
    palette: {
      primary: '#1a76d2',
      danger: '#e34a4a',
      info: '#4ab2e3',
      success: '#db76df',
      warning: '#f7cc36',
      white: '#fff',
      black: '#000',
      fontColor: '#34495e',
      transparent: 'transparent',
      lighterGray: '#ddd'
    }
  },
  whitelabeldomain: null,
  isLoading: true,
  siteData: {},
  sitedata: {},
  industries: [
    { value: 1, label: 'SaaS' },
    { value: 2, label: 'eCommerce' },
    { value: 3, label: 'Home Services' },
    { value: 4, label: 'Mortgage Broker / Real Estate' },
    { value: 5, label: 'Insurance' },
    { value: 6, label: 'Health Clubs / Fitness' },
    { value: 7, label: 'Other' },
  ],
};

if (typeof window !== 'undefined' && window && window.APP_OPTIONS) {
  state.options = window.APP_OPTIONS;
}

const mutations = {
  [types.CLOSE_MENU](state) {
    if (document.documentElement.clientWidth < 992) {
      state.sidebar.opened = false
    }
  },
  [types.TOGGLE_SIDEBAR](state, opened) {
    state.sidebar.opened = opened
  },
  [types.UPDATE_SUBMENU_OPENED](state, opened) {
    state.sidebar.submenuOpened = opened
  },
  [types.HIDE_SUBMENU](state) {
    Object.keys(state.sidebar.menuItems).forEach((index) => {
      state.sidebar.menuItems[index].forEach((item, index1) => {
        item.isOpen = false;
      });
    });
    state.sidebar.submenuOpened = false
  },
  [types.TOGGLE_WITHOUT_ANIMATION](state, value) {
    state.sidebar.withoutAnimation = value
  },
  [types.TOGGLE_EXPAND_MENU_ITEM](state, payload) {
    let menuItem = payload.menuItem;
    let expand = payload.expand;
    if (menuItem.meta) {
      menuItem.meta.expanded = expand
    }
  },

  setLoading(state, isLoading) {
    state.isLoading = isLoading
  },
  setSiteData(state, { domain, data }) {
    state.sitedata = data.data.data
    state.siteData = {
      ...state.siteData,
      [domain]: data,
    }
  },

  clearList(state) {
    state.didFetch = false;
    state.opened = false;
    state.withoutAnimation = false;
    state.submenuOpened = false;
  },

  addCustomTypeMenuItem(state, newItem) {
    state.sidebar.menuItems["CustomType"] = newItem.menuItem;
  }
}

const actions = {
  closeMenu({ commit }) {
    commit(types.CLOSE_MENU)
  },
  toggleSidebar({ commit }, opened) {
    commit(types.TOGGLE_SIDEBAR, opened)
  },
  updateSubmenuOpened({ commit }, opened) {
    commit(types.UPDATE_SUBMENU_OPENED, opened)
  },
  hideSubmenu({ commit }) {
    commit(types.HIDE_SUBMENU)
  },
  isToggleWithoutAnimation({ commit }, value) {
    commit(types.TOGGLE_WITHOUT_ANIMATION, value)
  },
  toggleExpandMenuItem({ commit }, payload) {
    commit(types.TOGGLE_EXPAND_MENU_ITEM, payload)
  },

  getSiteData({ commit }, domain) {

    return new Promise((resolve, reject) => {
      axios.get(`/v1/settings/site-data/${domain}`)
        .then(data => {
          commit('setSiteData', { domain, data })
          resolve(data)
        })
        .catch(errors => reject(errors))
    })
  },

  getProductData({ commit }, slug) {

    return new Promise((resolve, reject) => {
      axios.get(`/v1/settings/product-data/${slug}`)
        .then(data => {
          commit('setSiteData', { slug, data })
          resolve(data)
        })
        .catch(errors => reject(errors))
    })
  },

  clearList({ commit }) {
    commit(types.HIDE_SUBMENU)
    commit('clearList')
  },

  addCustomTypeMenuItem({ commit }, { userData }) {

    let menuItem = []
    if(userData.user_type == 4) {
      if(userData.custom_type_pages.includes('inbox_page')) {
        menuItem.push({
          name: 'menu.messaging', icon: 'fa fa-commenting-o', to: 'messaging'
        });
      }

      if(userData.custom_type_pages.includes('analytic_page')) {
        menuItem.push({
          name: 'menu.analytics', icon: 'fa fa-bar-chart', to: 'dashboard'
        });
      }

      if(userData.custom_type_pages.includes('calls_page')) {
        menuItem.push({
          name: 'menu.calls', icon: 'fa fa-phone-square', isOpen: false,
          childs: [
            { name: 'menu.calls', icon: 'fa fa-phone', to: 'calls', visible: true },
            { name: 'menu.scheduledCalls', icon: 'fa fa-calendar', to: 'callSchedule', visible: true },
            { name: 'menu.voiceRecords', icon: 'fa fa-microphone', to: 'voiceRecords', visible: true },
          ]
        });
      }

      if(userData.custom_type_pages.includes('tasks_page')) {
        menuItem.push({
          name: 'menu.tasks', icon: 'fa fa-tasks', to: 'business.tasks'
        });
      }

      if(userData.custom_type_pages.includes('contacts_page')) {
        menuItem.push({
          name: 'menu.contacts', icon: 'fa fa-address-book', to: 'business.contacts'
        });
      }

      if(userData.custom_type_pages.includes('broadcast_page')) {
        menuItem.push({
          name: 'menu.broadcast', icon: 'fa fa-podcast', to: 'business.broadcast'
        });
      }

      if(userData.custom_type_pages.includes('automation_page')) {
        menuItem.push({
          name: 'menu.automation', icon: 'fa fa-magic', to: 'business.automation',
        });
      }

      if(userData.custom_type_pages.includes('installs_page')) {
        menuItem.push({
          name: 'menu.installs.root', icon: 'fa fa-download', to: 'business.installs',
        });
      }


      const childMenuItem = {name: 'menu.settings', icon: 'fa fa-cogs', isOpen: false,
      childs: [
        {
          name: 'menu.settings', icon: 'fa fa-cog', to: 'business.setting', 'visible': true,
          childs: [
            { name: 'menu.edit', to: 'business.number.edit', icon: 'fa fa-edit' },
            { name: 'menu.create', to: 'business.smtp.create', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'business.smtp.edit', icon: 'fa fa-edit' },
            { name: 'menu.create', to: 'business.integration.create', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'business.integration.edit', icon: 'fa fa-edit' },
          ],
        }]
      };


      if(userData.custom_type_pages.includes('numbers_page')) {
        childMenuItem.childs.push({
          name: 'menu.numbers', icon: 'fa fa-phone', to: 'business.numbers.index', visible: true,
          childs: [
            { name: 'menu.edit', to: 'business.numbers.edit', icon: 'fa fa-edit' },
            { name: 'menu.verify', to: 'business.numbers.verification', icon: 'fa fa-check' },
          ],
        });
      }

      if(userData.custom_type_pages.includes('tags_page')) {
        childMenuItem.childs.push({ name: 'menu.tags', icon: 'fa fa-tags', to: 'business.tags', visible: true });
      }

      if(userData.custom_type_pages.includes('users_page')) {
        childMenuItem.childs.push({
          name: 'menu.users', icon: 'fa fa-users', to: 'business.users.index', visible: true,
          childs: [
            { name: 'menu.create', to: 'business.users.create', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'business.users.edit', icon: 'fa fa-edit' },
          ],
        });
      }

      if(userData.custom_type_pages.includes('departments_page')) {
        childMenuItem.childs.push({
          name: 'menu.departments', icon: 'fa fa-building', to: 'business.departments.index', visible: true,
          childs: [
            { name: 'menu.create', to: 'business.departments.create', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'business.departments.edit', icon: 'fa fa-edit' },
          ],
        });
      }

      if(userData.custom_type_pages.includes('message_template_page')) {
        childMenuItem.childs.push({
          name: 'menu.templates', icon: 'fa fa-envelope', to: 'business.templates.index', visible: true,
          childs: [
            { name: 'menu.create', to: 'business.templates.create', icon: 'fa fa-plus' },
            { name: 'menu.edit', to: 'business.templates.edit', icon: 'fa fa-edit' },
          ],
        });
      }

      if(userData.custom_type_pages.includes('blacklist/whitelist_page')) {
        childMenuItem.childs.push({ name: 'menu.blacklist', icon: 'fa fa-ban', to: 'business.blacklist', visible: true });
        childMenuItem.childs.push({ name: 'menu.whitelist', icon: 'fa fa-check-square-o', to: 'business.whitelist', visible: true });
      }
      menuItem.push(childMenuItem);
    }
    commit('addCustomTypeMenuItem', { menuItem });
  }
}

export default {
  state,
  mutations,
  actions,
}
