<template>
  <v-popover
    :popover-class="popoverClass"
    :open.sync="openValue"
    :disabled="disabled"
    :placement="placement"
    :auto-hide="autoHide"
    >
    <slot name="trigger"></slot>
    <template slot="popover">
      <div class="popover-icon" v-if="isIconPresent">
        <slot name="icon"></slot>
      </div>
      <div class="popover-content">
        <div class="popover-header">
          <slot name="header"></slot>
        </div>
        <div class="popover-body">
          <slot name="body"></slot>
        </div>
      </div>
    </template>
  </v-popover>
</template>

<script>
  export default {
    name: 'vuestic-popover',

    props: {
      open: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      placement: {
        type: String,
        default: 'auto'
      },
      popoverClass: {
        type: [String, Array],
        default: 'vuestic-tooltip'
      },
      autoHide: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        openValue: false,
      }
    },

    watch: {
      openValue: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
          {
            this.$emit('onUpdateOpen', newValue);
          }
        },
        deep: true,
      },

      open: {
        handler: function(newValue, oldValue) {
          if (newValue != oldValue)
          {
            this.openValue = newValue;
          }
        },
        deep: true,
      }
    },

    created() {
      this.openValue = this.open
    },

    computed: {
      isIconPresent () {
        return !!this.$slots.icon
      }
    }
  }
</script>

<style lang='scss' scoped>

</style>
