import Vue from 'vue'
import Vuex from 'vuex'
import VuexI18n from 'vuex-i18n' // load vuex i18n module
import * as VueDeepSet from 'vue-deepset'

/** Modules */
import appModule from './modules/app'
import agencyModule from './modules/agency'
import agencyUserModule from './modules/agency-user'
import businessModule from './modules/business'
import businessUserModule from './modules/business-user'
import businessIntegrationModule from './modules/business-integration'
import userModule from './modules/user'
import messagingModule from './modules/messaging'
import installModule from './modules/install'
import twilioModule from './modules/twilio'
import authModule from './modules/auth'
import subscriptionModule from './modules/subscription'
import whitelabelModule from './modules/whitelabel'
import pageModule from './modules/page'
import internationalModule from './modules/international'
import chatModule from './modules/chat'
import contactModule from './modules/contact'
import taskModule from './modules/task'
import tagModule from './modules/tag'
import broadcastModule from './modules/broadcast'
import automationModule from './modules/automation'
import departmentModule from './modules/department'
import templateModule from './modules/template'
import blacklistModule from './modules/blacklist'
import whitelistModule from './modules/whitelist'
import numberModule from './modules/number'
import packageModule from './modules/package'
import mediaModule from './modules/media'
import releaseNoteModule from './modules/release-note'
import leadFormModule from './modules/leadForm'

/** End Modules */
import * as getters from './getters'
import assign from 'lodash/assign';

Vue.use(Vuex)
let state = {};
const store = new Vuex.Store({
  strict: false, // this messes shit up.. keep this off.. do not mutate state directly..
  getters,
  modules: {
    app: appModule,
    agency: agencyModule,
    agencyUser: agencyUserModule,
    business: businessModule,
    businessUser: businessUserModule,
    businessIntegration: businessIntegrationModule,
    messaging: messagingModule,
    user: userModule,
    install: installModule,
    twilio: twilioModule,
    auth: authModule,
    subscription: subscriptionModule,
    whitelabel: whitelabelModule,
    page: pageModule,
    international: internationalModule,
    chat: chatModule,
    contact: contactModule,
    task: taskModule,
    tag: tagModule,
    broadcast: broadcastModule,
    automation: automationModule,
    leadForm: leadFormModule,
    department: departmentModule,
    template: templateModule,
    blacklist: blacklistModule,
    whitelist: whitelistModule,
    number: numberModule,
    package: packageModule,
    media: mediaModule,
    releaseNote: releaseNoteModule,
  },
  state: {
    sidebarUserList: [],
  },
  actions: {

  },
  mutations: VueDeepSet.extendMutation({
  
  })
})

if (typeof window !== 'undefined' && window && window.APP_STATE) {
  state = assign(store.state, window.APP_STATE);
}

Vue.use(VuexI18n.plugin, store)


export default store
