import lazyLoading from './lazyLoading'
import BlankLayout from '../components/layouts/BlankLayout'

export default [{
  path: '/',
  component: BlankLayout,
  children: [
    {
      name: 'unsubscribe-email',
      path: '/unsubscribe/email/:token',
      component: lazyLoading('auth/UnsubscribeEmail'),
    },
    {
      name: 'release-notes',
      path: '/release-notes',
      component: lazyLoading('agency/ReleaseNote'),
    },
  ],
}, {
  path: '*',
  redirect: '/not-found' 
}]
