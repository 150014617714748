import axios from 'axios'
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    contacts: [],
    cancelRequest: [],
    loading: false,
  },
  mutations: {
    loading(state) {
      state.loading = true
    },

    done(state) {
      state.loading = false
    },
    
    addRequest (state, payload) {
      state.cancelRequest[payload.type] = payload.source;
    },

    cancelRequest (state, type) {
      if (state.cancelRequest[type]) {
        state.cancelRequest[type]();
      }
      state.cancelRequest[type] = null;
    },

    getContactsSuccess (state, channel) {
      state.contacts = contacts
    },

    sendMessageSuccess(state, { length, hasImages, isIncoming }) {
      if ( hasImages )
      {
        if (isIncoming)
          this.state.auth.user.business.messages += 2
        else
          this.state.auth.user.business.messages += 3
      } else {
        this.state.auth.user.business.messages += Math.ceil(length / 160)
      }
    }
  },
  actions: {
    getContactsByInstall ({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.get(`/v1/chats/installs/${params.installId}/contacts`, {params})
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getInboxByInstall ({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.get(`/v1/chats/installs/${params.installId}/inbox`, {params})
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getInboxByInstallNew ({ commit, state }, params) {
      return new Promise((resolve, reject) => {
        commit('loading')
        let requestType = 'getInbox'
        if (state.cancelRequest[requestType])
        {
          commit('cancelRequest', requestType)
        }
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios.get(`/v1/chats/installs/${params.installId}/inbox-new`, { params, cancelToken: source.token })
          .then((res) => {
            commit('done')
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getFiles ({ commit, state }, params ) {
      return new Promise((resolve, reject) => {
        commit('loading')
        let requestType = 'getFiles'
        if (state.cancelRequest[requestType])
        {
          commit('cancelRequest', requestType)
        }
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios.get(`/v1/chats/contacts/${params.contactId}/files`, { params, cancelToken: source.token })
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getConversations ({ commit, state }, params ) {
      return new Promise((resolve, reject) => {
        commit('loading')
        let requestType = 'getConversations'
        if (state.cancelRequest[requestType])
        {
          commit('cancelRequest', requestType)
          commit('cancelRequest', 'getFiles')
        }
        const source = axios.CancelToken.source()
        commit('addRequest', {
          'type': requestType,
          'source': source.cancel
        })
        axios.get(`/v1/chats/contacts/${params.contactId}/conversations`, { params, cancelToken: source.token })
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getConversation ({ commit }, {contactId, messageId} ) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/chats/contacts/${contactId}/conversation/${messageId}`)
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    getMessages ({ commit }, params ) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.get(`/v1/chats/contacts/${params.contactId}/messages`, {params})
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sendMessage ({ commit }, param) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post(`/v1/chats/contacts/${param.contactId}/messages`, param)
          .then((res) => {
            commit('done')
            commit('sendMessageSuccess', { 
              length: param.content ? param.content.length: 0,
              hasImages: param.images.length,
              isIncoming: false,
            })
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    resendMessage ({ commit }, param) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post(`/v1/chats/contacts/${param.contactId}/resend-messages`, param)
          .then((res) => {
            commit('done')
            let message = res.data.data
            commit('sendMessageSuccess', { 
              length: message.message ? message.message.length: 0,
              hasImages: message.medias.length,
              isIncoming: false,
            })
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sendEmail ({ commit }, param) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post(`/v1/chats/contacts/${param.contactId}/email`, param)
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    cancelSchedule ({ commit }, param) {
      return new Promise((resolve, reject) => {
        axios.post(`/v1/chats/contacts/${param.contactId}/cancel-schedule`, param)
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    updateContact ({ commit }, { contactId, data }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.put(`/v1/chats/contacts/${contactId}`, data)
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    createContact ({ commit }, { installId, data }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post(`/v1/chats/installs/${installId}/contacts`, data)
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    refresh ({ commit }, { contactId }) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post(`/v1/chats/contacts/${contactId}/refresh`)
          .then((res) => {
            commit('done')
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    sendICS ({ commit }, params) {
      return new Promise((resolve, reject) => {
        commit('loading')
        axios.post(`/v1/chats/contacts/${params.contact_id}/send-ics`, params)
          .then((res) => {
            commit('done')
            resolve(res.data.data.ics_file)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
  },  

  getters: {
    contacts (state) {
      return state.contacts
    },
  },
}
