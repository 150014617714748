import axios from 'axios'

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  mutations: {
    loading (state) {
      state.loading = true
    },

    done (state) {
      state.loading = false
    },
  },
  actions: {
    getWhitelabels ({ commit }, queries) {
      return new Promise((resolve, reject) => {
        axios.get(`/v1/whitelabels`, { params: queries })
          .then(res => resolve(res))
          .catch(error => reject(error))
      })
    },

    requestCert ({ commit }, whitelabelId) {
        return new Promise((resolve, reject) => {
          axios.post(`/v1/whitelabels/${whitelabelId}/cert`)
            .then(res => resolve(res))
            .catch(error => reject(error))
        })
    },
  },

  getters: {
  },
}
